import { Typography } from 'antd'
import styled from 'styled-components'

const CustomText = styled(Typography)`
  font-family: Montserrat;
  color: ${props => {
    switch (props.color) {
      case 'grey':
        return '#6B6B6B'
      case 'blue':
        return '#2959A8'
      case 'green':
        return '#2BA608'
      case 'black':
        return '#000000'
      case 'greynew':
        return '#656565'
      default:
        return props.color
    }
  }};
  font-size: ${props => props.size};
  font-weight: ${props => props.fontWeight};
`

export default CustomText
