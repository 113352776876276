import React from 'react'
import styled from 'styled-components'
import { Typography, Card, Tabs, Col, Divider } from 'antd'
import { useEffect } from 'react'
import ProfileTitle from './ProfileTitle'
import ProfileInfo from './ProfileInfo'

const DoctorDetailModal = ({
    selectedDoctor = {},
    handleCancelModal,
    handleDeleteModalOpen,
}) => {

    const Main = styled.div`

    .content {
        padding:30px;
        background-color:#ffffff;
    }
    `





    const handleDelete = () => {
        handleCancelModal()
        handleDeleteModalOpen(selectedDoctor)
    }
    return (<>
        <Main>
            <div className="content">
                <ProfileTitle
                    doctor={selectedDoctor}
                    handleDeleteModalOpen={handleDelete}
                />
                <Divider />
                <ProfileInfo doctor={selectedDoctor} />
            </div>
        </Main>
    </>)
}

export default DoctorDetailModal