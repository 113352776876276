import React, { useState, useEffect } from "react";
import { Modal, Tabs, Col } from "antd";
import EmptyComponent from '../../Components/EmptyComponent';
import EmployeeCard from './EmployeeCard';
import PatientDetailsModal from '../list-patients/DetailsModal';
import ApiCalls from "../../apis/ApiCalls";
import { 
  CreateCompanyWrapper, 
  Title, 
  StyledSpin, 
  CardsWrapper,
  CustomTabs,
  StyledCard,
  StyledDiv 
} from "./styledComponents";

const { TabPane } = Tabs;

const Employees = ({ company }) => {
  const [loading, setLoading] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [detailsModal, setDetailsModal] = useState({ show: false, id: null });
  const [activeTab, setActiveTab] = useState(0);

  // Get company employees
  const getCompanyEmployees = async() => {
    setLoading(true);
    try {
      let { data } = await ApiCalls.getCompanyEmployees(company?._id, activeTab);
      if(data?.data?.data?.length > 0 && data?.status) {
        setEmployeeList(data.data.data);
      } else {
        setEmployeeList([]);
      }
      setLoading(false);
    } catch (error) {
      setEmployeeList([]);
      setLoading(false);
    }
  }

  useEffect(() => {
    getCompanyEmployees();
  }, [activeTab]);

  // View employee details
  const viewEmployeeDetails = (item) => {
    setDetailsModal({ show: true, id: item?._id });
  }

  return (
    <CreateCompanyWrapper>

      {/* Title */}
      <Title>Company Employees</Title>

      {/* Tabs */}
      <StyledCard>
        <Col span={24}>
          <StyledDiv>
            <CustomTabs
              defaultActiveKey="0"
              size="large"
              onChange={setActiveTab}
            >
              <TabPane
                tab={<div className="tabs-wrapper">Unverified</div>}
                key="0"
              />
              <TabPane
                tab={<div className="tabs-wrapper">Verified</div>}
                key="1"
              />
            </CustomTabs>
          </StyledDiv>
        </Col>
      </StyledCard>

      {loading ? (
        <StyledSpin />
      ) : (
        employeeList?.length > 0 ? (

          // Employees list
          <CardsWrapper>
            <EmployeeCard data={employeeList} viewDetails={viewEmployeeDetails} />
          </CardsWrapper>

        ) : (
          <EmptyComponent message="No data found" />
        )
      )}

      <Modal
        visible={detailsModal.show}
        onCancel={() => setDetailsModal({ show: false, id: null })}
        footer={false}
        width={"72%"}
        centered={true}
        destroyOnClose={true}
      >
        <PatientDetailsModal id={detailsModal.id} fromAppointments />
      </Modal>

    </CreateCompanyWrapper>
  );
}

export default Employees;