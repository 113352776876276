import React, { useState } from "react";
import styled from "styled-components";
import { Card, Tabs, Col } from "antd";
import { Input, Row } from "antd";
import CustomLayout from "../../Components/CustomLayout";
import ListController from "./ListController";

const { TabPane } = Tabs;

const Main = styled.div`
  padding: 0 5%;
  font-family: "Montserrat", sans-serif;
`;

const CustomTabs = styled(Tabs)`
  width: 100%;

  .ant-tabs-nav {
    width: 100%;
    margin: 0;
    & > div {
      width: 100%;
    }
  }
  .ant-tabs-tab-active {
    color: #3e8afa;
    svg {
      /* fill: #1890ff; */
    }
  }
  .ant-tabs-tab:hover {
    color: #1890ff;
    svg {
      /* fill: #1890ff; */
    }
  }
  .ant-tabs-tab {
    width: 250px;
    justify-content: center;
    font-weight: 600;
  }
  .tabs-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    svg {
      margin-right: 8px;
    }
  }
  .ant-tabs-ink-bar {
    height: 3px;
    /* background-color: #1890ff; */
  }
`;

const StyledCard = styled(Card)`
  width: 100%;
`;
const StyledDiv = styled.div`
  // display: flex;
  background-color: white;
  padding: 0 2%;
`;

const StyledListContainer = styled.div`
  position: relative;
  min-height: calc(100vh - 350px);
  & > .ant-spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const ManageDoctors = (props) => {
  const [searchKey, setSearchKey] = useState("");
  const [activeTab, setActiveTab] = useState("waiting-for-approval");

  const handleSearch = (value) => {
    setSearchKey(value);
  };

  const handleTabChange = (value) => {
    setActiveTab(value);
  };

  return (
    <Main>
      <CustomLayout
        mainTitle="Manage Therapists"
        makePaddingZero={true}
        headerComponent={
          <Row style={{ padding: "24px" }}>
            <Col>
              <Input.Search
                allowClear
                onChange={(event) => handleSearch(event.target.value)}
                value={searchKey}
                autoFocus={true}
              />
            </Col>
          </Row>
        }
      ></CustomLayout>

      <StyledCard>
        <Col span={24}>
          <StyledDiv>
            <CustomTabs
              defaultActiveKey="1"
              size="large"
              onChange={handleTabChange}
            >
              <TabPane
                tab={<div className="tabs-wrapper">Waiting For Approval</div>}
                key="waiting-for-approval"
              />

              <TabPane
                tab={<div className="tabs-wrapper">Accepted</div>}
                key="accepted"
              />
              <TabPane
                tab={<div className="tabs-wrapper">Rejected</div>}
                key="rejected"
              />
            </CustomTabs>
          </StyledDiv>
        </Col>
      </StyledCard>

      <StyledListContainer>
        <ListController selectedTab={activeTab} searchKey={searchKey} />
      </StyledListContainer>
    </Main>
  );
};

export default ManageDoctors;
