import React, { useEffect } from "react";
import { Input, message } from "antd";
import { StyledForm, StyledModal } from "../MedicineLibrary/styledComponents";

const ManageServiceForm = ({
  selectedValue,
  showServiceModal,
  setShowServiceModal,
  onFinish,
}) => {
  const [form] = StyledForm.useForm();

  useEffect(() => {
    form.resetFields();
    if (selectedValue) form.setFieldsValue({ ...selectedValue });
  }, [showServiceModal]);

  return (
    <>
      <StyledModal
        visible={showServiceModal}
        closable={false}
        title={`${selectedValue ? "Edit" : "Add New"} Service`}
        centered={true}
        onCancel={() => setShowServiceModal(false)}
        onOk={() => form.submit()}
        key={showServiceModal}
      >
        <StyledForm form={form} onFinish={onFinish}>
          <StyledForm.Item
            name="serviceName"
            label="Service Name"
            rules={[
              {
                required: true,
                message: "Service name is required",
              },
              { max: 60, message: "Character limit is 60" },
            ]}
          >
            <Input />
          </StyledForm.Item>
        </StyledForm>
      </StyledModal>
    </>
  );
};

export default ManageServiceForm;
