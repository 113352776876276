import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Row, Col, Button, Form, Select, Input, Radio, DatePicker, Spin } from 'antd';
import moment from "moment"
import ApiCalls from '../../../apis/ApiCalls';

const StyledCol = styled(Col)`



    .ant-radio-button-wrapper {
      width: 200px;
      display: flex;
      justify-content: center;
      /* height: 50px; */
      align-items: center;
      /* margin: 12px */
      border-radius: 5px;
      height:57px;
      text-transform:capitalize;

    }
    .ant-radio-group {
      display: flex;
      width: 100%;

    }
    .ant-radio-button-wrapper-checked {
      color: '#000000';
      height:57px;
    }
    .ant-picker {
      width: 100%;
      border-radius: 5px;
      padding: 15.5px ;
      text-transform:capitalize;

    }
    .ant-btn {
      border-radius: 25px;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      width: 170px;
      height: 40px;
      text-transform: uppercase;
      letter-spacing: 0.04em;
      color: #ffffff;
    }

    .ant-form-item-label > label {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #6b6b6b;   
      padding-left: 15px;
      text-transform:capitalize;

    }

    .ant-select-multiple .ant-select-selector,
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      border-radius: 5px;
      padding: 10.5px ;
      text-transform:capitalize;

    }

    .ant-form-item-label > label.ant-form-item-required::before {
      display: none;
    }

    .ant-input-lg {
      padding: 15.5px 15px;
      font-size: 16px;
      color: #2f2f2f;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 700;
      text-transform:capitalize;

    }

    
  `

const StyledInput = styled(Input)`
    border-radius: 5px;
  `






const ProfileInfo = (props) => {

    const { doctor } = props

    const [initialValues, setInitialValues] = useState({})
    const [specializationList, setSpecializationList] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        console.log(doctor.specialization, doctor.city, props, "hello")
        setInitialValues({
            firstName: doctor.firstName,
            lastName: doctor.lastName,
            gender: doctor.gender,
            email: doctor.email,
            city: doctor.city,
            dob:doctor.dob
        })
    }, [])

    useEffect(() => {
        setLoading(true)
        ApiCalls.getSpecialisationList().then(res => {
            if (res.data.status === 1) {
                setLoading(false)
                setSpecializationList(res.data.data)
            }
        })
    }, [])



    return (
        <Spin spinning={loading}>


            <Form
                layout='vertical'
                initialValues={{
                    ...doctor,
                    dob:moment(doctor.dob).format('DD-MM-YYYY')
                }}

            >
                <Row gutter={24}>
                    <StyledCol
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 10 }}
                        lg={{ span: 12 }}
                    >
                        <Form.Item
                            name='firstName'
                            label='First Name*'
                        >
                            <StyledInput placeholder='First Name' size='large' readOnly />
                        </Form.Item>
                    </StyledCol>
                    <StyledCol
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 10 }}
                        lg={{ span: 12 }}
                    >
                        <Form.Item
                            name='lastName'
                            label='Last Name*'
                        >
                            <StyledInput placeholder='Last Name' size='large' readOnly />
                        </Form.Item>
                    </StyledCol>
                </Row>
                <Row gutter={24}>
                    <StyledCol
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                    >
                        <Form.Item
                            name='gender'
                            label='Gender*'
                        >
                            {/* <Radio.Group default='male' size='large'  >
                                <Radio.Button value='male'>Male</Radio.Button>
                                <Radio.Button value='female'>Female</Radio.Button>
                            </Radio.Group> */}
                            <Input readOnly  size='large'/>
                        </Form.Item>
                    </StyledCol>
                    <StyledCol
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                    >
                        <Form.Item name='dob' label='Date of Birth'>
                            <Input size='large' readOnly/>
                        </Form.Item>
                    </StyledCol>
                </Row>
                <Row gutter={24}>
                    <StyledCol
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 10 }}
                        lg={{ span: 12 }}
                    >
                        <Form.Item
                            name='email'
                            label='Email*'
                        >
                            <StyledInput readOnly placeholder='Email' size='large' style={{ textTransform: "lowercase" }} />
                        </Form.Item>
                    </StyledCol>
                    <StyledCol
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 10 }}
                        lg={{ span: 12 }}
                    >
                        <Form.Item
                            name='state'
                            label='State'
                        >
                            {/* <Select
                        placeholder='Select State'
                        // mode="multiple"
                        size='large'
                    >
                        {props.cities.map(city => (
                            <Select.Option value={city._id}>{city.name}</Select.Option>
                        ))}
                    </Select> */}
                            <StyledInput  size='large' readOnly defaultValue="NA"/>

                        </Form.Item>
                    </StyledCol>
                </Row>
                <Row gutter={24}>
                    {/* <StyledCol
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                    >
                        <Form.Item
                            name='officePhone'
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: "Office phone number is required",
                            //     },
                            // ]}
                            label='Office Phone*'

                        >
                            <StyledInput placeholder='Office Phone' size='large' readOnly />
                        </Form.Item>
                    </StyledCol> */}
                    <StyledCol
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                    >
                        <Form.Item
                            name='mobile'
                            label='Mobile Number'
                        >
                            <StyledInput placeholder='Cell Phone' size='large' readOnly />
                        </Form.Item>
                    </StyledCol>
                </Row>
            </Form >
        </Spin >
    )
}

export default ProfileInfo