import React, { useState } from "react";
import CustomLayout from "../../Components/CustomLayout";
import { Col, Input, Tabs, Row } from "antd";
import styled from "styled-components";
import PatientTable from "./PatientTable";
import SearchContext from "./SearchContext";

const PatientInsurance = () => {
  const { TabPane } = Tabs;

  const Wrapper = styled.div`
    .ant-tabs-tab {
      padding: 0px;
    }
    .tabs-wrapper {
      background: #fff;
      padding: 8px 16px;
      padding: 8px 16px;
      width: 100%;
      margin-bottom: 8px;
      font-size: 16px;
      font-weight: bold;
    }
    .ant-tabs-ink-bar {
      background: #2959a8;
      height: 3px;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      font-weight: 600;
    }
    .tabs-wrapper {
      font-family: Montserrat;
      size: 16px;
      font-weight: 600;
    }
  `;
  const insuranceStatuses = {
    PENDING: "pending",
    APPROVED: "approved",
    REJECTED: "rejected",
  };

  const [activeKey, setActiveKey] = useState(insuranceStatuses.PENDING);
  const [searchKey, setSearchKey] = useState();

  const handleSearch = (e) => {
    setSearchKey(e.target.value);
  };

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  return (
    <CustomLayout
      mainTitle="Patient Insurance Card"
      headerComponent={
        <Row>
          <Col>
            <Input.Search
              onChange={handleSearch}
              value={searchKey}
              autoFocus={true}
            />
          </Col>
        </Row>
      }
    >
      <SearchContext.Provider value={searchKey}>
        <Wrapper>
          <div className="tabs-wrapper">
            <Tabs
              defaultActiveKey="1"
              size="large"
              onChange={handleTabChange}
              activeKey={activeKey}
            >
              <TabPane tab="Pending" key={insuranceStatuses.PENDING}>
                <PatientTable activeKey={activeKey} />
              </TabPane>
              <TabPane tab="Approved" key={insuranceStatuses.APPROVED}>
                <PatientTable activeKey={activeKey} />
              </TabPane>
              <TabPane tab="Rejected" key={insuranceStatuses.REJECTED}>
                <PatientTable activeKey={activeKey} />
              </TabPane>
            </Tabs>
          </div>
        </Wrapper>
      </SearchContext.Provider>
    </CustomLayout>
  );
};
export default PatientInsurance;
