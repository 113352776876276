import React from "react";
import styled from "styled-components";
import { Card, Col, Row, Button, Typography, Pagination, Spin } from "antd";
import ProfileAvatar from "../../../Components/ProfileAvatar";
import {
  UserOutlined,
  DeleteOutlined,
  CodeSandboxSquareFilled,
} from "@ant-design/icons";

import ApiCalls from "../../../apis/ApiCalls";

import { useState, useEffect } from "react";
import PatientCard from "../PatientCard";
import EmptyComponent from "../../../Components/EmptyComponent";

const CardsWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
`;

let PatientList = ({ searchTerm = "" }) => {
  let [patients, setPatients] = useState([]);
  let [loading, setLoading] = useState(true);
  let [totalPages, setTotalPages] = useState(0);
  let [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    getPatients();
  }, [currentPage, searchTerm]);

    useEffect(() => {
    setCurrentPage(0);
  }, [searchTerm]);

  const getPatients = async () => {
    try {
      setLoading(true);

      let { data } = await ApiCalls.getPatientList(searchTerm, currentPage, 6);
      console.log(data, "from patient");
      setPatients(data.data.patients);
      setTotalPages(data.data.patientCount);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handlePageChange = (pageNumber, pageLimit) => {
    setCurrentPage(pageNumber - 1);
  };
  return (
    <>
      {!loading && (
        <CardsWrapper>
          {patients.length === 0 && !loading && (
            <EmptyComponent message={"No data found"} />
          )}
          {patients.map((item) => {
            return (
              <PatientCard
                name={item.name}
                key={item._id}
                email={item.email}
                id={item._id}
                details={item}
                profilePic={item.profilePic}
              />
            );
          })}
        </CardsWrapper>
      )}
      {loading && (
        <Spin
          spinning={loading}
          size="large"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        />
      )}
      {!loading && (
        <PaginationWrapper>
          <Pagination
            defaultCurrent={1}
            total={totalPages}
            pageSize={6}
            hideOnSinglePage={true}
            onChange={handlePageChange}
            current={currentPage + 1}
          />
        </PaginationWrapper>
      )}
    </>
  );
};

export default PatientList;
