import ManageUserIcon from "../../images/js/ManageUserIcon";
import ManageInstitutionIcon from "../../images/js/ManageInstitutionIcon";
import ManageRevenueIcon from "../../images/js/ManageRevenueIcon";
import ManageFormIcon from "../../images/js/ManageFormIcon";
import InsuranceIcon from "../../images/js/InsuranceIcon";

const AdminLinks = [
  {
    key: "0",
    name: "Dashboard",
    path: "/base/home/dashboard",
    icon: ManageFormIcon
  },
  {
    key: "1",
    name: "Manage Therapists",
    path: "/base/home/manage-doctors",
    icon: ManageFormIcon
  },
  {
    key: "2",
    name: "Manage Refunds",
    path: "/base/home/manage-refunds",
    icon: ManageFormIcon
  },
  // {
  //   key: "3",
  //   name: "Manage Complaints",
  //   path: "/base/home/manage-complaints",
  //   icon: ManageFormIcon
  // },
  {
    key: "4",
    name: "List Appointments",
    path: "/base/home/list-appointments",
    icon: ManageFormIcon
  },
  {
    key: "5",
    name: "List Patients",
    path: "/base/home/list-patients",
    icon: ManageFormIcon
  },
  {
    key: "6",
    name: "Manage Services",
    path: "/base/home/services",
    icon: ManageFormIcon
  },
  {
    key: "7",
    name: "Manage Specializations",
    path: "/base/home/specializations",
    icon: ManageFormIcon,
  },
  // {
  //   key: "8",
  //   name: "Medicine Library",
  //   path: "/base/home/medicine-library",
  //   icon: ManageFormIcon
  // },
  {
    key: "9",
    name: "Manage Users",
    path: "/base/home/manage-users",
    icon: ManageUserIcon,
  },
  // {
  //   key: "10",
  //   name: "Manage Institutions",
  //   path: "/base/home/manage-institutions",
  //   icon: ManageInstitutionIcon
  // },
  // {
  //   key: "11",
  //   name: "Validate Insurance Card",
  //   path: "/base/home/insurance-card",
  //   icon: InsuranceIcon
  // },
  // {
  //   key: "12",
  //   name: "Insurance Claims",
  //   path: "/base/home/insurance-claims",
  //   icon: ManageRevenueIcon
  // },
  // {
  //   key: "13",
  //   name: "Manage Reports",
  //   path: "/base/home/manage-reports",
  //   icon: ManageFormIcon
  // },
  // {
  //   key: "14",
  //   name: "Corporate Subscriptions",
  //   path: "/base/home/corporate-subscriptions",
  //   icon: ManageFormIcon
  // },
  {
    key: "15",
    name: "Manage Coupons",
    path: "/base/home/manage-coupons",
    icon: ManageFormIcon
  },
  // {
  //   key: "16",
  //   name: "Manage Categories",
  //   path: "/base/home/manage-categories",
  //   icon: ManageFormIcon
  // },
  // {
  //   key: "17",
  //   name: "Manage Companies",
  //   path: "/base/home/manage-companies",
  //   icon: ManageFormIcon
  // },
  // {
  //   key: "18",
  //   name: "Survay Form",
  //   path: "/base/home/survay-form",
  //   icon: ManageFormIcon
  // },
  {
    key: "19",
    name: "Manage banner",
    path: "/base/home/banner-content",
    icon: ManageFormIcon
  }
];

export default AdminLinks;
