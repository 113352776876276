import React, { useState } from "react";
import styled from "styled-components";
import {
  Row,
  Col,
  Typography,
  Tooltip,
  Button,
  Divider,
  Tabs,
  DatePicker,
  TimePicker,
  Form,
  message,
  Input,
  Select,
} from "antd";

import Modal from "antd/lib/modal/Modal";
import moment from "moment";
import Report from "./Report";
import { useEffect } from "react";
import CustomText from "../../Components/CustomText";
import ApiCalls from "../../apis/ApiCalls";

const StyledRow = styled.div`
  width: 100%;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;

  .ant-picker-suffix {
    color: #e79c23;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .ant-form-item-has-error .ant-form-item-explain,
  .ant-form-item-has-error .ant-form-item-split {
    font-family: "Montserrat", sans-serif;
  }
`;
const StyledForm = styled(Form)`
  width: 100%;
`;

const SubmitButton = styled.div`
  width: 100%;
  font-family: "Montserrat", sans-serif;

  .ant-btn-primary {
    background: #e79c23;
    border: 0;
    border-radius: 5px;
    height: 44px;
    font-size: 16px;
    width: 230px;
    align-items: center;
    font-weight: 700;
  }
`;

const StyledFormItem = styled(Form.Item)`
  font-family: "Montserrat", sans-serif;
  .ant-picker-input {
    padding: 10px;
  }

  .ant-picker-input > input {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: bold;
  }
  .ant-select-selection-item {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: bold;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    font-weight: bold;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
    padding: 4px 11px;
  }
`;

const CalendarFilterForm = ({ type }) => {
  const { Option } = Select;
  const [openModal, setOpenModal] = useState(false);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [startdate, setStartDate] = useState(null);
  const [hospitalList, setHospitalList] = useState([]);
  const [selectedHospital, setSelectedHospital] = useState();
  const [form] = Form.useForm();

  const handleStartDateChange = (event) => {
    setStartDate(event);
  };

  function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  function disabledNextDate(current) {
    return current && current < startdate;
  }

  function disabledDateTime() {
    return {
      disabledHours: () => range(0, 24).splice(4, 20),
      disabledMinutes: () => range(30, 60),
      disabledSeconds: () => [55, 56],
    };
  }

  const OnSubmit = (value) => {
    setStart(value.startDate);
    setEnd(moment(value.endDate));
    setSelectedHospital(value.selectedHospital);
    setOpenModal(true);
  };

  const handleCancelModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    ApiCalls.getHospitalList().then((res) => {
      if (res.data.status) {
        setHospitalList(res.data.data);
      } else {
      }
    });
  }, []);

  return (
    <>
      <StyledForm
        layout="vertical"
        form={form}
        initialValues={{
          layout: "vertical",
        }}
        onFinish={OnSubmit}
      >
        <Row>
          <CustomText color="#5472a3" size="1vw" fontWeight="600">
            {
              {
                feedback: "Feedback Report",
                payment: "Payment Report",
                consultation: "Consultation Report",
              }[type]
            }
          </CustomText>
        </Row>
        <Row style={{ width: "100%" }}>
          <Col span={24}>
            <StyledRow>
              <div>
                <CustomText color="#A4A4A4" fontWeight="600">
                  From
                </CustomText>
                <StyledFormItem
                  name="startDate"
                  rules={[
                    {
                      required: true,
                      message: "Start Date is required",
                    },
                  ]}
                >
                  <DatePicker
                    format="LL"
                    onChange={(event) => handleStartDateChange(event)}
                  />
                </StyledFormItem>
              </div>
              <div>
                <CustomText color="#A4A4A4" fontWeight="600">
                  To
                </CustomText>

                <StyledFormItem
                  name="endDate"
                  rules={[
                    {
                      required: true,
                      message: "End Date is required",
                    },
                  ]}
                >
                  <DatePicker
                    disabledDate={disabledNextDate}
                    disabledTime={disabledDateTime}
                    format="LL"
                  />
                </StyledFormItem>
              </div>
            </StyledRow>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <StyledFormItem
              name="selectedHospital"
              rules={[
                {
                  required: true,
                  message: "Select Hospital",
                },
              ]}
            >
              <Select style={{ width: "100%" }} placeholder="Select Hospital">
                {hospitalList.map((item) => {
                  return <Option value={item._id}>{item.hospital}</Option>;
                })}
              </Select>
            </StyledFormItem>
          </Col>
        </Row>
        <Divider />
        <Row justify="end">
          <Col>
            <SubmitButton>
              <Button type="primary" htmlType="submit">
                Print Report
              </Button>
            </SubmitButton>
          </Col>
        </Row>
        <Modal
          visible={openModal}
          onCancel={handleCancelModal}
          footer={false}
          width={"1000px"}
          centered={true}
        >
          <Report
            type={type}
            start={start}
            end={end}
            selectedHospital={selectedHospital}
          />
        </Modal>
      </StyledForm>
    </>
  );
};

export default CalendarFilterForm;
