import React from "react";
import { 
  StyledColumn, 
  StyledRow, 
  StyledTable, 
  StyledText, 
  StyledButton
} from "./styledComponents";

const CouponCard = ({ data, deleteCoupon, viewCouponDetails, editCoupon }) => {
  return (
    <StyledTable>

      {/* Header */}
      <StyledRow>
        <StyledColumn header={true}>Coupon Code</StyledColumn>
        <StyledColumn header={true}>Coupon usage count</StyledColumn>
        <StyledColumn header={true}>Discount Percentage</StyledColumn>
      </StyledRow>

      {data?.map(item => (
        <StyledRow key={item._id}>
          <StyledColumn>
            <StyledText>{item?.couponCode}</StyledText>
          </StyledColumn>
          <StyledColumn>
            <StyledText>{item?.couponUsageCount}</StyledText>
          </StyledColumn>
          <StyledColumn>
            <StyledText>{item?.discountPercentage}</StyledText>
          </StyledColumn>
          <StyledColumn>
            <StyledButton onClick={() => viewCouponDetails(item._id)}>
              <p>View Coupon Details</p>
            </StyledButton>
          </StyledColumn>
          <StyledColumn>
            <StyledButton onClick={() => editCoupon(item._id)}>
              <p>Edit Coupon</p>
            </StyledButton>
          </StyledColumn>
          <StyledColumn>
            <StyledButton type="delete" onClick={() => deleteCoupon(item._id)}>
              <p>Delete Coupon</p>
            </StyledButton>
          </StyledColumn>
        </StyledRow>
      ))}

    </StyledTable>
  );
}

export default CouponCard;