import React from "react";
import Logo_telehealing from "../svg/telehealing_logo.svg"

function Logo() {
  return (
    <img src={Logo_telehealing} alt="logo"/>
  );
}

export default Logo;
