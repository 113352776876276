import React from 'react'
import { Table, Row, Col, Button, Divider, Rate, Spin } from 'antd'
import CustomText from '../../Components/CustomText'
import styled from 'styled-components'
import { PrinterOutlined } from '@ant-design/icons'
import Logo from '../../images/js/Logo'
import moment from 'moment'
import { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import { useEffect } from 'react'
import ApiCalls from '../../apis/ApiCalls'
import { useState } from 'react'

const StyledDiv = styled.div`
  font-family: 'Montserrat', sans-serif;
  @media print {
    @page {
      size: auto;
      margin: 5mm;
    }
  }
`

const Report = ({ type, start, end, selectedHospital }) => {
  const { Column } = Table
  const componentRef = useRef()

  const [tableData, setTableData] = useState([])
  const [totalRevenue, setTotalRevenue] = useState(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    console.log(start, end)
    setLoading(true)
    ApiCalls.getHospitalReport({
      type: 'admin',
      startDate: moment(start).toISOString(),
      endDate: moment(end).toISOString(),
      hospitalId: selectedHospital
    }).then(response => {
      console.log(response.data.data)
      setTableData(
        response.data.data.length > 0 ? response.data.data[0].data : []
      )
      setTotalRevenue( response.data.data.length > 0 ?response.data.data[0].totalRevenue:'NA')

      setLoading(false)
    })
  }, [start, end])

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: ' Report',
    removeAfterPrint: true
  })

  return (
    <>
      <StyledDiv ref={componentRef}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            fontSize: '18px',
            fontFamily: 'Montserrat',
            justifyContent: 'space-between'
          }}
        >
          <div style={{ paddingLeft: '10px', margin: '1%' }}>
            {type === 'consultation' && (
              <CustomText
                color='#5472a3'
                size='24px'
                fontWeight='800'
                style={{ marginTop: '0.5em', marginBottom: '0' }}
              >
                Consultation Report
              </CustomText>
            )}
            {type === 'payment' && (
              <CustomText
                color='#5472a3'
                size='24px'
                fontWeight='800'
                style={{ marginTop: '0.5em', marginBottom: '0' }}
              >
                Payment Report
              </CustomText>
            )}
            {type === 'feedback' && (
              <CustomText
                color='#5472a3'
                size='24px'
                fontWeight='800'
                style={{ marginTop: '0.5em', marginBottom: '0' }}
              >
                Therapist Feedback Report
              </CustomText>
            )}
            <div style={{ paddingTop: '10px' }}>
              <CustomText color='black' size='14.4px' fontWeight='600'>
                {moment().format('LLL')}
              </CustomText>
            </div>
            <Row align="middle">
              <Col>
                <CustomText color='blue' size='14.4px' fontWeight='600'>
                  {`Total Revenue: ${totalRevenue}`}
                </CustomText>
              </Col>
            </Row>
          </div>
          <div style={{ margin: '1%', width: '220px' }}>
            <Col>
              <div style={{ margin: ' 3% 0 10%' }}>
                <Logo width='159px' height='30.7px' />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <div>
                  <CustomText color='black' size='14.4px' fontWeight='600'>
                    From
                  </CustomText>
                  <CustomText color='#5472a3' size='14.4px' fontWeight='600'>
                    {moment(start).format('ll')}
                  </CustomText>
                </div>
                <div>
                  <CustomText color='black' size='14.4px' fontWeight='600'>
                    To
                  </CustomText>
                  <CustomText color='#5472a3' size='14.4px' fontWeight='600'>
                    {moment(end).format('ll')}{' '}
                  </CustomText>
                </div>
              </div>
            </Col>
          </div>
        </div>
        <Divider />
        {tableData ? (
          <Spin spinning={loading}>
            <Table dataSource={tableData} size='small' pagination={false}>
              <Column
                title='Index'
                key='index'
                render={(value, item, index) => (
                  <CustomText color='blue' size='18px'>
                    {index + 1}
                  </CustomText>
                )}
                align="center"
              />
              <Column
                title='Provider'
                dataIndex='firstName'
                key='name'
                render={(name, record) => (
                  <CustomText color='blue' size='18px'>
                    {record.firstName}&nbsp;{record.lastName}
                  </CustomText>
                )}
                align="center"

              />
              {type === 'feedback' && (
                <>
                  <Column
                    title='Average Rating'
                    dataIndex='rating'
                    key='rating'
                    render={rating => <Rate disabled defaultValue={rating} />}
                    align="center"
                  />
                  <Column
                    title='Total Consultation'
                    dataIndex='totalConsultation'
                    key='totalConsultation'
                    align="center"
                    render={consult => (
                      <CustomText color='#5472a3' size='18px'>
                        {consult}
                      </CustomText>
                    )}
                  />
                  <Column
                    title='Votes'
                    dataIndex='votes'
                    key='votes'
                    align="center"
                  />
                </>
              )}



              {type === 'consultation' && (
                <>
                  <Column
                    title='Total Consultation'
                    dataIndex='totalConsultation'
                    key='totalConsultation'
                    align="center"
                  />
                  <Column
                    title='Total amount recieved'
                    dataIndex='totalConsultationAmount'
                    key='totalConsultationAmount'
                    align="center"
                  />
                  <Column
                    title='Total amount refunded'
                    dataIndex='totalrefundAmount'
                    key='totalrefundAmount'
                    align="center"
                  />
                  <Column
                    title='Net Amount'
                    dataIndex='totalAmount'
                    key='totalAmount'
                    align="center"
                  />
                </>
              )}
            </Table>
          </Spin>
        ) : (
            'No Data Available'
          )}
      </StyledDiv>

      <div style={{ marginTop: '16px' }}>
        <Row justify='end'>
          <Button
            type='primary'
            icon={<PrinterOutlined />}
            onClick={handlePrint}
          >
            Print
          </Button>
        </Row>
      </div>
    </>
  )
}

export default Report
