export const MONTH_DATA = [
  {
    type: '01-06-2022',
    value: 7
  },
  {
    type: '02-06-2022',
    value: 10
  },
  {
    type: '03-06-2022',
    value: 24
  },
  {
    type: '04-06-2022',
    value: 18
  },
  {
    type: '05-06-2022',
    value: 0
  },
  {
    type: '06-06-2022',
    value: 19
  },
  {
    type: '07-06-2022',
    value: 30
  },
  {
    type: '08-06-2022',
    value: 5
  },
  {
    type: '09-06-2022',
    value: 28
  },
  {
    type: '10-06-2022',
    value: 12
  },
  {
    type: '11-06-2022',
    value: 20
  },
  {
    type: '12-06-2022',
    value: 0
  },
  {
    type: '13-06-2022',
    value: 30
  },
  {
    type: '14-06-2022',
    value: 30
  },
  {
    type: '15-06-2022',
    value: 28
  },
  {
    type: '16-06-2022',
    value: 26
  },
  {
    type: '17-06-2022',
    value: 22
  },
  {
    type: '18-06-2022',
    value: 20
  },
  {
    type: '19-06-2022',
    value: 0
  },
  {
    type: '20-06-2022',
    value: 30
  },
  {
    type: '21-06-2022',
    value: 29
  },
  {
    type: '22-06-2022',
    value: 29
  },
  {
    type: '23-06-2022',
    value: 8
  },
  {
    type: '24-06-2022',
    value: 29
  },
  {
    type: '25-06-2022',
    value: 12
  },
  {
    type: '26-06-2022',
    value: 0
  },
  {
    type: '27-06-2022',
    value: 22
  },
  {
    type: '28-06-2022',
    value: 29
  },
  {
    type: '29-06-2022',
    value: 20
  },
  {
    type: '30-06-2022',
    value: 21
  }
];

export const WEEK_DATA = [
  {
    type: 'Sunday',
    value: 0
  },
  {
    type: 'Monday',
    value: 0
  },
  {
    type: 'Tuesday',
    value: 0
  },
  {
    type: 'Wednesday',
    value: 0
  },
  {
    type: 'Thursday',
    value: 0
  },
  {
    type: 'Friday',
    value: 0
  },
  {
    type: 'Saturday',
    value: 0
  }
];

export const YEAR_DATA = [
  {
    type: 'January',
    value: 600
  },
  {
    type: 'February',
    value: 590
  },
  {
    type: 'March',
    value: 475
  },
  {
    type: 'April',
    value: 498
  },
  {
    type: 'May',
    value: 478
  },
  {
    type: 'June',
    value: 578
  },
  {
    type: 'July',
    value: 598
  },
  {
    type: 'August',
    value: 598
  },
  {
    type: 'September',
    value: 598
  },
  {
    type: 'October',
    value: 689
  },
  {
    type: 'November',
    value: 598
  },
  {
    type: 'December',
    value: 588
  }
];