import React, { useState } from "react";
import { Row, Col, Divider, Input, message } from "antd";
import CustomText from "../../Components/CustomText";
import styled from "styled-components";
import CustomButton from "../../Components/CustomButton";
import ProfileAvatar from "../../Components/ProfileAvatar";
import { UserOutlined } from "@ant-design/icons";
import moment from "moment";
import FileCard from "../../Components/FileCard";
import ApiCalls from "../../apis/ApiCalls";
const InsuranceDetails = ({
  handleCancelModal,
  patient = {},
  handleRefetch,
}) => {
  const Wrapper = styled.div`
    padding: 16px 24px;
  `;
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);

  const { healthCardInfo = {} } = patient;

  const handleAccept = () => {
    setAcceptLoading(true);
    ApiCalls.updatePatientInsuranceStatus(healthCardInfo._id, "approved")
      .then((res) => {
        setAcceptLoading(false);
        if (res.data.status === 1) {
          message.success("Insurance card approved");
          handleCancelModal();
          handleRefetch();
        }
      })
      .catch((error) => {
        setAcceptLoading(false);
        if (error.response) {
          message.info(error.response.data.data.message);
        } else if (error.request) {
          message.error("Server not responding");
        } else {
          message.error("Something went wrong");
        }
      });
  };
  const handleDecline = () => {
    setRejectLoading(true);
    ApiCalls.updatePatientInsuranceStatus(healthCardInfo._id, "rejected")
      .then((res) => {
        setRejectLoading(false);
        if (res.data.status === 1) {
          handleRefetch();
          message.success("Insurance card rejected");
          handleCancelModal();
        }
      })
      .catch((error) => {
        setRejectLoading(false);
        if (error.response) {
          message.info(error.response.data.data.message);
        } else if (error.request) {
          message.error("Server not responding");
        } else {
          message.error("Something went wrong");
        }
      });
  };
  return (
    <Wrapper>
      <Row gutter={16} justify="right" align="middle">
        <Col span={{ xs: 24, sm: 24, md: 24 }}>
          <Row justify="center" align="middle">
            <ProfileAvatar
              url={patient.profilePic}
              size={120}
              icon={<UserOutlined />}
            />
          </Row>
        </Col>
        <Col>
          <Row justify="right">
            <CustomText
              color="blue"
              size="18px"
              fontWeight="bold"
            >{`${patient.firstName} ${patient.lastName}`}</CustomText>
          </Row>
          <Row justify="right">
            <CustomText color="darkgrey" fontWeight="bold">
              {patient.email}
            </CustomText>
          </Row>
        </Col>
      </Row>
      <Divider />
      <Row gutter={24}>
        <Col span={12} style={{ marginBottom: "16px" }}>
          <CustomText>First Name</CustomText>
          <Input readOnly={true} value={patient.firstName} />
        </Col>
        <Col span={12} style={{ marginBottom: "16px" }}>
          <CustomText>Last Name</CustomText>
          <Input readOnly={true} value={patient.lastName} />
        </Col>
        <Col span={12} style={{ marginBottom: "16px" }}>
          <CustomText>Gender</CustomText>
          <Input readOnly={true} value={patient.gender} />
        </Col>
        <Col span={12} style={{ marginBottom: "16px" }}>
          <CustomText>DOB</CustomText>
          <Input
            readOnly={true}
            value={moment(patient.dob).format("DD/MM/YYYY")}
          />
        </Col>
        <Col span={12} style={{ marginBottom: "16px" }}>
          <CustomText>Email</CustomText>
          <Input readOnly={true} value={patient.email} />
        </Col>
        <Col span={12} style={{ marginBottom: "16px" }}>
          <CustomText>Mobile</CustomText>
          <Input readOnly={true} value={patient.mobile} />
        </Col>
      </Row>
      <Divider />
      <Row>
        <CustomText color="darkgrey" size="18px" fontWeight="bold">
          Insurance card details
        </CustomText>
      </Row>
      <Row gutter={24}>
        <Col span={12} style={{ marginBottom: "16px" }}>
          <CustomText>Provider Name</CustomText>
          <Input readOnly={true} value={healthCardInfo.insuranceCompany} />
        </Col>

        <Col span={12} style={{ marginBottom: "16px" }}>
          <CustomText>Policy Number</CustomText>
          <Input readOnly={true} value={healthCardInfo.policyNumber} />
        </Col>
        <Col span={12} style={{ marginBottom: "16px" }}>
          <CustomText>Expiry</CustomText>
          <Input
            readOnly={true}
            value={moment(healthCardInfo.expiryDate).format("DD/MM/YYYY")}
          />
        </Col>
        <Col span={12} style={{ marginBottom: "16px" }}>
          <CustomText>Type</CustomText>
          <Input readOnly={true} value={healthCardInfo.type} />
        </Col>
        {healthCardInfo.type === "copay" && !!healthCardInfo.percentage && (
          <Col span={12} style={{ marginBottom: "16px" }}>
            <CustomText>Percentage</CustomText>
            <Input readOnly={true} value={healthCardInfo.percentage} />
          </Col>
        )}
        {healthCardInfo.type === "copay" && !!healthCardInfo.fixedCopay && (
          <Col span={12} style={{ marginBottom: "16px" }}>
            <CustomText>Fixed Amount</CustomText>
            <Input readOnly={true} value={healthCardInfo.fixedCopay} />
          </Col>
        )}
        <Col span={12} style={{ marginBottom: "16px" }}>
          <CustomText>Emirates Id</CustomText>
          <Input readOnly={true} value={healthCardInfo.emiratesId} />
        </Col>
        <Col span={12} style={{ marginBottom: "16px" }}>
          <CustomText>Name as per in the emirates id card</CustomText>
          <Input readOnly={true} value={healthCardInfo.nameInEmiratesId} />
        </Col>
        <Col span={12} style={{ marginBottom: "16px" }}>
          <CustomText>Nationality</CustomText>
          <Input readOnly={true} value={healthCardInfo.nationality} />
        </Col>
      </Row>
      <Row>
        <CustomText>Insurance File</CustomText>
      </Row>
      <Row>
        <Col span={12} style={{ marginBottom: "16px" }}>
          <FileCard name="Insurance Card" url={healthCardInfo.cardImg} />
        </Col>
      </Row>
      <Row>
        <CustomText>Emirates Id Card</CustomText>
      </Row>
      <Row>
        <Col span={12} style={{ marginBottom: "16px" }}>
          <FileCard
            name="Emirates Id Card"
            url={healthCardInfo.emiratesCardImg}
          />
        </Col>
      </Row>
      {healthCardInfo.isApproved === "pending" && (
        <Row gutter={24} justify="end">
          <Col>
            <CustomButton onClick={handleDecline} loading={rejectLoading}>
              Reject
            </CustomButton>
          </Col>
          <Col>
            <CustomButton
              type="primary"
              onClick={handleAccept}
              loading={acceptLoading}
            >
              Approve Insurance Card
            </CustomButton>
          </Col>
        </Row>
      )}
    </Wrapper>
  );
};
export default InsuranceDetails;
