import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Typography, Card, Tabs, Col, Button, Input, Pagination, Row, Spin, Modal } from 'antd';
import { useState } from 'react'
import { FileAddOutlined } from '@ant-design/icons'
import AddInstitution from './AddInstitution'
import ApiCalls from '../../apis/ApiCalls';
import CustomText from '../../Components/CustomText';
import CardItem from './CardItem';
import { institutionTypes } from './institutionTypes';
import DetailedView from './DetailedView';
import DeleteView from './DeleteView';
const { TabPane } = Tabs
const Main = styled.div`
  padding: 3% 5%;
  font-family: 'Montserrat', sans-serif;

  .ant-input {
    background-color: transparent;
  }
  .ant-input-search {
    background-color: transparent;
  }
`
const MainTitle = styled(Typography)`
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 0.5em;
  color: #5472a3;
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
`

const ModalTitle = styled(Typography)`
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 0.5em;
  color: #5472a3;
  font-weight: bold;
  font-size: 24px;
  line-height: 37px;
`
const CustomTabs = styled(Tabs)`
  width: 100%;

  .ant-tabs-nav {
    width: 100%;
    margin: 0;
    & > div {
      width: 100%;
    }
  }
  .ant-tabs-tab-active {
    color: #3e8afa;
    
    svg {
      /* fill: #1890ff; */
    }
  }
  .tabs-wrapper{
    font-weight: 600;
  }
  .ant-tabs-tab:hover {
    color: #1890ff;
    svg {
      /* fill: #1890ff; */
    }
  }
  .ant-tabs-tab {
    width: 250px;
    justify-content: center;
    font-weight: 600;
  }
  .tabs-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      margin-right: 8px;
    }
  }
  .ant-tabs-ink-bar {
    height: 3px;
    /* background-color: #1890ff; */
  }
`
const StyledDiv = styled.div`
  // display: flex;
`

const StyledCard = styled(Card)`
  width: 100%;
`

const StyledButton = styled(Button)`
  width: 150px;
  border-radius: 20px;
  background-color: #e79c23;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  border: 0;
  color: white;
  margin-right: 10px;
`

// const FileStyle = styled(FileAddOutlined)`
//   height: 40px;
//   width: 40px;
//   color: #9e9e9e;
//   svg {
//     height: 90%;
//     width: 90%;
//   }
// `

const ManageInstitutions = () => {
  const [showAddModal, setShowAddModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [activeTab, setActivetab] = useState(institutionTypes.HOSPITALS)
  const [institutionList, setInstitutionList] = useState([])
  const [totalItems, setTotalItems] = useState(6)
  const [selectedInstitution, setSelectedInstitution] = useState({})
  const [pageNumber, setPageNumber] = useState(1)
  const [loading, setLoading] = useState(true)
  const [searchKey, setSearchKey] = useState("")
  const [refetchFlag, setRefetchFlag] = useState(false)
  const pageLimit = 5;

  const handleCancelModal = () => {
    setSelectedInstitution({})
    setShowAddModal(false)
    setShowAddModal(false)
    setShowEditModal(false)
    setShowDeleteModal(false)
  }

  const handleChangeTab = activeKey => {
    setActivetab(activeKey)
  }

  const handleAddInstitutions = () => {
    setShowAddModal(true)
  }

  const handleSearch = (e) => {
    setPageNumber(1)
    setSearchKey(e.target.value)
  }

  useEffect(() => {
    setLoading(true)
    ApiCalls.getInstitutionList(activeTab.toLowerCase(), searchKey, pageNumber-1, pageLimit).then(response => {
      if (response.data.status === 1) {
        setTotalItems(() => response.data.data.length > 0 ? response.data.data[0].count : 0)
        setInstitutionList(() => response.data.data.length > 0 ? response.data.data[0].data : [])
        response.data.data.length > 0 && Object.keys(selectedInstitution).length > 0 && 
        setSelectedInstitution((prev) => {
          const data = response.data.data[0].data
          const index =  data.findIndex((item) => prev._id === item._id)
          if (index !== -1) {
            return (data[index])
          }
        })
        setLoading(false)
      } else {
        setLoading(false)
        setInstitutionList([])
      }
    }).catch(error => {
      setLoading(false)
      setInstitutionList([])
    })
  }, [pageNumber, activeTab, searchKey, refetchFlag])

  const handlePageChange = (pageNumber, size) => {
    // setInstitutionList([])
    console.log(pageNumber)
    setPageNumber(pageNumber)
  }

  const handleRefetch = () => {
    setRefetchFlag(!refetchFlag)
  }

  const handleRemoveItem = (institution) => {
    setSelectedInstitution(institution)
    setShowDeleteModal(true)
  }
  const handleEditItem = (institution) => {
    setSelectedInstitution(institution)
    setShowEditModal(true)
  }

  return (
    <Main>
      <Row justify="space-between" align="middle">
        <Col>
          <MainTitle>Manage Institution</MainTitle>
        </Col>
        <Col>
          <Row gutter="16" justify="end" align="middle">
            <Col>
              <Button
                type="primary"
                shape="round"
                // size="small"
                onClick={() => handleAddInstitutions(activeTab)}
              >
                + ADD {activeTab}
              </Button>
            </Col>
            <Col>
              <Input.Search
                placeholder='Search'
                onChange={handleSearch}
                style={{ width: 256 }}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <StyledCard>
        <Col span={24}>
          <StyledDiv>
            <CustomTabs
              defaultActiveKey='1'
              size='large'
              activeKey={activeTab}
              onChange={handleChangeTab}
            >
              <TabPane
                tab={<div className='tabs-wrapper'>Hospitals</div>}
                key={institutionTypes.HOSPITALS}
              />

              <TabPane
                tab={<div className='tabs-wrapper'>Laboratory</div>}
                key={institutionTypes.LABS}
              />
              <TabPane
                tab={<div className='tabs-wrapper'>Pharmacy</div>}
                key={institutionTypes.PHARMACIES}
              />
            </CustomTabs>
          </StyledDiv>
        </Col>
      </StyledCard>
      <div style={{ minHeight: '50vh' }}>
        <Spin spinning={loading}>
          {institutionList.length > 0 ?
            institutionList.map(item =>
              <CardItem
                item={item}
                handleRemoveItem={() => { handleRemoveItem(item) }}
                handleEditItem={() => { handleEditItem(item) }}
              />
            )
            :
            <Row justify="center" align="middle" style={{ height: '200px' }}>
              <Col>
                <CustomText color='#A4A4A4' fontWeight="18px" fontWeight='bold'>
                  NO Data Found
                  </CustomText>
              </Col>
            </Row>
          }
        </Spin>
      </div>
      <Row justify="center" style={{ marginTop: '16px' }}>
        <Col>
          <Pagination
            defaultCurrent={1}
            current={pageNumber}
            total={totalItems}
            pageSize={pageLimit}
            hideOnSinglePage={true}
            onChange={handlePageChange}
            style={{ width: '100%' }}
          />
        </Col>
      </Row>

      <Modal
        visible={showAddModal}
        onCancel={handleCancelModal}
        footer={false}
        width={'30%'}
        centered={true}
        title={false}
        destroyOnClose={true}
      >
        <AddInstitution onCancel={handleCancelModal} institutionType={activeTab} handleRefetch={handleRefetch} />
      </Modal>
      <Modal
        visible={showEditModal}
        onCancel={handleCancelModal}
        footer={false}
        width={'70%'}
        centered={true}
        title={false}
        destroyOnClose={true}

      >
        <DetailedView
          selectedInstitution={selectedInstitution}
          handleRefetch={handleRefetch}
          handleCancelModal={handleCancelModal}

        />
      </Modal>
      <Modal
        visible={showDeleteModal}
        onCancel={handleCancelModal}
        footer={false}
        width={'400px'}
        centered={true}
        title={<CustomText size="16" fontWeight="bold">Delete</CustomText>}
      // destroyOnClose={true}
      >
        <DeleteView
          selectedInstitution={selectedInstitution}
          handleRefetch={handleRefetch}
          handleCancelModal={handleCancelModal}
          institutionType={activeTab}
        />
      </Modal>
    </Main >
  )
}
export default ManageInstitutions
