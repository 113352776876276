import React from 'react'
import { Row, Form, Col, Input, Button, message, Divider } from 'antd'
import { useState } from 'react'
import styled from 'styled-components'
import { useEffect } from 'react';
import ApiCalls from '../../apis/ApiCalls';
import CustomText from '../../Components/CustomText';
import { institutionTypes } from './institutionTypes';
import { PlusCircleFilled } from '@ant-design/icons';

const StyledCol = styled(Col)`
  .ant-picker-input > input {
    font-size: 14px;
    color: #5472a3;
  }

  .ant-input:placeholder-shown {
    border-radius: 5px;
  }

  .ant-form-item-label > label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    padding-left: 15px;
  }

  .ant-form-item-has-error .ant-form-item-explain,
  .ant-form-item-has-error .ant-form-item-split {
    font-family: 'Montserrat', sans-serif;
  }
`

const ButtonWrapper = styled.div`
  font-family: Montserrat;
  font-style: normal;
  float: right;
  .ant-btn {
    border: none;
    border-radius: 8px;
    height: 40px;
    background-color: #e79c23;
    border-color: #e79c23;
    width: 150px;
  }
`

const AddInstitution = ({ onCancel, institutionType="", handleRefetch }) => {
    const [form] = Form.useForm()
    let formLayout = 'vertical';
    const [loading, setLoading] = useState(false)
    const [initialValues, setInitialValues] = useState({})

    const onFinish = model => {
        setLoading(true)
        switch (institutionType) {
            case institutionTypes.HOSPITALS:
                console.log(institutionType, institutionTypes)
                    ApiCalls.addHospital({
                        hospitalName: model.name,
                        email: model.email
                    })
                        .then(response => {
                            console.log(response)
                            setLoading(false)
                            message.success(`Added New ${institutionType}`)
                            handleRefetch()
                            onCancel()
                        })
                        .catch(error => {
                            setLoading(false)
                            message.error(
                                error.response
                                    ? error.response.data.data.message
                                    : 'No Response From Server',
                            )
                        });
                break;
            case institutionTypes.LABS:
                ApiCalls.addLaboratory({
                    labName: model.name,
                    email: model.email
                })
                    .then(response => {
                        console.log(response)
                        setLoading(false)
                        message.success(`Added New ${institutionType}`)
                        handleRefetch()
                        onCancel()
                    })
                    .catch(error => {
                        setLoading(false)
                        message.error(
                            error.response
                                ? error.response.data.data.message
                                : 'No Response From Server',
                        )
                    });
                break;
            case institutionTypes.PHARMACIES:
                ApiCalls.addPharmacy( {
                    pharmacyName: model.name,
                    email: model.email
                })
                    .then(response => {
                        console.log(response)
                        setLoading(false)
                        message.success(`Added New ${institutionType}`)
                        handleRefetch()
                        onCancel()
                    })
                    .catch(error => {
                        setLoading(false)
                        message.error(
                            error.response
                                ? error.response.data.data.message
                                : 'No Response From Server',
                        )
                    });
                break;
            default:
              break

        }

    }
    useEffect(()=>{
        setInitialValues({name:'',email:''})
    },[])

    return (
        <>
            <Row justify="start" gutter={8} align="middle">
                <Col>
                    <PlusCircleFilled style={{ fontSize: '30px', fontWeight: 'bold', color: 'lightgrey' }} />
                </Col>
                <Col>
                    <CustomText size="16px" fontWeight="bold">
                        {`ADD ${institutionType}`}
                    </CustomText>
                </Col>
            </Row>
            <Divider />
            <Row justify='center'>
                <Form
                    layout={formLayout}
                    form={form}
                    style={{ width: '90%' }}
                    onFinish={onFinish}
                    initialValues={initialValues}
                >
                    <Row>
                        <StyledCol span={24}>
                            <Form.Item
                                name='name'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Name is required' 
                                    }
                                ]}
                                label='Name'
                            >
                                <Input placeholder='Name' size='large' />
                            </Form.Item>
                        </StyledCol>
                    </Row>
                    <Row>
                        <StyledCol span={24}>
                            <Form.Item
                                name='email'
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'Email is not valid'
                                    },
                                    {
                                        required: true,
                                        message: 'Email is required'
                                    }
                                ]}
                                label='Email'
                            >
                                <Input placeholder='Email' size='large' />
                            </Form.Item>
                        </StyledCol>
                    </Row>
                    <Row justify='end'>
                        <Col span={24}>
                            <Form.Item>
                                <ButtonWrapper>
                                    <Button type='primary' htmlType='submit' loading={loading}>
                                        Submit
                                    </Button>
                                </ButtonWrapper>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Row>
        </>
    )
}

export default AddInstitution
