import React from "react";

function InsuranceIcon({ height = "512", width = "512", active }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 512 512"
        >
            {active ? (
                <>
                    <path  fill="#E79C23" d="M474.858 104.6c-1.616-10.87-8.917-20.23-19.054-24.428L268.016 2.405c-7.749-3.207-16.283-3.207-24.032 0L56.196 80.172C46.059 84.37 38.758 93.73 37.142 104.6c-3.943 26.526-10.553 96.706 13.153 175.28 13.184 43.697 33.843 83.911 61.403 119.524 33.084 42.751 76.498 79.308 129.037 108.653C245.443 510.685 250.721 512 256 512c5.278 0 10.557-1.315 15.265-3.944 52.539-29.345 95.953-65.902 129.037-108.653 27.559-35.613 48.218-75.827 61.402-119.524 23.707-78.573 17.096-148.753 13.154-175.279zm-27.497 170.951c-28.168 93.359-89.872 167.183-183.401 219.424-4.907 2.741-11.007 2.742-15.917 0-93.53-52.24-155.236-126.065-183.403-219.424-22.831-75.674-16.471-143.221-12.677-168.749.846-5.692 4.665-10.592 9.966-12.786l187.788-77.767a16.4 16.4 0 016.284-1.259c2.128 0 4.258.42 6.284 1.259l187.788 77.767c5.301 2.195 9.119 7.094 9.964 12.786 3.795 25.529 10.154 93.076-12.676 168.749z"></path>
                    <path fill="#E79C23" d="M417.006 115.377l-73.397-29.954a7.492 7.492 0 10-5.661 13.873l73.397 29.954c3.768 1.537 6.479 4.951 7.076 8.908 3.031 20.095 8.111 73.266-10.128 132.841-22.499 73.489-71.815 131.618-146.577 172.771-3.524 1.939-7.904 1.939-11.428 0-74.763-41.153-124.079-99.281-146.578-172.771-18.241-59.574-13.161-112.745-10.13-132.841.597-3.958 3.308-7.371 7.077-8.908l150.826-61.553a12.048 12.048 0 019.037 0l45.298 18.487a7.493 7.493 0 005.661-13.873L266.18 53.824a26.8 26.8 0 00-20.359 0L94.995 115.377c-8.631 3.522-14.851 11.396-16.231 20.547-3.183 21.107-8.521 76.949 10.618 139.461 10.647 34.777 27.33 66.781 49.586 95.122 26.699 33.999 61.721 63.064 104.094 86.388 3.991 2.197 8.464 3.295 12.939 3.295 4.474-.001 8.949-1.098 12.939-3.295 42.374-23.323 77.396-52.389 104.094-86.388 22.255-28.341 38.938-60.346 49.585-95.122 19.139-62.513 13.801-118.355 10.617-139.461-1.38-9.152-7.599-17.025-16.23-20.547z"></path>
                    <path fill="#2959a8" d="M241.069 346.855h29.863c10.69 0 19.388-8.656 19.388-19.296v-33.116c0-2.378 1.975-4.313 4.404-4.313H328.1c10.691 0 19.388-8.656 19.388-19.295v-29.63c0-10.64-8.697-19.296-19.388-19.296h-33.376c-2.429 0-4.404-1.934-4.404-4.312V184.48c0-10.639-8.697-19.295-19.388-19.295h-29.863c-10.69 0-19.388 8.655-19.388 19.295v33.117c0 2.377-1.976 4.312-4.404 4.312H183.9c-10.69 0-19.388 8.656-19.388 19.296v29.63c0 9.656 7.248 17.881 16.859 19.132 4.105.54 7.861-2.36 8.396-6.462a7.493 7.493 0 00-6.462-8.396c-2.171-.283-3.809-2.12-3.809-4.273v-29.63c0-2.378 1.976-4.313 4.404-4.313h33.377c10.69 0 19.388-8.656 19.388-19.295v-33.117c0-2.377 1.976-4.312 4.404-4.312h29.863c2.429 0 4.404 1.934 4.404 4.312v33.117c0 10.639 8.697 19.295 19.387 19.295h33.376c2.429 0 4.405 1.934 4.405 4.313v29.63c0 2.377-1.976 4.312-4.405 4.312h-33.376c-10.69 0-19.387 8.656-19.387 19.296v33.116c0 2.378-1.975 4.313-4.404 4.313h-29.863c-2.428 0-4.404-1.934-4.404-4.313v-33.116c0-10.639-8.697-19.296-19.388-19.296-4.138 0-7.492 3.354-7.492 7.492s3.354 7.492 7.492 7.492c2.428 0 4.404 1.934 4.404 4.313v33.116c.001 10.638 8.698 19.294 19.388 19.294z"></path>
                </>
            ) : (
                    <>
                        <path d="M474.858 104.6c-1.616-10.87-8.917-20.23-19.054-24.428L268.016 2.405c-7.749-3.207-16.283-3.207-24.032 0L56.196 80.172C46.059 84.37 38.758 93.73 37.142 104.6c-3.943 26.526-10.553 96.706 13.153 175.28 13.184 43.697 33.843 83.911 61.403 119.524 33.084 42.751 76.498 79.308 129.037 108.653C245.443 510.685 250.721 512 256 512c5.278 0 10.557-1.315 15.265-3.944 52.539-29.345 95.953-65.902 129.037-108.653 27.559-35.613 48.218-75.827 61.402-119.524 23.707-78.573 17.096-148.753 13.154-175.279zm-27.497 170.951c-28.168 93.359-89.872 167.183-183.401 219.424-4.907 2.741-11.007 2.742-15.917 0-93.53-52.24-155.236-126.065-183.403-219.424-22.831-75.674-16.471-143.221-12.677-168.749.846-5.692 4.665-10.592 9.966-12.786l187.788-77.767a16.4 16.4 0 016.284-1.259c2.128 0 4.258.42 6.284 1.259l187.788 77.767c5.301 2.195 9.119 7.094 9.964 12.786 3.795 25.529 10.154 93.076-12.676 168.749z"></path>
                        <path d="M417.006 115.377l-73.397-29.954a7.492 7.492 0 10-5.661 13.873l73.397 29.954c3.768 1.537 6.479 4.951 7.076 8.908 3.031 20.095 8.111 73.266-10.128 132.841-22.499 73.489-71.815 131.618-146.577 172.771-3.524 1.939-7.904 1.939-11.428 0-74.763-41.153-124.079-99.281-146.578-172.771-18.241-59.574-13.161-112.745-10.13-132.841.597-3.958 3.308-7.371 7.077-8.908l150.826-61.553a12.048 12.048 0 019.037 0l45.298 18.487a7.493 7.493 0 005.661-13.873L266.18 53.824a26.8 26.8 0 00-20.359 0L94.995 115.377c-8.631 3.522-14.851 11.396-16.231 20.547-3.183 21.107-8.521 76.949 10.618 139.461 10.647 34.777 27.33 66.781 49.586 95.122 26.699 33.999 61.721 63.064 104.094 86.388 3.991 2.197 8.464 3.295 12.939 3.295 4.474-.001 8.949-1.098 12.939-3.295 42.374-23.323 77.396-52.389 104.094-86.388 22.255-28.341 38.938-60.346 49.585-95.122 19.139-62.513 13.801-118.355 10.617-139.461-1.38-9.152-7.599-17.025-16.23-20.547z"></path>
                        <path d="M241.069 346.855h29.863c10.69 0 19.388-8.656 19.388-19.296v-33.116c0-2.378 1.975-4.313 4.404-4.313H328.1c10.691 0 19.388-8.656 19.388-19.295v-29.63c0-10.64-8.697-19.296-19.388-19.296h-33.376c-2.429 0-4.404-1.934-4.404-4.312V184.48c0-10.639-8.697-19.295-19.388-19.295h-29.863c-10.69 0-19.388 8.655-19.388 19.295v33.117c0 2.377-1.976 4.312-4.404 4.312H183.9c-10.69 0-19.388 8.656-19.388 19.296v29.63c0 9.656 7.248 17.881 16.859 19.132 4.105.54 7.861-2.36 8.396-6.462a7.493 7.493 0 00-6.462-8.396c-2.171-.283-3.809-2.12-3.809-4.273v-29.63c0-2.378 1.976-4.313 4.404-4.313h33.377c10.69 0 19.388-8.656 19.388-19.295v-33.117c0-2.377 1.976-4.312 4.404-4.312h29.863c2.429 0 4.404 1.934 4.404 4.312v33.117c0 10.639 8.697 19.295 19.387 19.295h33.376c2.429 0 4.405 1.934 4.405 4.313v29.63c0 2.377-1.976 4.312-4.405 4.312h-33.376c-10.69 0-19.387 8.656-19.387 19.296v33.116c0 2.378-1.975 4.313-4.404 4.313h-29.863c-2.428 0-4.404-1.934-4.404-4.313v-33.116c0-10.639-8.697-19.296-19.388-19.296-4.138 0-7.492 3.354-7.492 7.492s3.354 7.492 7.492 7.492c2.428 0 4.404 1.934 4.404 4.313v33.116c.001 10.638 8.698 19.294 19.388 19.294z"></path>
                    </>
                )}
        </svg>
    );
}

export default InsuranceIcon;
