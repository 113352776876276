import React from "react";
import styled from "styled-components";
import {
  Card,
  Col,
  Row,
  Button,
  Typography,
  Pagination,
  Spin,
  Modal,
} from "antd";
import ProfileAvatar from "../../Components/ProfileAvatar";
import { UserOutlined, DeleteOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import ApiCalls from "../../apis/ApiCalls";
import { useState } from "react";
import DoctorDetailModal from "./DoctorDetailModal";
import DeleteModal from "./DeleteModal";

const Main = styled.div`
  font-family: "Montserrat", sans-serif;
  .ant-pagination {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }
`;
const StyledCard = styled(Card)`
  width: 100%;
  margin: 1% 0;
`;
const CardTitle = styled(Typography)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 29px;
  color: #2959a8;
  text-transform: capitalize;
`;
const CardSubTitle = styled(Typography)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #aeaeae;
`;

const PrimaryButton = styled.div`
  .ant-btn-primary {
    border-radius: 25px;
    height: 40px;
    font-size: 12px;
    width: 150px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* svg{
      margin-right:10px;
      align-self: center;
  } */
  }
`;

const DetailButton = styled(Button)`
  /* background-color: #e79c23; */
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;

  border: 0;
`;
const RemoveButton = styled(Button)`
  background-color: #dcdcdc;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  color: #a9a9a9;
  border: 0;
`;

const ProviderList = (props) => {
  const [showDoctorDetail, setShowDoctorDetail] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState([]);
  const [selectDeleteDoctor, setSelectDeleteDoctor] = useState([]);
  const [deleteDoctorModalOpen, setDeleteDoctorModalOpen] = useState(false);
  const [providers, setProviders] = useState([]);
  const [add, setAdd] = useState(true);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(6);
  const pageLimit = 8;

  const handleDoctorDetailModalOpen = (e, value) => {
    setSelectedDoctor(value);
    setShowDoctorDetail(true);
  };

  const handleDeleteModalOpen = (value) => {
    setSelectedDoctor(value);
    setDeleteDoctorModalOpen(true);
  };

  const handleCancelModal = () => {
    setShowDoctorDetail(false);
  };

  const handleCancelDeleteModal = () => {
    setDeleteDoctorModalOpen(false);
  };

  const handlePageChange = (pageNumber, size) => {
    setPageNumber(pageNumber - 1);
  };

  useEffect(() => {
    setLoading(true);
    if (!props.searchKey) {
      ApiCalls.getProvider(pageNumber, pageLimit)
        .then((res) => {
          if (res.data.status === 1) {
            setTotalPages(res.data.data.count);
            setLoading(false);
            setProviders(res.data.data.data);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      ApiCalls.getUserList("provider", props.searchKey, pageNumber, pageLimit)
        .then((response) => {
          if (response.data.status === 1) {
            setTotalPages(response.data.data[0].count);
            setProviders(response.data.data[0].data);
            setLoading(false);
            setPageNumber(0);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [pageNumber, add, props.searchKey]);

  return (
    <>
      <Main>
        <Spin spinning={loading} size="large">
          {providers
            ? providers.map((providerList) => {
                return (
                  <StyledCard>
                    <Row
                      gutter={16}
                      align="middle"
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <Col>
                          <ProfileAvatar
                            icon={<UserOutlined />}
                            size={60}
                            url={providerList.profilePic}
                          />
                        </Col>
                        <Col>
                          <Row>
                            <CardTitle>{`${providerList.firstName} ${providerList.lastName}`}</CardTitle>
                          </Row>
                          <Row>
                            <CardSubTitle>{providerList.mobile}</CardSubTitle>
                          </Row>
                        </Col>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <Col>
                            <PrimaryButton>
                              <RemoveButton
                                type="primary"
                                style={{ fontSize: "16px" }}
                                icon={<DeleteOutlined />}
                                onClick={() =>
                                  handleDeleteModalOpen(providerList)
                                }
                              >
                                Remove
                              </RemoveButton>
                            </PrimaryButton>
                          </Col>
                        </div>
                        <div>
                          <Col>
                            <PrimaryButton>
                              <DetailButton
                                type="primary"
                                style={{ fontSize: "16px" }}
                                onClick={(event) =>
                                  handleDoctorDetailModalOpen(
                                    event,
                                    providerList
                                  )
                                }
                              >
                                View Details
                              </DetailButton>
                            </PrimaryButton>
                          </Col>
                        </div>
                      </div>
                    </Row>
                  </StyledCard>
                );
              })
            : ""}
          <Modal
            visible={showDoctorDetail}
            onCancel={handleCancelModal}
            footer={false}
            width={"72%"}
            centered={true}
          >
            <DoctorDetailModal
              selectedDoctor={selectedDoctor}
              handleCancelModal={handleCancelModal}
              handleDeleteModalOpen={handleDeleteModalOpen}
            />
          </Modal>
          <Modal
            title="Delete Provider"
            visible={deleteDoctorModalOpen}
            onCancel={handleCancelDeleteModal}
            footer={false}
            width={"50%"}
            centered={true}
          >
            <DeleteModal
              selectedUser={selectedDoctor}
              handleCancelDeleteModal={(event) => {
                handleCancelDeleteModal(false);
              }}
              setAdd={setAdd}
              add={add}
            />
          </Modal>
          <Pagination
            defaultCurrent={1}
            total={totalPages}
            pageSize={10}
            hideOnSinglePage={true}
            onChange={handlePageChange}
          />
        </Spin>
      </Main>
    </>
  );
};

export default ProviderList;
