import React from "react";
import "./App.less";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import Login from "./login";
import { Provider } from "react-redux";
import configureStore from "./redux-store/store/configureStore";
import Base from "./modules/base";
const store = configureStore();
const state = store.getState();

function App() {
  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <Switch>
            <Route path="/login" render={(props) => <Login />} />
            <Route path="/base" component={Base} />
            <Redirect to="/login" />
          </Switch>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
