import React, { useState, useEffect } from "react";
import {
    FormListContainer,
    FormListBox,
    HeadingText,
    FormListWrapper,
    Wrapper,
    SubHeadingText,
    SubmitButton,
    ButtonContainer,
    Label,
    AlignedRow,
} from "./styledComponent";
import { LoadingOutlined, PlusOutlined, PlusSquareFilled } from "@ant-design/icons";
import { Divider, Button, message, Modal, Spin } from "antd";
import { useHistory, useParams } from "react-router-dom";
import ApiCalls from "../../apis/ApiCalls";

const FormList = () => {
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([0]);
    const [CategoryList, setCategoryList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const history = useHistory();
    const [detailsModal, setDetailsModal] = useState({ show: false, id: null });
    const [deleteLoading, setDeleteLoading] = useState(false);
    
    //Get All form api
  const allFormList = async() => {
    setLoading(true);
     await ApiCalls.getAllForm()
      .then((res) => {
        setLoading(false);
        if (res?.data?.data?.data) {
            setTotalCount(res.data.count)
            setList(res.data.data.data)
          message.success("All the form list are here");
        }
      }) 
      .catch((error) => {
        setList([]);
        setLoading(false);
        if (error.response) {
          message.info(error.response.data.data.message);
        } else if (error.request) {
          message.error("Server not responding");
        } else {
          message.error("Something went wrong");
        }
      });
}

  //get form by category
  const formByCategory = async(categoryNumber) => {
    setLoading(true);
    try {
        let { data } = await ApiCalls.getFormByCategory(categoryNumber);
        if(data?.data?.length > 0){
            setCategoryList(data?.data)
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

  useEffect(()=>{
    allFormList()
    formByCategory(0)
  },[])

  //get single form
  const ViewForm = async(formId) => {
    history.push(`/base/home/survay-form/form-details/${formId._id}`)
    }

    // close modal
    const closeModal = (refresh) => {
        setDetailsModal({ show: false, id: null });
        if(refresh){
            allFormList()
        }
    }

    // delete single form
    const confirmDelete =()=>{
        setDeleteLoading(true);
        ApiCalls.deleteSingleForm(detailsModal.id)
          .then(res => {
            console.log(res)
            if(res.data.status){
                closeModal(true);
                message.success("Deleted successfully")
            }
            setDeleteLoading(false);
          })
          .catch(error => {
            closeModal(true);
            setDeleteLoading(false);
          });
    }

    // Delete plan
    const deleteForm = (item) => {
        setDetailsModal({ show: true, id: item._id });
    }

    return loading ? (
        <LoadingOutlined />
    ) : (
        <FormListContainer>
            <FormListBox>
                <HeadingText>Form List</HeadingText>
                <SubmitButton>
                    <Button type="primary" htmlType="submit" onClick={() => history.push("/base/home/survay-form/create-form")}>
                        <PlusOutlined />
                        Create Form
                    </Button>
                </SubmitButton>
            </FormListBox>
        {list.map((item, index)=>(
            <FormListWrapper key={index}>
            <Wrapper style={{marginBottom:"20px"}}>
                <SubHeadingText>{item.formDescription}</SubHeadingText>
                <ButtonContainer>
                <SubmitButton>
                    <Button type="primary" onClick={()=>ViewForm(item)}>
                        View Form
                    </Button>
                </SubmitButton>
                <SubmitButton delete={true}>
                    <Button type="primary" onClick={()=>deleteForm(item)}>
                        Delete Form
                    </Button>
                </SubmitButton>
                </ButtonContainer>
            </Wrapper>
            <Divider />
        </FormListWrapper>
        ))}     
        <Modal
        visible={detailsModal.show}
        onCancel={() => closeModal(false)}
        footer={false}
        width="455px"
        // style={{ maxWidth: '45rem' }}
        centered={true}
        destroyOnClose={true}
      >
         <div>
            <Label fontWeight={600} marginTop="20px">You're about to delete the form details, Are you sure?</Label>
            <AlignedRow>
              <Label marginRight="50px" color="#e60000" hover onClick={confirmDelete}>
                Yes {deleteLoading ? <Spin /> : null}
              </Label>
              <Label color="#0000cc" hover onClick={() => closeModal(false)}>No</Label>
            </AlignedRow>
          </div>
        </Modal>       
        </FormListContainer>
    )
}
export default FormList;