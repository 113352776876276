import React from "react";
import CustomText from "../../Components/CustomText";
import moment from "moment";
import { Row, Col, Divider } from "antd";
import styled from "styled-components";

const InsuranceInfoCard = ({ insuranceInfo = {}, transactionInfo = {} }) => {
  const CardWrapper = styled.div`
    min-height: 250px;
    border: 1px solid #d9d9d9;
    padding: 16px;
    margin: 8px auto;
    .header-wrapper {
      border-bottom: 1px solid #d9d9d9;
      padding-bottom: 16px;
    }
    .field {
      margin: 8px;
    }
  `;

  const fields = [
    {
      label: "Card Number:",
      value: insuranceInfo.policyNumber,
    },
    {
      label: "Provider Name:",
      value: insuranceInfo.insuranceCompany,
    },
    {
      label: "Validity:",
      value: moment(insuranceInfo.expiryDate).format("DD/MM/YYYY"),
    },
    {
      label: "Amount Claimed:",
      value: transactionInfo.insuranceClaimAmount,
    },
    {
      label: "Status:",
      value: `${transactionInfo.insuranceClaimStatus}`.toUpperCase(),
    },
  ];
  return (
    <CardWrapper>
      <div className="content-wrapper">
        {fields.map((item) => (
          <Row className="field">
            <Col span={12}>
              <CustomText fontWeight="bold">{item.label}</CustomText>
            </Col>
            <Col span={12}>
              <CustomText fontWeight="bold" color="grey">
                {item.value}
              </CustomText>
            </Col>
          </Row>
        ))}
      </div>
    </CardWrapper>
  );
};

export default InsuranceInfoCard;
