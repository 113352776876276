import React, { useState, useEffect } from "react";
import { Tabs, Col } from "antd";
import CustomLayout from "../../Components/CustomLayout";
import EmptyComponent from "../../Components/EmptyComponent";
import AppointmentCard from "./AppointmentCard";
import ApiCalls from "../../apis/ApiCalls";
import { 
  Main, 
  CustomTabs, 
  StyledCard, 
  StyledDiv, 
  CardsWrapper, 
  StyledSpin,
  StyledPagination
} from "./styledComponents";

const { TabPane } = Tabs;

const ListAppointments = () => {
  const [activeTab, setActiveTab] = useState("upcoming");
  const [loading, setLoading] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemCount, setItemCount] = useState(0);

  const getAppointmentStatus = () => {
    switch(activeTab) {
      case "awaiting_payment":
        return 0;
      case "completed":
        return 1;
      case "cancelled":
        return 2;
      case "missed":
        return 3;
      case "upcoming":
        return 4;
      default:
        return 5;
    }
  }

  // Get appointments
  const getAppintmentsList = async(currentPage) => {
    try {
      setLoading(true);
      let { data } = await ApiCalls.listAppointments(getAppointmentStatus(), currentPage);
      if(data?.data?.apponimentDataList?.length > 0) {
        setAppointments([...data?.data?.apponimentDataList]);
        if(data?.data?.responseData?.count) {
          setItemCount(data?.data?.responseData?.count);
        }
      } else {
        setAppointments([]);
      }
      setLoading(false);
    } catch (error) {
      setAppointments([]);
      setLoading(false);
    }
  }

  useEffect(() => {
    setCurrentPage(1);
    getAppintmentsList(0);
  }, [activeTab]);

  useEffect(() => {
    getAppintmentsList(currentPage - 1);
  }, [currentPage]);

  return (
    <Main>

      {/* Header */}
      <CustomLayout
        mainTitle="Appointments"
        makePaddingZero={true}
      />

      {/* Tabs */}
      <StyledCard>
        <Col span={24}>
          <StyledDiv>
            <CustomTabs
              defaultActiveKey="1"
              size="large"
              onChange={setActiveTab}
            >
              <TabPane
                tab={<div className="tabs-wrapper">Upcoming</div>}
                key="upcoming"
              />
              <TabPane
                tab={<div className="tabs-wrapper">Cancelled</div>}
                key="cancelled"
              />
              <TabPane
                tab={<div className="tabs-wrapper">Completed</div>}
                key="completed"
              />
              <TabPane
                tab={<div className="tabs-wrapper">Missed</div>}
                key="missed"
              />
              <TabPane
                tab={<div className="tabs-wrapper">Awaiting Payment</div>}
                key="awaiting_payment"
              />
            </CustomTabs>
          </StyledDiv>
        </Col>
      </StyledCard>

      {/* Data */}
      {!loading && (
        <CardsWrapper>
          {appointments.length === 0 ? (
            <EmptyComponent message="No data found" />
          ) : (
            <>

              {/* Appointments */}
              <AppointmentCard data={appointments} />

              {/* Pagination */}
              <StyledPagination 
                current={currentPage} 
                pageSize={10} 
                total={itemCount} 
                onChange={page => setCurrentPage(page)} 
              />

            </>
          )}
        </CardsWrapper>
      )}

      {loading && (
        <StyledSpin spinning={true} size="large" />
      )}

    </Main>
  );
}

export default ListAppointments;