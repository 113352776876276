import React, { useState } from "react";
import moment from "moment";
import { Modal } from "antd";
import DetailsModal from "../../ManageDoctors/DetailsModal";
import PatientDetailsModal from "../../list-patients/DetailsModal";
import { 
  StyledColumn, 
  StyledRow, 
  StyledTable, 
  StyledText, 
  StyledButton 
} from "./styledComponents";

const AppointmentCard = ({ data }) => {
  const [detailsModal, setDetailsModal] = useState({ show: false, modalType: null, id: null });

  return (
    <>
      <StyledTable>

        {/* Header */}
        <StyledRow>
          <StyledColumn header={true}>Patient Name</StyledColumn>
          <StyledColumn header={true}>Provider Name</StyledColumn>
          <StyledColumn header={true}>Start time</StyledColumn>
          <StyledColumn header={true}>End time</StyledColumn>
        </StyledRow>

        {data?.map(item => (
          <StyledRow key={`${item?.patientId}${item?.providerId}`}>
            <StyledColumn>
              <StyledText>{item?.patientFullName}</StyledText>
            </StyledColumn>
            <StyledColumn>
              <StyledText>{item?.providerName}</StyledText>
            </StyledColumn>
            <StyledColumn>
              <StyledText>{moment(item?.timeOfAppointmentStart).format("HH:MM a, DD-MM-YYYY")}</StyledText>
            </StyledColumn>
            <StyledColumn>
              <StyledText>{moment(item?.timeOfAppointmentEnd).format("HH:MM a, DD-MM-YYYY")}</StyledText>
            </StyledColumn>
            <StyledColumn>
              <StyledButton onClick={() => setDetailsModal({ show: true, modalType: "Patient", id: item?.patientId })}>
                <p>View Patient Details</p>
              </StyledButton>
            </StyledColumn>
            <StyledColumn>
              <StyledButton onClick={() => setDetailsModal({ show: true, modalType: "Doctor", id: item?.providerId })}>
                <p>View Therapist Details</p>
              </StyledButton>
            </StyledColumn>
          </StyledRow>
        ))}
      </StyledTable>

      <Modal
        visible={detailsModal.show}
        onCancel={() => setDetailsModal({ show: false, modalType: null, id: null })}
        footer={false}
        width={"72%"}
        centered={true}
        destroyOnClose={true}
      >
        {detailsModal.modalType === "Doctor" ? (
          <DetailsModal id={detailsModal.id} fromAppointments />
        ) : (
          <PatientDetailsModal id={detailsModal.id} fromAppointments />
        )}
      </Modal>
    </>
  );
}

export default AppointmentCard;