import React, { useEffect, useState } from "react";
import { Modal, Spin, AutoComplete } from "antd";
import CustomLayout from "../../Components/CustomLayout";
import EmptyComponent from "../../Components/EmptyComponent";
import PlanCard from "./PlanCard";
import CreatePlan from "./CreatePlan";
import PlanDetails from "./PlanDetails";
import ApiCalls from "../../apis/ApiCalls";
import { Main, CardsWrapper, StyledSpin, CreateButton, ButtonTitle, Label, AlignedRow } from "./styledComponents";

const ManageCorporates = () => {
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [detailsModal, setDetailsModal] = useState({ show: false, modalType: null, id: null });
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [currencySearchText, setCurrencySearchText] = useState('');

  // Get subscription plans
  const getAllSubscriptionPlans = async(currency) => {
    try {
      setLoading(true);
      let subscriptionApi = currency && currency?.trim('')?.length > 0 ? ApiCalls.getAllSubscriptionPlansBasedOnCurrency : ApiCalls.getAllSubscriptionPlans;
      let { data } = await subscriptionApi(currency);
      setPlans([...data.data]);
      setLoading(false);
    } catch (error) {
      setPlans([]);
      setLoading(false);
    }
  }

  // Fetch currencies
  const fetchCurrencies = async() => {
    try {
      let { data } = await ApiCalls.fetchCurrencies(currencySearchText);
      if(data?.data?.currencies?.length > 0) {
        setCurrencies(data?.data?.currencies);
      } else {
        setCurrencies([]);
      }
    } catch (error) {
      setCurrencies([]);
    }
  }

  useEffect(() => {
    fetchCurrencies();
    if(currencySearchText?.trim('')?.length === 0) {
      getAllSubscriptionPlans();
    }
  }, [currencySearchText]);

  const closeModal = (refresh = false) => {
    if(refresh) {
      getAllSubscriptionPlans();
    }
    setDetailsModal({ show: false, modalType: null, id: null });
  }

  // Confirm delete subscription
  const confirmDelete = async() => {
    setDeleteLoading(true);
    ApiCalls.deleteSubscription(detailsModal.id)
      .then(res => {
        closeModal(true);
        setDeleteLoading(false);
      })
      .catch(error => {
        closeModal(true);
        setDeleteLoading(false);
      });
  }

  // Delete plan
  const deletePlan = (subscriptionId) => {
    setDetailsModal({ show: true, modalType: 'delete', id: subscriptionId });
  }

  // Edit plan
  const editPlan = (subscriptionId) => {
    setDetailsModal({ show: true, modalType: 'editPlan', id: subscriptionId });
  }

  // View plan details
  const viewPlanDetails = (subscriptionId) => {
    setDetailsModal({ show: true, modalType: 'details', id: subscriptionId });
  }

  return (
    <Main>
      <CustomLayout
        mainTitle="Corporate Subscriptions"
        makePaddingZero={true}
        headerComponent={
          <CreateButton 
            onClick={() => setDetailsModal(detailsModal => ({ ...detailsModal, show: true, modalType: 'createPlan' }))}
          >
            <ButtonTitle>
              Create Plan
            </ButtonTitle>
          </CreateButton>
        }
      />

      {/* Search with currency */}
      <AutoComplete
        style={{ width: '300px', marginBottom: '20px' }}
        value={currencySearchText}
        options={currencies?.map(item => ({ value: item.code, label: item.code }))}
        onSelect={value => getAllSubscriptionPlans(value)}  
        onChange={value => setCurrencySearchText(value)}
        placeholder='Currency'
      />

      {loading ? (
        <StyledSpin spinning={true} size="large" /> 
      ) : (
        <CardsWrapper>
          {plans.length === 0 ? (
            <EmptyComponent message="No data found" />
          ) : (
            <PlanCard data={plans} deletePlan={deletePlan} editPlan={editPlan} viewPlanDetails={viewPlanDetails} />
          )}
        </CardsWrapper>
      )}

      {/* Modal */}
      <Modal
        visible={detailsModal.show}
        onCancel={() => closeModal(false)}
        footer={false}
        width={detailsModal.modalType === "delete" ? "25rem" : "60vw"}
        style={{ maxWidth: '45rem' }}
        centered={true}
        destroyOnClose={true}
      >
        {detailsModal.modalType === 'details' ? (
          <PlanDetails subscriptionId={detailsModal.id} />
        ) : detailsModal.modalType === "delete" ? (
          <div>
            <Label fontWeight={600} marginTop="20px">You're about to delete the plan, Are you sure?</Label>
            <AlignedRow>
              <Label marginRight="50px" color="#e60000" hover onClick={confirmDelete}>
                Yes {deleteLoading ? <Spin /> : null}
              </Label>
              <Label color="#0000cc" hover onClick={() => closeModal(false)}>No</Label>
            </AlignedRow>
          </div>
        ) : (
          <CreatePlan 
            closeModal={closeModal} 
            type={detailsModal.modalType} 
            subscriptionId={detailsModal.id} 
          />
        )}
      </Modal>

    </Main>
  );
}

export default ManageCorporates;