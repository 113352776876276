import React from "react";
import styled from "styled-components";
import {
  Card,
  Col,
  Row,
  Button,
  Typography,
  Pagination,
  Spin,
  message,
} from "antd";
import ProfileAvatar from "../../../Components/ProfileAvatar";
import { UserOutlined, DeleteOutlined } from "@ant-design/icons";
import ActionModal from "../ActionModal";

import ApiCalls from "../../../apis/ApiCalls";
import Modal from "antd/lib/modal/Modal";
import { useState, useEffect } from "react";
import RefundCard from "../RefundCard";
import CustomText from "../../../Components/CustomText";
import EmptyComponent from "../../../Components/EmptyComponent";

const CardsWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  min-height: 500px;
  /* height: 500px; */
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
`;

let PendingRefundsList = () => {
  let [refunds, setRefunds] = useState([]);
  let [loading, setLoading] = useState(true);
  let [currentPage, setCurrentPage] = useState(0);
  let [totalPages, setTotalPages] = useState(0);
  const [isModalVisible, setModalVisibility] = useState(false);
  const [currentSelectedRefund, setCurrentSelectedRefund] = useState({});
  console.log("pending");

  useEffect(() => {
    getPendingRefundsList();
  }, [currentPage]);

  const getPendingRefundsList = async () => {
    try {
      setLoading(true);
      let { data } = await ApiCalls.getRefunds("pending", currentPage);
      console.log(data);
      setRefunds(data.data.data);
      setTotalPages(data.data.count);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handlePageChange = (pageNumber, pageLimit) => {
    setCurrentPage(pageNumber - 1);
  };

  const acceptHandler = (id, action, refund) => {
    console.log("pressed");
    setCurrentSelectedRefund({ ...refund, action: "accepted" });
    setModalVisibility(true);
  };

  const rejectHandler = (id, action, refund) => {
    setCurrentSelectedRefund({ ...refund, action: "rejected" });
    setModalVisibility(true);
  };

  const handleModelConfirm = async (confirmationType) => {
    try {
      let response;
      if (confirmationType === "accepted") {
        response = await ApiCalls.acceptOrRejectRefund(
          currentSelectedRefund.scheduleData._id,
          "accepted"
        );
      }
      if (confirmationType === "rejected") {
        console.log(currentSelectedRefund);
        response = await ApiCalls.acceptOrRejectRefund(
          currentSelectedRefund.scheduleData._id,
          "rejected"
        );
      }
      if (response.data.status) {
        message.success(`Successfully ${confirmationType} the refund`);
      }
      setLoading(true);
      setModalVisibility(false);
      let { data } = await ApiCalls.getRefunds("pending", currentPage, 5);
      setRefunds(data.data.data);
      setTotalPages(data.data.count);
      setLoading(false);
    } catch (error) {
      message.error(
        error.response
          ? error?.response?.data?.data?.message
          : "No Response From Server",
        5
      );
    }
  };

  return (
    <>
      <CardsWrapper>
        {!loading && (
          <>
            {refunds.length === 0 ? (
              <EmptyComponent message="No data to show" />
            ) : null}
            {refunds.map((item) => {
              return (
                <RefundCard
                  accept
                  reject
                  id={item._id}
                  name={item.name}
                  key={item._id}
                  email={item.phoneNumber}
                  transactionId={item.transactionData?.transactionId}
                  acceptHandler={acceptHandler}
                  rejectHandler={rejectHandler}
                  details={item}
                  profilePic={item.profilePic}
                />
              );
            })}
          </>
        )}
      </CardsWrapper>
      {loading && <Spin spinning={true} size="large" />}
      <Modal
        visible={isModalVisible}
        onCancel={() => {
          setModalVisibility(false);
        }}
        footer={false}
        width={700}
        centered={true}
        destroyOnClose={true}
        title={
          <CustomText size="16" fontWeight="bold">
            Confirmation
          </CustomText>
        }
      >
        <ActionModal
          selectedRefund={currentSelectedRefund}
          handleModalConfirm={handleModelConfirm}
          action={currentSelectedRefund.action}
          handleModalCancel={setModalVisibility}
        />
      </Modal>

      {!loading && (
        <PaginationWrapper>
          <Pagination
            defaultCurrent={1}
            total={totalPages}
            pageSize={5}
            hideOnSinglePage={true}
            onChange={handlePageChange}
            current={currentPage + 1}
          />
        </PaginationWrapper>
      )}
    </>
  );
};

export default PendingRefundsList;
