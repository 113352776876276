import React from "react";
import PendingRefundsList from "./PendingRefundsList";
import AcceptedRefundsList from "./AcceptedRefundsList";
import RejectedRefundsList from "./RejectedRefundsList";

let ListController = ({ selectedTab }) => {
  return (
    <>
      {(() => {
        switch (selectedTab) {
          case "pending":
            console.log("list controller");
            return <PendingRefundsList />;
          case "accepted":
            return <AcceptedRefundsList />;
          case "rejected":
            return <RejectedRefundsList />;
        }
      })()}
    </>
  );
};

export default ListController;
