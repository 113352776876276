import React from 'react'
import styled from 'styled-components'
import { Card, Row, Col } from 'antd'
import ConsultationReport from './ConsultationReport'
import PaymentReport from './PaymentReport'
import DoctorFeedBackReport from './FeedBackReport'
import { useState } from 'react'
import useSelection from 'antd/lib/table/hooks/useSelection'
import CustomLayout from '../../Components/CustomLayout'
import CalendarFilterForm from './CalendarFilterForm';

const StyledCard = styled(Card)`
  // height: 385px;
  /* width: 98%; */

  .ant-card-head {
    border-bottom: 0;
  }

  /* .ant-card-body {
    padding: 24px 24px 0 24px;
  } */

  .ant-card-head-title {
    font-family: Montserrat;
    color: #a4a4a4;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }

  & .ant-select-selection-item {
    font-size: 14px;
  }

  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    font-family: Montserrat;

    padding: 1px 11px;
    width: 11vw;
    font-size: 0.75vw;
    font-weight: 800;
  }

  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 40px;
    border-radius: 5px;
  }

  .ant-divider-horizontal {
    margin: 18px 0;
  }
`

const ManageReports = () => {
  return (
    <>
      <CustomLayout mainTitle='Reports'>
        <Row gutter={16}>
          <Col
            span={12}
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
          >
            <StyledCard>
              <CalendarFilterForm type='consultation' />
            </StyledCard>
          </Col>
          <Col
            span={12}
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
          >
            <StyledCard>
              <CalendarFilterForm type='feedback' />
            </StyledCard>
          </Col>
        </Row>
      </CustomLayout>
    </>
  )
}

export default ManageReports
