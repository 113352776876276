import React from "react";
import { Input, message } from "antd";
import { StyledForm, StyledModal } from "./styledComponents";
import ApiCalls from "../../apis/ApiCalls";
import { useState, useEffect } from "react";

const EditMedicine = ({
  showEditMedicine,
  setShowEditMedicine,
  fetchMedicineList,
  currentMedicineDetails,
}) => {
  const [form] = StyledForm.useForm();

  useEffect(() => form.resetFields(), [currentMedicineDetails]);
  const onFinish = (val) => {
    let editedMedicineDetails = {
      ...val,
      medicineId: currentMedicineDetails._id,
    };
    ApiCalls.editMedicine(editedMedicineDetails)
      .then((response) => {
        if (response.data.status) {
          message.success("Successfully edited medicine details");
          fetchMedicineList();
        }
        setShowEditMedicine(false);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  return (
    <>
      <StyledModal
        visible={showEditMedicine}
        closable={false}
        title="Edit Medicine"
        centered={true}
        onCancel={() => setShowEditMedicine(false)}
        onOk={() => form.submit()}
        key={showEditMedicine}
        destroyOnClose={true}
      >
        <StyledForm form={form} onFinish={onFinish}>
          <StyledForm.Item
            name="medicineName"
            label="Medicine Name"
            initialValue={currentMedicineDetails.medicineName}
            rules={[
              {
                required: true,
                message: "Medicine name is required",
              },
            ]}
          >
            <Input />
          </StyledForm.Item>

          <StyledForm.Item
            name="drugType"
            label="Drug Type"
            initialValue={currentMedicineDetails.drugType}
            rules={[
              {
                required: true,
                message: "Drug Type is required",
              },
            ]}
          >
            <Input />
          </StyledForm.Item>

          <StyledForm.Item
            name="dosageForm"
            label="Dosage Form"
            initialValue={currentMedicineDetails.dosageForm}
            rules={[
              {
                required: true,
                message: "Dosage Form is required",
              },
            ]}
          >
            <Input />
          </StyledForm.Item>

          <StyledForm.Item
            name="description"
            label="Description"
            initialValue={currentMedicineDetails.description}
            rules={[
              {
                required: true,
                message: "Description is required",
              },
            ]}
          >
            <Input />
          </StyledForm.Item>

          <StyledForm.Item
            name="strength"
            label="Strength"
            initialValue={currentMedicineDetails.strength}
            rules={[
              {
                required: true,
                message: "Strength is required",
              },
            ]}
          >
            <Input />
          </StyledForm.Item>

          <StyledForm.Item
            name="volume"
            label="Volume"
            initialValue={currentMedicineDetails.volume}
            rules={[
              {
                required: true,
                message: "Volume is required",
              },
            ]}
          >
            <Input />
          </StyledForm.Item>
        </StyledForm>
      </StyledModal>
    </>
  );
};

export default EditMedicine;
