import React, { useState, Fragment, useEffect } from "react";
import { Form, Input, Row, Col, message, Select, Divider, Button, DatePicker } from "antd";
import { StyledForm, StyledFormItem, FormListContainer, FormWrapper, SubText, SubmitButton , ButtonContainer} from "./styledComponent";
import styled from "styled-components";
import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons";
import ApiCalls from "../../apis/ApiCalls"; 
import { useHistory } from "react-router-dom";

const CreateForm = () => {
  const [loading, setLoading] = useState(false);
  const [question, setQuestion] = useState([
    0
  ]);
 
  const [form] = Form.useForm();
  const history = useHistory();
  const CustomCol = styled(Col)`
& > div {
  margin-bottom: 0px;
}
.success:hover {
  border: 2px solid red;
}
`;

   //create form api
   const createFormApi = async(values) => {
    let temp=[]
    values.questionsList.map(item=>
    {
      if(item!== undefined){
        temp.push(item)
      }
    }

    )
    let request= {
      ...values,
      questionsList:temp,
    }
    setLoading(true);
    try {
      let { data } = await ApiCalls.createForm(request);
       if(data){
        history.goBack();
       }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

 //Remove
  const remove=(item)=>{
  let temp=[]
  question.map((data)=>{
    if(item!== data){
      temp.push(data)
    }
  })
  setQuestion(temp);
  }

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  return (
    <FormListContainer>
      <StyledForm form={form} onFinish={createFormApi}>
        <FormWrapper>
          <Row
            style={{
              justifyContent: "space-between",
              width: "50%",
              marginBottom: "23px",
            }}
          >
            <CustomCol span={24}>
              <StyledFormItem
                name="formName"
                // label="Name of the person"
                rules={[
                  {
                    message: "Please enter alphabets only",
                    pattern: new RegExp("^[a-zA-Z][a-zA-Z\\s]+$"),
                  },
                  {
                    required: true,
                    message: "Name is required",
                  },
                ]}
              >
                <Input placeholder="Form name"
                // value={formName} 
                // onChange={e=>setFormName(e.target.value)}
                />
              </StyledFormItem>
            </CustomCol>
          </Row>

          <Row
            style={{
              justifyContent: "space-between",
              width: "50%",
              marginBottom: "23px",
            }}
          >
            <CustomCol span={24}>
              <StyledFormItem
                name="formForCategory"
                rules={[
                  {
                    required: true,
                    message: "Form For",
                  },
                ]}
              //   initialValue={companyProfile?.profileCreatorRole}
              >
                <Select
                  style={{ cursor: "pointer" }}
                  showSearch
                  placeholder="select an option"
                  options={[
                    {
                      value: 'patient',
                      label: 'patient',
                    },
                    {
                      value: 'provider',
                      label: 'provider',
                    },
                    {
                      value: 'corporate',
                      label: 'corporate',
                    },
                    {
                      value: 'corporate-employees',
                      label: 'corporate-employees',
                    },
                    {
                      value: 'public',
                      label: 'public',
                    },
                  ]}
                  // value={formForCategory}
                  // onChange={e=>setFormForCategory(e.target.value)}
                  />
                </StyledFormItem>
            </CustomCol>
          </Row>
        </FormWrapper>
        
          <Row style={{ marginBottom: "23px" }}>
            <CustomCol span={24}>
              <StyledFormItem
                name="formDescription"
                rules={[
                  {
                    required: true,
                    message: "Form Description is required",
                  },
                ]}
              
              // initialValue={companyProfile.companyEmail}
              >
                <Input placeholder="Form Description" 
                autoFocus={true} 
                // value={formDescription} 
                // onChange={e=>setFormDescription(e.target.value)}
                />
              </StyledFormItem>
            </CustomCol>
          </Row>

        <FormWrapper>
          <Row
            style={{
              justifyContent: "space-between",
              width: "50%",
              marginBottom: "23px",
            }}
          >
            <SubText style={{ paddingLeft: 30 }}>Is Form Active?</SubText>
            <CustomCol span={24}>
              <StyledFormItem
                name="isActive"
                rules={[
                  {
                    required: true,
                    message: "true",
                  },
                ]}
              //   initialValue={companyProfile?.profileCreatorRole}
              >
                <Select
                  style={{ cursor: "pointer" }}
                  showSearch
                  placeholder="select an option"
                  optionFilterProp="children"
                  options={[
                    {
                      value: 'true',
                      label: 'true',
                    },
                    {
                      value: 'false',
                      label: 'false',
                    },
                  ]}
                  // value={isActive}
                  // onChange={e=>setIsActive(e.target.value)}
                />
              </StyledFormItem>
            </CustomCol>
          </Row>

          <Row
            style={{
              justifyContent: "space-between",
              width: "50%",
              marginBottom: "23px",
            }}
          >
            <SubText style={{ marginLeft: 30 }}>Access Type</SubText>
            <CustomCol span={24}>
              <StyledFormItem
                name="accessType"
                rules={[
                  {
                    required: true,
                    message: "access type",
                  },
                ]}
              //   initialValue={companyProfile?.profileCreatorRole}
              >
                <Select
                  style={{ cursor: "pointer" }}
                  showSearch
                  placeholder="select an option"
                  options={[
                    {
                      value: 'public',
                      label: 'public',
                    },
                    {
                      value: 'private',
                      label: 'private',
                    },
                  ]}
                  // value={accessType}
                  // onChange={e=>setAccessType(e.target.value)}
                />
              </StyledFormItem>
            </CustomCol>
          </Row>

          <Row
            style={{
              justifyContent: "space-between",
              width: "50%",
              marginBottom: "23px",
            }}
          >
            <SubText style={{ marginLeft: 30 }}>End date</SubText>
            <CustomCol span={24}>
              <StyledFormItem
                name="endDate"
                rules={[
                  {
                    required: true,
                    message: "end date",
                  },
                ]}
              //   initialValue={companyProfile?.profileCreatorRole}
              >
               <DatePicker onChange={onChange} /> 
              </StyledFormItem>
            </CustomCol>
          </Row>

        </FormWrapper>

        <Divider />
     {question.map((item)=>(
      <Fragment key={item.toString()}>
          <CloseCircleOutlined style={{marginLeft:"30px"}} onClick={()=>remove(item)}/>
      <Row
          style={{
            justifyContent: "space-between",
            width: "100%",
            marginBottom: "23px",
          }}
        >
          <CustomCol span={24}>
            <StyledFormItem
              name={["questionsList",item, "question"]}
              // label="Name of the person"
              rules={[
                {
                  message: "Enter you question here",
                  pattern: new RegExp("^[a-zA-Z][a-zA-Z\\s]+$"),
                },
                {
                  required: true,
                  message: "Enter your question here",
                },
              ]}
            >
              <Input placeholder="Enter your question here"
              // value={question}
              // onChange={e=>setQuestion(e.target.value)}
               />
            </StyledFormItem>
          </CustomCol>
        </Row>

        <FormWrapper>
          <Row
            style={{
              justifyContent: "space-between",
              width: "50%",
              marginBottom: "23px",
            }}
          >
            <SubText style={{ paddingLeft: 30 }}>Options</SubText>
            <CustomCol span={24}>
              <StyledFormItem
                name={["questionsList",item, "options",0]}
                // label="Name of the person"
                rules={[
                  {
                    message: "Option 01",
                    pattern: new RegExp("^[a-zA-Z][a-zA-Z\\s]+$"),
                  },
                  {
                    required: true,
                    message: "Option 01",
                  },
                ]}
              >
                <Input placeholder="Option 01"
                // value={options}
                // onChange={e=>setOptions(e.target.value)}
                 />
              </StyledFormItem>
            </CustomCol>
          </Row>

          <Row
            style={{
              justifyContent: "space-between",
              width: "50%",
              marginBottom: "23px",
            }}
          >
            <SubText></SubText>
            <CustomCol span={24}>
              <StyledFormItem
               name={["questionsList",item, "options",1]}
                // label="Name of the person"
                rules={[
                  {
                    message: "Option 02",
                    pattern: new RegExp("^[a-zA-Z][a-zA-Z\\s]+$"),
                  },
                  {
                    required: true,
                    message: "Option 02",
                  },
                ]}
              >
                <Input placeholder="Option 02" 
                // value={options}
                // onChange={e=>setOptions(e.target.value)}
                />
              </StyledFormItem>
            </CustomCol>
          </Row>

        </FormWrapper>

        <FormWrapper>
          <Row
            style={{
              justifyContent: "space-between",
              width: "50%",
              marginBottom: "23px",
            }}
          >
            <SubText></SubText>
            <CustomCol span={24}>
              <StyledFormItem
                name={["questionsList",item, "options",2]}
                // label="Name of the person"
                rules={[
                  {
                    message: "Option 03",
                    pattern: new RegExp("^[a-zA-Z][a-zA-Z\\s]+$"),
                  },
                  {
                    required: true,
                    message: "Option 03",
                  },
                ]}
              >
                <Input placeholder="Option 03" 
                // value={options}
                // onChange={e=>setOptions(e.target.value)}
                />
              </StyledFormItem>
            </CustomCol>
          </Row>

        </FormWrapper>

        <FormWrapper>
          <Row
            style={{
              justifyContent: "space-between",
              width: "50%",
              marginBottom: "23px",
            }}
          >
            <SubText style={{ paddingLeft: 30 }}>Answer typed</SubText>
            <CustomCol span={24}>
              <StyledFormItem
                name={["questionsList",item, "answerType"]}
                rules={[
                  {
                    required: true,
                    message: "single select",
                  },
                ]}
              //   initialValue={companyProfile?.profileCreatorRole}
              >
                <Select
                  style={{ cursor: "pointer" }}
                  showSearch
                  placeholder="select an option"
                  options={[
                    {
                      value: 'singleSelect',
                      label: 'Single select',
                    },
                    {
                      value: 'multiSelect',
                      label: 'Multi select',
                    },
                  ]}
                  // value={answerType}
                  // onChange={e=>setAnswerType(e.target.value)}                  
                />
              </StyledFormItem>
            </CustomCol>
          </Row>

          <Row
            style={{
              justifyContent: "space-between",
              width: "50%",
              marginBottom: "23px",
            }}
          >
            <SubText style={{ marginLeft: 30 }}>Is answer required?</SubText>
            <CustomCol span={24}>
              <StyledFormItem
                name={["questionsList",item, "isRequired"]}
                rules={[
                  {
                    required: true,
                    message: "True",
                  },
                ]}
              //   initialValue={companyProfile?.profileCreatorRole}
              >
                <Select
                  style={{ cursor: "pointer" }}
                  showSearch
                  placeholder="select an option"
                  options={[
                    {
                      value: true,
                      label: 'true',
                    },
                    {
                      value: false,
                      label: 'false',
                    },
                  ]}
                  // value={isRequired}
                  // onChange={e=>setIsRequired(e.target.value)}
                />
              </StyledFormItem>
            </CustomCol>
          </Row>

        </FormWrapper>
      </Fragment>
     ))}
        

       <ButtonContainer>
        <SubmitButton>
          <Button 
          type="primary" 
          htmlType="submit"
          style={{width:"100%"}}
          >
            <PlusOutlined />
            Add Form
          </Button>
        </SubmitButton>

        <SubmitButton>
          <Button 
          type="primary" 
          htmlType="button" 
          style={{width:"100%"}}
          onClick={()=>setQuestion(question=>[...question, question[question.length-1]+1])}
          >
            <PlusOutlined />
            Add Question
          </Button>
        </SubmitButton>
        </ButtonContainer>

      </StyledForm>
    </FormListContainer>
  );
};

export default CreateForm;
