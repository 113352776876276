import React from "react";
import { 
  StyledColumn, 
  StyledRow, 
  StyledTable, 
  StyledText, 
  StyledButton
} from "./styledComponents";

const PlanCard = ({ data, deletePlan, viewPlanDetails, editPlan }) => {
  return (
    <StyledTable>

      {/* Header */}
      <StyledRow>
        <StyledColumn header={true}>Plan Name</StyledColumn>
        <StyledColumn header={true}>Subscription Rank</StyledColumn>
        <StyledColumn header={true}>Plan Price</StyledColumn>
      </StyledRow>

      {data?.map(item => (
        <StyledRow key={item._id}>
          <StyledColumn>
            <StyledText>{item?.planName}</StyledText>
          </StyledColumn>
          <StyledColumn>
            <StyledText>{item?.subscriptionRank}</StyledText>
          </StyledColumn>
          <StyledColumn>
            <StyledText>₹ {item?.planPrice}</StyledText>
          </StyledColumn>
          <StyledColumn>
            <StyledButton onClick={() => viewPlanDetails(item._id)}>
              <p>View Plan Details</p>
            </StyledButton>
          </StyledColumn>
          <StyledColumn>
            <StyledButton onClick={() => editPlan(item._id)}>
              <p>Edit Plan</p>
            </StyledButton>
          </StyledColumn>
          <StyledColumn>
            <StyledButton type="delete" onClick={() => deletePlan(item._id)}>
              <p>Delete Plan</p>
            </StyledButton>
          </StyledColumn>
        </StyledRow>
      ))}

    </StyledTable>
  );
}

export default PlanCard;