import React from "react";

function ManagerRevenueIcon({ height = "512", width = "512", active }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 714.14 714.14"
    >
      {active ? (
        <>
          <path
            fill="#efa322"
            d="M357.07 41.85c-173.81 0-315.23 141.41-315.23 315.21s141.41 315.25 315.23 315.25S672.3 530.9 672.3 357.07 530.89 41.85 357.07 41.85zM386 520.21v38.55a28.88 28.88 0 01-57.77 0v-38.37h-38.39a28.88 28.88 0 110-57.77h89.64a38.34 38.34 0 100-76.67h-44.83a96.12 96.12 0 01-6.47-192v-38.58a28.88 28.88 0 0157.77 0v38.37h38.34a28.88 28.88 0 110 57.77h-89.64a38.34 38.34 0 100 76.67h44.84a96.12 96.12 0 016.47 192z"
            className="a"
          ></path>
          <path
            fill="#c8881d"
            d="M609.56 104.59a357.07 357.07 0 10-505 505 357.07 357.07 0 10505-505zM357.07 672.31c-173.81 0-315.23-141.41-315.23-315.25S183.25 41.85 357.07 41.85 672.3 183.26 672.3 357.07 530.89 672.31 357.07 672.31z"
            className="b"
          ></path>
          <path
            fill="#5472a3"
            d="M379.48 344.1h-44.83a54.26 54.26 0 110-108.51h89.64a13 13 0 100-25.93H370v-54.29a13 13 0 00-25.93 0v54.29h-8c-45.71 0-81.68 36-81.68 80.22A80.25 80.25 0 00334.65 370h44.84a54.26 54.26 0 110 108.51h-89.65a13 13 0 100 25.93h54.26v54.29a13 13 0 0025.93 0v-54.26h8c45.71 0 81.68-35.94 81.68-80.16a80.31 80.31 0 00-80.23-80.21z"
            className="c"
          ></path>
        </>
      ) : (
        <>
          <path
            fill="#6a6a6a"
            d="M609.56 104.58a357.07 357.07 0 00-505 505 357.07 357.07 0 00505-505zM357.07 672.3c-173.82 0-315.23-141.41-315.23-315.23S183.25 41.84 357.07 41.84 672.3 183.25 672.3 357.07 530.89 672.3 357.07 672.3z"
            className="a"
          ></path>
          <path
            fill="#6a6a6a"
            d="M379.48 336.15h-44.82a46.31 46.31 0 110-92.61h89.64a20.92 20.92 0 100-41.84H378v-46.32a20.92 20.92 0 00-41.84 0v46.31h-1.49a88.15 88.15 0 100 176.3h44.82a46.31 46.31 0 010 92.62h-89.65a20.92 20.92 0 000 41.84h46.31v46.31a20.92 20.92 0 0041.84 0v-46.31h1.49a88.15 88.15 0 000-176.3z"
            className="a"
          ></path>
        </>
      )}
    </svg>
  );
}

export default ManagerRevenueIcon;
