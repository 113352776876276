import React, { useEffect, useState } from "react";
import { Modal, Spin } from "antd";
import CustomLayout from "../../Components/CustomLayout";
import EmptyComponent from "../../Components/EmptyComponent";
import CategoryCard from "./CategoryCard";
import CreateCategory from "./CreateCategory";
import CategoryDetails from "./CategoryDetails";
import ApiCalls from "../../apis/ApiCalls";
import { Main, CardsWrapper, StyledSpin, CreateButton, ButtonTitle, Label, AlignedRow } from "./styledComponents";

const ManageCategories = () => {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [detailsModal, setDetailsModal] = useState({ show: false, modalType: null, category: null });
  const [deleteLoading, setDeleteLoading] = useState(false);

  const getAllCategories = async() => {
    try {
      setLoading(true);
      let { data } = await ApiCalls.getAllCategories();
      setCategories([...data.data]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getAllCategories();
  }, []);

  const closeModal = (refresh = false) => {
    if(refresh) {
      getAllCategories();
    }
    setDetailsModal({ show: false, modalType: null, category: null });
  }

  const confirmDelete = async() => {
    setDeleteLoading(true);
    ApiCalls.deleteCategory(detailsModal.category?._id)
      .then(res => {
        closeModal(true);
        setDeleteLoading(false);
      })
      .catch(error => {
        closeModal(true);
        setDeleteLoading(false);
      });
  }

  const deleteCategory = (category) => {
    setDetailsModal({ show: true, modalType: 'delete', category  });
  }

  const editCategory = (category) => {
    setDetailsModal({ show: true, modalType: 'editCategory', category });
  }

  const viewCategoryDetails = (category) => {
    setDetailsModal({ show: true, modalType: 'details', category });
  }

  return (
    <Main>
      <CustomLayout
        mainTitle="Manage Categories"
        makePaddingZero={true}
        headerComponent={
          <CreateButton 
            onClick={() => setDetailsModal(detailsModal => ({ ...detailsModal, show: true, modalType: 'createCategory' }))}
          >
            <ButtonTitle>
              Create Category
            </ButtonTitle>
          </CreateButton>
        }
      />

      {loading ? (
        <StyledSpin spinning={true} size="large" /> 
      ) : (
        <CardsWrapper>
          {categories.length === 0 ? (
            <EmptyComponent message="No data found" />
          ) : (
            <CategoryCard 
              data={categories} 
              deleteCategory={deleteCategory} 
              editCategory={editCategory} 
              viewCategoryDetails={viewCategoryDetails} 
            />
          )}
        </CardsWrapper>
      )}

      {/* Modal */}
      <Modal
        visible={detailsModal.show}
        onCancel={() => closeModal(false)}
        footer={false}
        width={detailsModal.modalType === "delete" ? "25rem" : "60vw"}
        style={{ maxWidth: detailsModal.modalType === 'details' ? '50vw' : '45rem' }}
        centered={true}
        destroyOnClose={true}
      >
        {detailsModal.modalType === 'details' ? (
          <CategoryDetails category={detailsModal.category} />
        ) : detailsModal.modalType === "delete" ? (
          <div>
            <Label fontWeight={600} marginTop="20px">You're about to delete the category, Are you sure?</Label>
            <AlignedRow>
              <Label marginRight="50px" color="#e60000" hover onClick={confirmDelete}>
                Yes {deleteLoading ? <Spin /> : null}
              </Label>
              <Label color="#0000cc" hover onClick={() => closeModal(false)}>No</Label>
            </AlignedRow>
          </div>
        ) : (
          <CreateCategory 
            closeModal={closeModal} 
            type={detailsModal.modalType} 
            categoryId={detailsModal.category?._id} 
          />
        )}
      </Modal>

    </Main>
  );
}

export default ManageCategories;