import React, { useEffect } from "react";
import { Layout, Menu, Typography, Dropdown } from "antd";
import styled from "styled-components";
import Logo from "../../images/js/Logo";
import { UserOutlined } from "@ant-design/icons";
import {
  Link,
  Route,
  Switch as RouterSwitch,
  withRouter,
} from "react-router-dom";
import { connect } from "react-redux";
import ProfileAvatar from "../../Components/ProfileAvatar";
import { compose } from "redux";
import storage from "../../storage";
import reduxActions from "../../redux-store/actions";
import Home from "../home/Index";
import EmptyComponent from "../../Components/EmptyComponent";
import { useHistory } from "react-router";
import jwt from "jwt-decode";
const { Header } = Layout;


const StyledHeader = styled(Header)`
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  position: fixed;
  z-index: 2;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 3%;
  justify-content: space-between;

  & > div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    & > div {
      margin-right: 10%;
      display: flex;
      align-items: center;
    }
  }
`;

const StyledHeaderMenu = styled(Menu)`
  width: 150px;
  top: 15px;
  padding: 15px;
  font-family: "Montserrat", sans-serif;
  left: 28px;

  .ant-dropdown-menu-item > a,
  .ant-dropdown-menu-submenu-title > a,
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    color: #5472a3;
    font-weight: 600;
  }
`;

const StyledDropDown = styled(Dropdown)`
  width: 120px;
`;

const StyledTypographyText = styled(Typography.Text)`
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  line-height: 12px;
  font-size: 16px;
  color: #5472a3;
`;

const Base = (props) => {
  const history = useHistory();

  const logout = () => {
    storage.authToken.clear();
    reduxActions.authActions.removeAuthToken();
    props.history.push("/login");
  };

  const menu = (
    <StyledHeaderMenu>
      <Menu.Item onClick={logout}>Logout</Menu.Item>
    </StyledHeaderMenu>
  );

  useEffect(() => {
    try {
      const decodedToken = jwt(storage.authToken.getItem());
      if (decodedToken.exp * 1000 < Date.now()) {
        history.push("/login");
      }
    } catch (e) {
      history.push("/login");
    }
  }, []);

  return (
    <Layout color="#000">
      <StyledHeader style={{ backgroundColor: "#fff" }}>
        <div>
          <div>
            <Link to="/base/home/dashboard" style={{ height: '40px' }}>
              <Logo width="159px" height="30.7px" to="" />
            </Link>
          </div>
        </div>
        <div className="right-components">
          <StyledDropDown
            overlay={menu}
            placement="bottomRight"
            trigger={["click"]}
          >
            <div>
              <ProfileAvatar
                url={props.userData.profilePic}
                size={"medium"}
                style={{ marginRight: "10px" }}
                icon={<UserOutlined />}
              />
              <StyledTypographyText>Admin</StyledTypographyText>
            </div>
          </StyledDropDown>
        </div>
      </StyledHeader>
      <RouterSwitch>
        <Route path="/base/home" render={(props) => <Home {...props} />} />
        <Route
          path="*"
          exact={true}
          render={() => (
            <EmptyComponent message="404,Page not found" minHeight="100vh" />
          )}
        />
      </RouterSwitch>
    </Layout>
  );
};
const mapStateToProps = (store) => {
  return {
    userData: store.auth.userData,
  };
};
export default compose(connect(mapStateToProps), withRouter)(Base);
