import React, { useState, useEffect, useContext } from "react";
import { Table, Col, Modal } from "antd";
import ProfileAvatar from "../../Components/ProfileAvatar";
import CustomButton from "../../Components/CustomButton";
import CustomText from "../../Components/CustomText";
import ClaimDetails from "./ClaimDetails";
import ApiCalls from "../../apis/ApiCalls";
import SearchContext from "./SearchContext";

const PatientTable = ({ activeKey }) => {
  const [loading, setLoading] = useState(false);
  const [claimsList, setClaimsList] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [selectedClaim, setSelectedClaim] = useState({});
  const searchKey = useContext(SearchContext);
  const pageLimit = 10;

  const { Column } = Table;
  const handlePageChange = (pageNumber, size) => {
    setPageNumber(pageNumber);
  };

  const handleCancelModal = () => {
    setShowModal(false);
  };
  const handleRefetch = () => {
    setRefetch(!refetch);
  };
  const handleDetailView = (item) => {
    setSelectedClaim(item);
    setShowModal(true);
  };
  useEffect(() => {
    setPageNumber(1);
  }, [searchKey, activeKey]);

  useEffect(() => {
    setLoading(true);
    ApiCalls.getInsuranceClaimRequests({
      claimStatus: activeKey,
      page: pageNumber - 1,
      limit: pageLimit,
      name: searchKey,
    })
      .then((res) => {
        if (res.data.status === 1) {
          setClaimsList(res.data.data.data);
          setTotalCount(res.data.data.count);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [activeKey, refetch, pageNumber, searchKey]);

  return (
    <>
      <Table
        dataSource={claimsList}
        tableLayout="fixed"
        scroll={{ y: 300 }}
        loading={loading}
        pagination={{
          limit: pageLimit,
          total: totalCount,
          pageSize: pageLimit,
          hideOnSinglePage: true,
          position: ["bottomCenter"],
          onChange: handlePageChange,
          defaultCurrent: 1,
          current: pageNumber,
        }}
        style={{
          minHeight: "60vh",
          cursor: "pointer",
        }}
      >
        <Column
          dataIndex="patientInfo"
          width={70}
          render={(field) => (
            <Col>
              <ProfileAvatar url={field.profilePic} size={48} />
            </Col>
          )}
        />
        <Column
          align="center"
          dataIndex="patientInfo"
          key="name"
          title={<CustomText fontWeight="600">Patient</CustomText>}
          render={(field, record) => (
            <CustomText fontWeight="600" size="14px" color="blue">
              {`${field.firstName} ${field.lastName}`}
            </CustomText>
          )}
        />
        <Column
          align="center"
          dataIndex="consultationInfo"
          title={<CustomText fontWeight="600">Date</CustomText>}
          key="date"
          render={(consultationInfo) => (
            <CustomText>{consultationInfo.startDate}</CustomText>
          )}
        />
        <Column
          align="center"
          dataIndex="insuranceCardDetails"
          title={
            <CustomText fontWeight="600">Insurance Card Number</CustomText>
          }
          render={(insuranceCardDeatils = {}) => (
            <CustomText>{insuranceCardDeatils.policyNumber}</CustomText>
          )}
          key="insuranceCardDeatils"
        />
        <Column
          align="center"
          dataIndex="transactionDetails"
          title={<CustomText fontWeight="600">Amount</CustomText>}
          key="mobile"
          render={(transactionDetails = {}) => (
            <CustomText>{transactionDetails.insuranceClaimAmount}</CustomText>
          )}
        />
        <Column
          align="center"
          render={(_, record) => (
            <CustomButton
              shape="round"
              style={{
                backgroundColor: "#f5f5f5",
                color: "#000",
              }}
              onClick={() => handleDetailView(record)}
            >
              VIEW DETAILS
            </CustomButton>
          )}
        />
      </Table>
      <Modal
        visible={showModal}
        onCancel={handleCancelModal}
        footer={false}
        width={"72%"}
        centered={true}
        destroyOnClose={true}
      >
        <ClaimDetails
          handleCancelModal={handleCancelModal}
          selectedClaim={selectedClaim}
          activeKey={activeKey}
          handleRefetch={handleRefetch}
        />
      </Modal>
    </>
  );
};

export default PatientTable;
