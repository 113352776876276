import { Row } from "antd";
import styled from "styled-components";

export const FamilyMemberWrapper = styled.div`
  border: 1px solid grey;
  padding: 20px;
  margin-top: 15px;
`;

export const StyledRow = styled(Row)`
  margin-top: 5px;
  margin-bottom: 5px;
`;