import React, { useEffect, useState } from 'react'
import { Divider, Spin } from 'antd'
import styled from 'styled-components'
import PatientFileHeader from './PatientFileHeader'
import ApiCalls from '../../../apis/ApiCalls'
import PatientInfo from './PatientInfo'

// const { TabPane } = Tabs

const WrapperRow = styled.div`
  width: 100%;
  min-height:400px;
  /* padding:8px; */
  .ant-tabs-content {
    height: 600px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .ant-tabs-tab-active {
    color: #2959a8;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
  }
  .ant-tabs-tab {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    &:hover {
      color: #2959a8;
    }
  }
`

const PatientFile = ({ selectedPatient, handleCancelModal }) => {
  const [patientFile, setPatientFile] = useState({})
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (Object.keys(selectedPatient).length === 0) {
      setPatientFile({})
    } else {
      setLoading(true)
      ApiCalls.getPatientHealthRecord(selectedPatient._id).then(response => {
        if (response.data.status) {
          setLoading(false)
          setPatientFile(response.data.data[0])
        }
      }).catch(error => {
        setLoading(false)
      })
    }
  }, [])

  return (
    <WrapperRow>
      <PatientFileHeader selectedPatient={selectedPatient} />
      <Divider />
      <Spin spinning={loading}>
        <PatientInfo
          patientFile={patientFile}
          handleCancelModal={handleCancelModal}
          loading={loading}
        />
      </Spin>
      {/* <Tabs defaultActiveKey='1'>
        <TabPane tab='Patient' key='1'>
          <Spin spinning={loading}>
          <PatientInfo
            patientFile={patientFile}
            handleCancelModal={handleCancelModal}
            loading={loading}
          />
          </Spin>
        </TabPane>
        <TabPane tab='Scheduled' key='2'>
          Scheduled
        </TabPane>
        <TabPane tab='Past' key='3'>
          Past
        </TabPane>
        <TabPane tab='Missed' key='4'>
          Missed
        </TabPane>
      </Tabs> */}
    </WrapperRow>
  )
}
export default PatientFile
