import React, { useState, useEffect } from "react";
import { Row } from "antd";
import CompanyCard from "./CompanyCard";
import ApiCalls from "../../apis/ApiCalls";
import { CreateCategoryWrapper, Title, Label, StyledSpin } from "./styledComponents";

const CategoryDetails = ({ category }) => {
  const [loading, setLoading] = useState(false);
  const [categoryDetails, setCategoryDetails] = useState({});
  const [companies, setCompanies] = useState([]);

  // Get category details
  const getCategoryDetails = async() => {
    setLoading(true);
    try {
      let { data } = await ApiCalls.getSingleCategoryDetails(category?._id);
      if(data.data) {
        setCategoryDetails({
          ...data.data
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  // Get companies in category
  const getCategoryCompanies = async() => {
    setLoading(true);
    try {
      let { data } = await ApiCalls.getAllCompaniesInCategory(category?._id);
      if(data.data?.length > 0 && data?.status === 1) {
        setCompanies(data.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getCategoryDetails();
    getCategoryCompanies();
  }, []);

  return (
    <CreateCategoryWrapper>

      {/* Title */}
      <Title>Category Details</Title>

      {loading ? (
        <StyledSpin />
      ) : (
        <>

          {/* Category Name */}
          <Row>
            <Label fontWeight={600}>Category Name&emsp;:&emsp;</Label>
            <Label>{categoryDetails.categoryName}</Label>
          </Row>

          {/* Company List */}
          {companies?.length > 0 ? (
            <>
              <Title>Company List</Title>
              <CompanyCard data={companies} />
            </>
          ) : null}
          
        </>
      )}

    </CreateCategoryWrapper>
  );
}

export default CategoryDetails;