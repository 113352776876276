import React, { useState, useEffect } from "react";
import { Row } from "antd";
import moment from 'moment/moment';
import ApiCalls from "../../apis/ApiCalls";
import { CreateCompanyWrapper, Title, Label, StyledSpin } from "./styledComponents";

const CompanyDetails = ({ company }) => {
  const [loading, setLoading] = useState(false);
  const [companyDetails, setCompanyDetails] = useState({});

  // Get company details
  const getCompanyDetails = async() => {
    setLoading(true);
    try {
      let { data } = await ApiCalls.getSingleCompanyDetails(company?._id);
      if(data.data) {
        setCompanyDetails({
          ...data.data
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getCompanyDetails();
  }, []);

  return (
    <CreateCompanyWrapper>

      {/* Title */}
      <Title>Company Details</Title>

      {loading ? (
        <StyledSpin />
      ) : (
        <>

          {/* Company Name */}
          <Row>
            <Label fontWeight={600}>Company Name&emsp;:&emsp;</Label>
            <Label>{companyDetails.companyName}</Label>
          </Row>

          {/* Comapny email */}
          <Row>
            <Label fontWeight={600}>Company Email&emsp;:&emsp;</Label>
            <Label>{companyDetails.companyEmail}</Label>
          </Row>

          {/* Comapny website */}
          <Row>
            <Label fontWeight={600}>Company Website&emsp;:&emsp;</Label>
            <Label>{companyDetails.companyWebsite}</Label>
          </Row>

          {/* Company Address */}
          <Row>
            <Label fontWeight={600}>Company Address&emsp;:&emsp;</Label>
            <Label>{companyDetails.companyAddress}</Label>
          </Row>

          {/* Approval status */}
          <Row>
            <Label fontWeight={600}>Company Approval Status&emsp;:&emsp;</Label>
            <Label>{companyDetails.companyApprovalStatus}</Label>
          </Row>

          {/* Company Category */}
          <Row>
            <Label fontWeight={600}>Company Category&emsp;:&emsp;</Label>
            <Label>{companyDetails.companyCategory?.categoryName}</Label>
          </Row>

          {/* Subscription details */}
          {companyDetails.subscriptionDetails?.planName ? (
            <>
              <Title style={{ marginTop: '20px' }}>Company Subscription Details</Title>

              {/* Plan name */}
              {companyDetails.subscriptionDetails?.planName ? (
                <Row>
                  <Label fontWeight={600}>Plan Name&emsp;:&emsp;</Label>
                  <Label>{companyDetails.subscriptionDetails?.planName}</Label>
                </Row>
              ) : null}

              {/* Plan price */}
              {companyDetails.subscriptionDetails?.planPrice ? (
                <Row>
                  <Label fontWeight={600}>Plan Price&emsp;:&emsp;</Label>
                  <Label>{companyDetails.subscriptionDetails?.planPrice}</Label>
                </Row>
              ) : null}

              {/* Plan type */}
              {companyDetails.subscriptionDetails?.planType ? (
                <Row>
                  <Label fontWeight={600}>Plan Type&emsp;:&emsp;</Label>
                  <Label>{companyDetails.subscriptionDetails?.planType}</Label>
                </Row>
              ) : null}

              {/* Plan duration */}
              {companyDetails.subscriptionDetails?.planDuration ? (
                <Row>
                  <Label fontWeight={600}>Plan Duration&emsp;:&emsp;</Label>
                  <Label>{companyDetails.subscriptionDetails?.planDuration}</Label>
                </Row>
              ) : null}

              {/* Subscription rank */}
              {companyDetails.subscriptionDetails?.subscriptionRank ? (
                <Row>
                  <Label fontWeight={600}>Subscription Rank&emsp;:&emsp;</Label>
                  <Label>{companyDetails.subscriptionDetails?.subscriptionRank}</Label>
                </Row>
              ) : null}

              {/* Credits left */}
              <Row>
                <Label fontWeight={600}>Credits Left&emsp;:&emsp;</Label>
                <Label>{companyDetails.subscriptionCreditDetails?.creaditsLeft}</Label>
              </Row>

              {/* Credits purchased */}
              <Row>
                <Label fontWeight={600}>Credits Purchased&emsp;:&emsp;</Label>
                <Label>{companyDetails.subscriptionCreditDetails?.creditsPurchased}</Label>
              </Row>

              {/* Doctor count */}
              {companyDetails.subscriptionDetails?.doctorCount ? (
                <Row>
                  <Label fontWeight={600}>Therapist Count&emsp;:&emsp;</Label>
                  <Label>{companyDetails.subscriptionDetails?.doctorCount}</Label>
                </Row>
              ) : null}

              {/* Date of purchase */}
              {companyDetails.subscriptionDetails?.dateOfPurchase ? (
                <Row>
                  <Label fontWeight={600}>Date of Purchase&emsp;:&emsp;</Label>
                  <Label>{moment(companyDetails.subscriptionDetails?.dateOfPurchase).format("Do MMM YYYY")}</Label>
                </Row>
              ) : null}

              {/* Discount percentage */}
              {companyDetails.subscriptionDetails?.discountPercentage ? (
                <Row>
                  <Label fontWeight={600}>Discount Percentage&emsp;:&emsp;</Label>
                  <Label>{companyDetails.subscriptionDetails?.discountPercentage}</Label>
                </Row>
              ) : null}
            </>
          ) : null}
          
        </>
      )}

    </CreateCompanyWrapper>
  );
}

export default CompanyDetails;