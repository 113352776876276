import React, { useState, useEffect } from "react";
import {
  StyledTable,
  StyledRow,
  StyledColumn,
  Main,
  PaginationContainer,
  PageFunctionContainer,
  ButtonsContainer,
  TabButton,
  StyledButtonsContainerForLastColumn,
} from "./styledComponents";
import CustomLayout from "../../Components/CustomLayout";
import CustomText from "../../Components/CustomText";
import { LoadingOutlined } from "@ant-design/icons";
import ApiCalls from "../../apis/ApiCalls";
import { message, Pagination, Input, Button, Modal } from "antd";
import AddNewMedicine from "./AddNewMedicine";
import ImportMedicines from "./ImportMedicines";
import EditMedicineModal from "./EditMedicine";
import DeleteModal from "./DeleteModal";
import EmptyComponent from "../../Components/EmptyComponent";

const MedicineLibrary = () => {
  const [loading, setLoading] = useState(false);
  const [medicineList, setMedicineList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [addedBy, setAddedBy] = useState("admin");
  const [showAddMedicine, setShowAddMedicine] = useState(false);
  const [showImportMedicine, setShowImportMedicine] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [currentSelectedMedicine, setCurrentSelectedMedicine] = useState({});
  const [editModalVisibility, setEditModalVisibility] = useState(false);
  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);
  const [samplePdfUrl, setSamplePdfUrl] = useState("");

  const fetchMedicineList = () => {
    let params = {
      page: currentPage,
      limit: 10,
      searchKey: searchValue,
      addedBy: addedBy,
    };
    setLoading(true);
    ApiCalls.getMedicineList(params)
      .then((response) => {
        if (response.data.status) {
          if (response.data.data.medicines.length === 0 && currentPage > 0) {
            setCurrentPage(currentPage - 1);
          }
          setSamplePdfUrl(response.data.data.medicineListUrl);
          setMedicineList(response.data.data.medicines);
          setTotalCount(response.data.data.totalCount);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };

  useEffect(() => {
    fetchMedicineList();
  }, [currentPage, addedBy]);

  useEffect(() => {
    setCurrentPage(0);
  }, [addedBy, searchValue]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchMedicineList(0);
    }, 500);
    return () => {
      clearInterval(delayDebounceFn);
    };
  }, [searchValue]);

  const handleEditButtonPress = async (medicineObject) => {
    setCurrentSelectedMedicine(medicineObject);
    setEditModalVisibility(true);
  };
  const handleDeleteButtonPress = async (medicineObject) => {
    setCurrentSelectedMedicine(medicineObject);
    setDeleteModalVisibility(true);
  };

  return (
    <>
      <Main>
        <CustomLayout
          mainTitle="Medicine Library"
          makePaddingZero={true}
          headerComponent={
            <PageFunctionContainer>
              <Input
                autoFocus={true}
                placeholder="Search"
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
                allowClear
              />
              <ButtonsContainer>
                <Button onClick={() => setShowAddMedicine(true)} type="primary">
                  Add Medicine
                </Button>
                <Button
                  onClick={() => setShowImportMedicine(true)}
                  type="primary"
                >
                  Import Medicines
                </Button>
              </ButtonsContainer>
            </PageFunctionContainer>
          }
        >
          <ButtonsContainer page={true}>
            <TabButton
              onClick={() => setAddedBy("admin")}
              active={addedBy === "admin"}
            >
              Admin
            </TabButton>
            <TabButton
              onClick={() => setAddedBy("provider")}
              active={addedBy === "provider"}
            >
              Therapist
            </TabButton>
          </ButtonsContainer>
        </CustomLayout>
        <StyledTable>
          <StyledRow addedBy={addedBy}>
            <StyledColumn header={true}>Name</StyledColumn>
            <StyledColumn header={true}>Drug Type</StyledColumn>
            <StyledColumn header={true}>Dosage Form</StyledColumn>
            <StyledColumn header={true}>Description</StyledColumn>
            <StyledColumn header={true}>Strength</StyledColumn>
            <StyledColumn header={true}>Volume</StyledColumn>
            {addedBy !== "admin" && (
              <>
                <StyledColumn header={true}>Therapist name</StyledColumn>
                <StyledColumn header={true}>Patient name</StyledColumn>
              </>
            )}
          </StyledRow>
          {medicineList.length === 0 && !loading && (
            <EmptyComponent message={"No data found"} />
          )}
          {loading ? (
            <LoadingOutlined />
          ) : (
            medicineList.map((item) => {
              return (
                <StyledRow id={item._id} addedBy={addedBy}>
                  <StyledColumn title={item.medicineName}>
                    {item.medicineName.length > 15
                      ? item.medicineName.slice(0, 15) + "..."
                      : item.medicineName}
                  </StyledColumn>
                  <StyledColumn>{item.drugType}</StyledColumn>
                  <StyledColumn>{item.dosageForm}</StyledColumn>
                  <StyledColumn title={item.description}>
                    {item.description && item.description.length > 10
                      ? item.description.slice(0, 10) + "..."
                      : item.description}
                  </StyledColumn>
                  <StyledColumn>{item.strength}</StyledColumn>
                  <StyledColumn>{item.volume}</StyledColumn>
                  {addedBy === "admin" ? (
                    <div>
                      <StyledButtonsContainerForLastColumn>
                        <Button onClick={() => handleEditButtonPress(item)}>
                          Edit
                        </Button>
                        <Button onClick={() => handleDeleteButtonPress(item)}>
                          Delete
                        </Button>
                      </StyledButtonsContainerForLastColumn>
                    </div>
                  ) : item.doctorName && item.patientName ? (
                    <>
                      <StyledColumn title={item.doctorName}>
                        {item.doctorName.length > 10
                          ? item.doctorName.slice(0, 10) + "..."
                          : item.doctorName}
                      </StyledColumn>
                      <StyledColumn title={item.patientName}>
                        {item.patientName.length > 10
                          ? item.patientName.slice(0, 10) + "..."
                          : item.patientName}
                      </StyledColumn>
                    </>
                  ) : null}
                </StyledRow>
              );
            })
          )}
        </StyledTable>
        <PaginationContainer>
          <Pagination
            size="large"
            pageSize={10}
            total={totalCount}
            current={currentPage + 1}
            onChange={(val) => setCurrentPage(val - 1)}
            showSizeChanger={false}
          />
        </PaginationContainer>
      </Main>
      <AddNewMedicine
        showAddMedicine={showAddMedicine}
        setShowAddMedicine={setShowAddMedicine}
        fetchMedicineList={fetchMedicineList}
      />
      <ImportMedicines
        showImportMedicine={showImportMedicine}
        setShowImportMedicine={setShowImportMedicine}
        fetchMedicineList={fetchMedicineList}
        samplePdfUrl={samplePdfUrl}
      />
      <EditMedicineModal
        showEditMedicine={editModalVisibility}
        setShowEditMedicine={setEditModalVisibility}
        currentMedicineDetails={currentSelectedMedicine}
        fetchMedicineList={fetchMedicineList}
      />
      <Modal
        visible={deleteModalVisibility}
        onCancel={() => {
          setDeleteModalVisibility(false);
        }}
        footer={false}
        width={700}
        centered={true}
        destroyOnClose={true}
        title={
          <CustomText size="16" fontWeight="bold">
            Confirmation
          </CustomText>
        }
      >
        <DeleteModal
          selectedMedicine={currentSelectedMedicine}
          handleModalCancel={setDeleteModalVisibility}
          fetchMedicineList={fetchMedicineList}
        />
      </Modal>
    </>
  );
};

export default MedicineLibrary;
