import React, { useState } from "react";
import styled from "styled-components";
import { Modal } from "antd";
import { Avatar } from "antd";
import DetailsModal from "../DetailsModal";

const StyledNameWrapper = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 29px;
  color: rgb(41, 89, 168);
  text-transform: capitalize;
`;

const StyledNameAndPhoneNumberWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledEmailWrapper = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: rgb(174, 174, 174);
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  align-self: center;
`;

const StyledAvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 21px;
`;

const StyledButton = styled.button`
  border-radius: 25px;
  border-width: 0;
  height: 40px;
  font-size: 12px;

  width: 150px;
  display: flex;
  justify-content: space-around;
  -moz-box-align: center;
  align-items: center;
  background-color: ${({ reject }) =>
    reject ? "#f56363" : "rgb(245, 186, 76)"};
  background-color: ${({ details }) => (details ? "rgb(64, 169, 255)" : null)};
  & > p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    font-size: ${({ details }) => (details ? "16px" : null)};
    line-height: 29px;
    color: rgb(255, 255, 255);
    text-transform: capitalize;
    padding-top: 17px;
  }

  &:hover {
    background-color: ${({ reject }) => (reject ? "#db6a6a" : "#fdab13")};
    background-color: ${({ details }) => (details ? "#5fb7ff" : null)};
    border-width: 1px;
    border-color: white;
  }
`;

const PatientCard = ({
  name = "",
  email = "",
  acceptHandler,
  rejectHandler,
  accept,
  reject,
  details,
  id,
  profilePic,
}) => {
  const Main = styled.div`
    width: 100%;
    .content {
      padding: 20px;
      background-color: #ffffff;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  `;

  const [detailsModalState, setDetailsModalState] = useState(false);

  return (
    <>
      <Main>
        <div className="content">
          <div style={{ display: "flex", flexDirection: "row" }}>
            <StyledAvatarWrapper>
              <Avatar size={60} src={profilePic} />
            </StyledAvatarWrapper>
            <StyledNameAndPhoneNumberWrapper>
              <StyledNameWrapper>{name}</StyledNameWrapper>

              <StyledEmailWrapper>{email}</StyledEmailWrapper>
            </StyledNameAndPhoneNumberWrapper>
          </div>
          <StyledButtonsWrapper>
            {accept && (
              <StyledButton onClick={() => acceptHandler(id, name, email)}>
                <p>Accept</p>
              </StyledButton>
            )}
            {reject && (
              <StyledButton
                reject
                onClick={() => rejectHandler(id, name, email)}
              >
                <p>Reject</p>
              </StyledButton>
            )}
            <StyledButton details onClick={() => setDetailsModalState(true)}>
              <p>View Details</p>
            </StyledButton>
          </StyledButtonsWrapper>
        </div>
        <Modal
          visible={detailsModalState}
          onCancel={() => setDetailsModalState(false)}
          footer={false}
          width={"72%"}
          centered={true}
          destroyOnClose={true}
        >
          <DetailsModal details={details} />
        </Modal>
      </Main>
    </>
  );
};

export default PatientCard;
