import React from "react";
import PendingComplaintsList from "./PendingComplaintsList";
import AcceptedComplaintsList from "./AcceptedComplaintsList";
import RejectedComplaintsList from "./RejectedComplaintsList";

let ListController = ({ selectedTab }) => {
  return (
    <>
      {(() => {
        switch (selectedTab) {
          case "pending":
            console.log("list controller");
            return <PendingComplaintsList />;
          case "accepted":
            return <AcceptedComplaintsList />;
          case "rejected":
            return <RejectedComplaintsList />;
        }
      })()}
    </>
  );
};

export default ListController;
