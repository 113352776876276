import { createStore, combineReducers, applyMiddleware } from "redux";
import authReducer from "../reducers/AuthReducer";
import logger from "redux-logger";

export default () => {
    const store = createStore(
        combineReducers({
            auth: authReducer
        }), applyMiddleware(logger)
    );
    return store
}