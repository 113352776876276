import React, { useEffect, useState } from "react";
import { Modal, Spin } from "antd";
import CustomLayout from "../../Components/CustomLayout";
import EmptyComponent from "../../Components/EmptyComponent";
import CouponCard from "./CouponCard";
import CreateCoupon from "./CreateCoupon";
import CouponDetails from "./CouponDetails";
import ApiCalls from "../../apis/ApiCalls";
import { Main, CardsWrapper, StyledSpin, CreateButton, ButtonTitle, Label, AlignedRow } from "./styledComponents";

const ManageCoupons = () => {
  const [loading, setLoading] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [detailsModal, setDetailsModal] = useState({ show: false, modalType: null, id: null });
  const [deleteLoading, setDeleteLoading] = useState(false);

  const getAllCoupons = async() => {
    try {
      setLoading(true);
      let { data } = await ApiCalls.getAllCoupons();
      setCoupons([...data.data]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getAllCoupons();
  }, []);

  const closeModal = (refresh = false) => {
    if(refresh) {
      getAllCoupons();
    }
    setDetailsModal({ show: false, modalType: null, id: null });
  }

  const confirmDelete = async() => {
    setDeleteLoading(true);
    ApiCalls.deleteCoupon(detailsModal.id)
      .then(res => {
        closeModal(true);
        setDeleteLoading(false);
      })
      .catch(error => {
        closeModal(true);
        setDeleteLoading(false);
      });
  }

  const deleteCoupon = (couponId) => {
    setDetailsModal({ show: true, modalType: 'delete', id: couponId });
  }

  const editCoupon = (couponId) => {
    setDetailsModal({ show: true, modalType: 'editCoupon', id: couponId });
  }

  const viewCouponDetails = (couponId) => {
    setDetailsModal({ show: true, modalType: 'details', id: couponId });
  }

  return (
    <Main>
      <CustomLayout
        mainTitle="Manage Coupons"
        makePaddingZero={true}
        headerComponent={
          <CreateButton 
            onClick={() => setDetailsModal(detailsModal => ({ ...detailsModal, show: true, modalType: 'createCoupon' }))}
          >
            <ButtonTitle>
              Create Coupon
            </ButtonTitle>
          </CreateButton>
        }
      />

      {loading ? (
        <StyledSpin spinning={true} size="large" /> 
      ) : (
        <CardsWrapper>
          {coupons.length === 0 ? (
            <EmptyComponent message="No data found" />
          ) : (
            <CouponCard data={coupons} deleteCoupon={deleteCoupon} editCoupon={editCoupon} viewCouponDetails={viewCouponDetails} />
          )}
        </CardsWrapper>
      )}

      {/* Modal */}
      <Modal
        visible={detailsModal.show}
        onCancel={() => closeModal(false)}
        footer={false}
        width={detailsModal.modalType === "delete" ? "25rem" : "70vw"}
        centered={true}
        destroyOnClose={true}
      >
        {detailsModal.modalType === 'details' ? (
          <CouponDetails couponId={detailsModal.id} />
        ) : detailsModal.modalType === "delete" ? (
          <div>
            <Label fontWeight={600} marginTop="20px">You're about to delete the coupon, Are you sure?</Label>
            <AlignedRow>
              <Label marginRight="50px" color="#e60000" hover onClick={confirmDelete}>
                Yes {deleteLoading ? <Spin /> : null}
              </Label>
              <Label color="#0000cc" hover onClick={() => closeModal(false)}>No</Label>
            </AlignedRow>
          </div>
        ) : (
          <CreateCoupon 
            closeModal={closeModal} 
            type={detailsModal.modalType} 
            couponId={detailsModal.id} 
          />
        )}
      </Modal>

    </Main>
  );
}

export default ManageCoupons;