import React from 'react';
import { Row, Col, Input, Collapse } from 'antd'
import CustomText from '../../../Components/CustomText';
const BankDetails = ({ institution }) => {
    return (
        <>
            <Collapse
            >
                <Collapse.Panel
                    header={
                        <CustomText size="16px" color="blue" fontWeight="bold">
                            Bank Details
                        </CustomText>
                    }
                >
                    <Row gutter={24}>
                        <Col span={12}>
                            <CustomText>
                                Account Number
                            </CustomText>
                            <Input
                                readOnly={true}
                                value={institution.accountNumber}
                                defaultValue="NA"
                            >
                            </Input>
                        </Col>
                        <Col span={12}>
                            <CustomText>
                                Bank Name
                            </CustomText>
                            <Input
                                readOnly={true}
                                value={institution.bankName}
                                defaultValue="NA"
                            >
                            </Input>
                        </Col>
                        <Col span={12}>
                            <CustomText>
                                IBAN
                            </CustomText>
                            <Input
                                readOnly={true}
                                value={institution.iban}

                                defaultValue="NA"
                            >
                            </Input>
                        </Col>
                        <Col span={12}>
                            <CustomText>
                                BIC
                            </CustomText>
                            <Input
                                readOnly={true}
                                value={institution.bic}
                                defaultValue="NA"
                            >
                            </Input>
                        </Col>
                        <Col span={24}>
                            <CustomText>
                                Bank Address
                            </CustomText>
                            <Input.TextArea
                                value={institution.bankAddress}
                                readOnly={true}
                                defaultValue="NA"
                            >
                            </Input.TextArea>
                        </Col>
                    </Row>
                </Collapse.Panel>
            </Collapse>

        </>
    )
}
export default BankDetails