import React, { useState, useEffect } from "react";
import { Input, notification, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import ApiCalls from "../../apis/ApiCalls";
import { 
  CreateCategoryWrapper, 
  Label, 
  Title,
  CreateButton,
  ButtonTitle,
  StyledSpin
} from "./styledComponents";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const CreateCategory = ({ closeModal, type, categoryId }) => {
  const [categoryName, setCategoryName] = useState('');
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const getCategoryDetails = async() => {
    setLoading(true);
    try {
      let { data } = await ApiCalls.getSingleCategoryDetails(categoryId);
      if(data.data?.categoryName) {
        setCategoryName(data.data?.categoryName);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    if(type === "editCategory") {
      getCategoryDetails();
    }
  }, []);

  // Save / update category
  const submit = async() => {
    try {
      setButtonLoading(true);
      let dat = {
        categoryName
      };
      let { data } = type === "editCategory" ? await ApiCalls.updateCategory(categoryId, dat) : await ApiCalls.createCategory(dat)
      if(data) {
        closeModal(true);
      }
      setButtonLoading(false);
    } catch (error) {
      notification.error({
        message: error?.response?.data?.data?.message,
        placement: 'topRight'
      });
      setButtonLoading(false);
    }
  }

  return (
    <CreateCategoryWrapper>

      {/* Title */}
      <Title>{type === "editCategory" ? "Update" : "Create"} Category</Title>

      {loading ? (
        <StyledSpin />
      ) : (
        <>

          {/* Category name */}
          <Label>Category Name</Label>
          <Input 
            placeholder='Category name' 
            name='categoryName'
            value={categoryName} 
            onChange={(e) => setCategoryName(e.target.value)}
          />

          {/* Create / update button */}
          <CreateButton 
            type="modalButton"
            disabled={categoryName?.trim('')?.length === 0}
            onClick={submit}
          >
            {buttonLoading ? (
              <Spin indicator={antIcon} />
            ) : (
              <ButtonTitle>{type === "editCategory" ? "Update" : "Create"}</ButtonTitle>
            )}
          </CreateButton>

        </>
      )}

    </CreateCategoryWrapper>
  );
}

export default CreateCategory;