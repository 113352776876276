import React from 'react'
import styled from 'styled-components'
import CustomText from '../../Components/CustomText'
import CalendarFilterForm from './CalendarFilterForm'

const DoctorFeedBackReport = (type) => {
  return (
    <>
    <div>
    <CustomText color='#5472a3' size='1vw' fontWeight='600'>
        Therapist FeedBack Report
      </CustomText>
    </div>
      
      <div>
          <CalendarFilterForm type="feedback"/>
      </div>
    </>
  )
}

export default DoctorFeedBackReport
