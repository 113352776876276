import React, { useState } from "react";
import {
    FormListContainer,
    FormWrapper,
    HeadingText,
    SubmitButton,
    SubText,
    Wrapper,
    StyledFormItem,
    Text,
} from "./styledComponent";
import { Form, Row, Col, Select, Divider, Button, message, DatePicker } from "antd";
import styled from "styled-components";
import ApiCalls from "../../apis/ApiCalls";
import { Input, Radio, Space } from 'antd';
import { useEffect } from "react";
import { useParams, useHistory} from "react-router-dom";
import moment from "moment";

const FormDetails = () => {
    const [form] = Form.useForm();
    const [value, setValue] = useState(1);
    const [singleFormDetails, setSingleFormDetails] = useState({});
    const [loading, setLoading] = useState(false);
    const param = useParams();
    const history= useHistory();

    const CustomCol = styled(Col)`
    & > div {
    margin-bottom: 0px;
    }
    .success:hover {
    border: 2px solid red;
    }
    `;

    const onChange = (e) => {
        console.log(' checked', e.target.value);
        setValue(e.target.value);
    };

    //get single form details api

    const getSingleFormDetails = () => {
        ApiCalls.getSingleForm(param.id)
            .then((res) => {
                setLoading(false);
                if (res?.data?.data) {
                    setSingleFormDetails(res.data.data)
                    message.success("Here is form detail");
                }
            })
            .catch((error) => {
                setSingleFormDetails({});
                setLoading(false);
                if (error.response) {
                    message.info(error.response.data.message);
                } else if (error.request) {
                    message.error("Server not responding");
                } else {
                    message.error("Something went wrong");
                }
            });
    }

    //get single form
  const updateForm = () => {
    history.push(`/base/home/survay-form/update-form/${param.id}`)
    }

    const onclickViewAnswers=()=>{
        history.push(`/base/home/survay-form/form-details/view-answers/${param.id}`)
    }

    useEffect(() => {
        getSingleFormDetails()
    }, [])

    return (
        <FormListContainer>
            <FormWrapper Sub={true}>
                <HeadingText Sub={true}>{singleFormDetails.formName}</HeadingText>
                <Wrapper Sub={true}>
                    <SubmitButton Sub={true}>
                        <Button Sub={true} type="primary" htmlType="submit" onClick={updateForm}>
                            Edit Form
                        </Button>
                    </SubmitButton>
                    <SubmitButton>
                        <Button type="primary" htmlType="submit" onClick={onclickViewAnswers}>
                            View Answers
                        </Button>
                    </SubmitButton>
                </Wrapper>
            </FormWrapper>

            <SubText>{singleFormDetails.formDescription}</SubText>

            <FormWrapper>
                <Row
                    style={{
                        justifyContent: "space-between",
                        width: "50%",
                        marginBottom: "23px",
                    }}
                >
                    <SubText style={{ paddingLeft: 30 }}>Is Form Active?</SubText>
                    <CustomCol span={24}>
                        <StyledFormItem
                            // initialValue={singleFormDetails.isActive}
                            name="true"
                            rules={[
                                {
                                    required: true,
                                    message: "true",
                                },
                            ]}
                        >
                            <Select
                                defaultValue={singleFormDetails.isActive}
                                style={{ cursor: "pointer" }}
                                showSearch
                                placeholder="select an item"
                                optionFilterProp="children"
                                options={[
                                {
                                  value:true,
                                  label: 'true',
                                },
                                {
                                  value:false,
                                  label: 'false',
                                },
                              ]}
                            />
                        </StyledFormItem>
                    </CustomCol>
                </Row>

                <Row
                    style={{
                        justifyContent: "space-between",
                        width: "50%",
                        marginBottom: "23px",
                    }}
                >
                    <SubText style={{ marginLeft: 30 }}>Access Type</SubText>
                    <CustomCol span={24}>
                        <StyledFormItem
                            name="public"
                            rules={[
                                {
                                    required: true,
                                    message: "Form For",
                                },
                            ]}
                        >
                            <Select
                                style={{ cursor: "pointer" }}
                                showSearch
                                defaultValue={singleFormDetails.accessType}
                                placeholder="select an item"
                                optionFilterProp="children"
                                options={[
                                    {
                                      value: 'public',
                                      label: 'public',
                                    },
                                    {
                                      value: 'private',
                                      label: 'private',
                                    },
                                  ]}
                            />
                        </StyledFormItem>
                    </CustomCol>
                </Row>

            </FormWrapper>
   
            <Divider />
            <HeadingText>Questions</HeadingText>
            {singleFormDetails?.questionsList?.map((item, index) => (
                <Wrapper key={item._id}>
                    <Radio.Group onChange={onChange} value={value}><SubText>Q. {index + 1 } {item.question}<span style={{ color: "#EE2525" }}>(Required)</span></SubText>
                    <Space direction="vertical" style={{marginTop:5, marginBottom:10}}>
                        {
                            item?.options?.map((item, index) => (
                                
                                <Radio
                                key={index}
                                value={item}
                            >
                                {item}
                                </Radio>
                                
                            ))
                        }
                    </Space>
                    </Radio.Group>
                    <Text>{item.answerType}</Text>
                </Wrapper>
            ))}


        </FormListContainer>
    )
}
export default FormDetails;