import React from "react";
import styled from "styled-components";
import Background from "../images/svg/Background.svg";

const ImageSection = () => {
  const Main = styled.div`
    background-image: url(${Background});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100%;
    height: 100vh;
  `;
  return (
    <>
      <Main></Main>
    </>
  );
};

export default ImageSection;
