import React, { useState, useEffect } from "react";
import { Row, Modal } from "antd";
import moment from 'moment/moment';
import ApiCalls from "../../apis/ApiCalls";
import { 
  Title, 
  Label, 
  StyledSpin, 
  StyledTable, 
  StyledColumn, 
  StyledText,
  DoctorRow,
  StyledButton,
  CouponDetailsWrapper
} from "./styledComponents";
import DetailsModal from '../ManageDoctors/DetailsModal';

const CouponDetails = ({ couponId }) => {
  const [loading, setLoading] = useState(false);
  const [couponDetails, setCouponDetails] = useState({});
  const [detailsModal, setDetailsModal] = useState({ show: false, id: null });

  const getCouponDetails = async() => {
    setLoading(true);
    try {
      let { data } = await ApiCalls.getSingleCouponDetails(couponId);
      if(data.data) {
        setCouponDetails({
          ...data.data?.couponDetail
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getCouponDetails();
  }, []);

  // View doctor details
  const viewDoctorDetails = (item) => {
    setDetailsModal({ show: true, id: item._id });
  }

  return (
    <CouponDetailsWrapper>

      {/* Title */}
      <Title>Coupon Details</Title>

      {loading ? (
        <StyledSpin />
      ) : (
        <>

          {/* Coupon Code */}
          <Row>
            <Label fontWeight={600}>Coupon Code&emsp;:&emsp;</Label>
            <Label>{couponDetails.couponCode}</Label>
          </Row>

          {/* Coupon Description */}
          <Row>
            <Label fontWeight={600}>Description&emsp;:&emsp;</Label>
            <Label>{couponDetails.tnC?.description}</Label>
          </Row>

          {/* Max avails per user */}
          <Row>
            <Label fontWeight={600}>Maximum avails per user&emsp;:&emsp;</Label>
            <Label>{couponDetails.tnC?.maxAvailsPerUser}</Label>
          </Row>

          {/* Discount Percentage */}
          <Row>
            <Label fontWeight={600}>Discount Percentage&emsp;:&emsp;</Label>
            <Label>{couponDetails.discountPercentage}</Label>
          </Row>

          {/* Minimum value */}
          <Row>
            <Label fontWeight={600}>Minimum value&emsp;:&emsp;</Label>
            <Label>{couponDetails.tnC?.minValue}</Label>
          </Row>

          {/* Start time */}
          <Row>
            <Label fontWeight={600}>Start Time&emsp;:&emsp;</Label>
            <Label>{moment(couponDetails.startTime).format("Do MMMM YYYY")}</Label>
          </Row>

          {/* End Time */}
          <Row>
            <Label fontWeight={600}>End Time&emsp;:&emsp;</Label>
            <Label>{moment(couponDetails.endTime).format("Do MMMM YYYY")}</Label>
          </Row>

          {/* Special Coupon */}
          <Row>
            <Label fontWeight={600}>Special Coupon&emsp;:&emsp;</Label>
            <Label>{couponDetails.tnC?.isSpecialCoupon ? "Yes" : "No"}</Label>
          </Row>

          {couponDetails.tnC?.isSpecialCoupon ? (
            <>

              {/* Applicable to */}
              <Row>
                <Label fontWeight={600}>Applicable to&emsp;:&emsp;</Label>
                <Label>{couponDetails.tnC?.specialCouponDetails[0]?.applicableTo}</Label>
              </Row>

              {/* Applicable list */}
              <Label fontWeight={800} fontSize='18px' style={{ marginBottom: 0 }}>Applicable list</Label>
              <StyledTable style={{ minHeight: '100px' }}>

                {/* Header */}
                <DoctorRow>
                  <StyledColumn header={true}>Name</StyledColumn>
                  <StyledColumn header={true}>Email</StyledColumn>
                </DoctorRow>

                {couponDetails.tnC?.specialCouponDetails[0]?.applicableList?.map(item => (
                  <DoctorRow key={item._id}>

                    {/* Doctor name */}
                    <StyledColumn>
                      <StyledText type="doctor">{item?.name}</StyledText>
                    </StyledColumn>

                    {/* Doctor email */}
                    <StyledColumn>
                      <StyledText type="doctor">{item?.email}</StyledText>
                    </StyledColumn>

                    {/* View doctor details */}
                    <StyledColumn>
                      <StyledButton onClick={() => viewDoctorDetails(item)}>
                        <p>View Details</p>
                      </StyledButton>
                    </StyledColumn>

                  </DoctorRow>
                ))}
              </StyledTable>

            </>
          ) : null}
          
        </>
      )}

      <Modal
        visible={detailsModal.show}
        onCancel={() => setDetailsModal({ show: false, id: null })}
        footer={false}
        width={"72%"}
        centered={true}
        destroyOnClose={true}
      >
        <DetailsModal id={detailsModal.id} fromAppointments />
      </Modal>

    </CouponDetailsWrapper>
  );
}

export default CouponDetails;