import React from "react";
import ProfileAvatar from "../../../../Components/ProfileAvatar";
import CustomText from "../../../../Components/CustomText";
import moment from "moment";
import { Row, Col } from "antd";
import { UserOutlined } from "@ant-design/icons";
import styled from "styled-components";

const ScheduleCard = ({ schedule, startTime, endTime }) => {
  const CardWrapper = styled.div`
    min-height: 250px;
    width: 450px;
    border: 1px solid #d9d9d9;
    padding: 16px;
    margin: 15px 0;
    margin-right: 10px;
    .header-wrapper {
      border-bottom: 1px solid #d9d9d9;
      padding-bottom: 16px;
    }
    .content-wrapper {
      & > div {
        margin: 8px;
      }
    }
  `;

  const ImageWrapper = styled.div`
    & > img {
      height: 100px;
      width: 200px;
    }
  `;
  return (
    <CardWrapper>
      <div className="content-wrapper">
        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">Schedule:</CustomText>
          </Col>
          <Col span={12}>
            <CustomText fontWeight="bold" color="grey">
              {schedule}
            </CustomText>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">Start Time :</CustomText>
          </Col>
          <Col span={12}>
            <CustomText fontWeight="bold" color="grey">
              {startTime}
            </CustomText>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">End time:</CustomText>
          </Col>
          <Col span={12}>
            <CustomText fontWeight="bold" color="grey">
              {endTime}
            </CustomText>
          </Col>
        </Row>
      </div>
    </CardWrapper>
  );
};

export default ScheduleCard;
