import React from 'react'
import { Row, Col, Space } from 'antd'
import styled from 'styled-components'
import CustomText from './CustomText'

const ReadOnlyInput = ({ label = '', value = '', unit = null }) => {
  const ReadOnlyInputWrapper = styled.div`
    font-family: Montserrat;
    font-size: 14px;
    color: #000000;
    margin: 8px auto 8px auto;
    .value {
      border: 0.5px solid #aeaeae54;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 8px 20px;
      font-weight: 600;
      min-width: 200px;
    }
    .unit {
      color: #6b6b6b;
      margin-left: 8px;
      font-weight: 600;
    }
    .label {
      /* margin-right:16px */
      color: #6b6b6b;
      font-weight: 600;
      min-width: 110px;
    }
  `
  return (
    <ReadOnlyInputWrapper>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className='label'>
          <CustomText>{label}&nbsp;:&nbsp;</CustomText>
        </div>
        <div gutter={16} align='middle'>
          <div className='value'>
            <CustomText>
              {value} &nbsp; {unit}
            </CustomText>
          </div>
        </div>
        {/* {unit &&
                    <div className="unit">
                        <CustomText>
                            
                        </CustomText>
                    </div>
                } */}
      </div>
    </ReadOnlyInputWrapper>
  )
}
export default ReadOnlyInput
