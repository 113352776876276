import React from 'react'
import { Typography, Row, Col, Button, Tooltip } from 'antd'
import { UserOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import ProfileAvatar from '../../../Components/ProfileAvatar'
import { useEffect } from 'react'

const CardTitle = styled(Typography)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #2959a8;
`
const CardSubTitle = styled(Typography)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #aeaeae;
`

const SecondaryButton = styled.div`
  .ant-btn {
    background: #e4e4e4;
    color: #9d9d9d;
    border: none;
    border-radius: 25px;
    height: 40px;
    font-size: 12px;
    min-width: 150px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
`

const PatientFileHeader = ({ selectedPatient }) => {
  return (
    <Row justify='space-between' align='middle' style={{ width: '100%' }}>
      <Col>
        <Row gutter={16} align='middle'>
          <Col>
            <ProfileAvatar icon={<UserOutlined />} size={96} url={selectedPatient.profilePic}/>
          </Col>
          <Col>
            {selectedPatient.firstName && (
              <Row>
                <CardTitle>
                  {selectedPatient.firstName.toUpperCase() +
                    ' ' +
                    selectedPatient.lastName.toUpperCase()}
                </CardTitle>
              </Row>
            )}
            <Row>
              <CardSubTitle>{selectedPatient.mobile}</CardSubTitle>
            </Row>
            <Row>
              <CardSubTitle>{selectedPatient.email}</CardSubTitle>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col>
        {/* <Row gutter={16} align='middle'>
          <Col>
            <Tooltip
              placement='topLeft'
              title='Click here to go back'
              arrowPointAtCenter
            >
              <SecondaryButton>
                <Button
                  type='primary'
                  icon={<ArrowLeftOutlined />}
                >
                  BACK
                </Button>
              </SecondaryButton>
            </Tooltip>
          </Col>
        </Row> */}
      </Col>
    </Row>
  )
}
export default PatientFileHeader
