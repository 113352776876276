import React from "react";
import ProfileAvatar from "../../../../Components/ProfileAvatar";
import CustomText from "../../../../Components/CustomText";
import moment from "moment";
import { Row, Col } from "antd";
import { UserOutlined } from "@ant-design/icons";
import styled from "styled-components";

const DoctorInfoCard = ({ doctor }) => {
  const CardWrapper = styled.div`
    min-height: 250px;
    width: 70vw;
    max-width: 550px;
    /* border: 1px solid #d9d9d9; */
    padding: 16px;
    margin: 15px 0;
    margin-right: 10px;
    .header-wrapper {
      border-bottom: 1px solid #d9d9d9;
      padding-bottom: 16px;
    }
    .content-wrapper {
      & > div {
        margin: 8px;
      }
    }
  `;
  return (
    <CardWrapper>
      <div
        style={{ display: "flex", alignItems: "center" }}
        className="header-wrapper"
      >
        <div>
          <ProfileAvatar
            url={doctor.profilePic}
            icon={<UserOutlined />}
            size={60}
          />
        </div>
        <div
          style={{
            display: "flex",
            marginLeft: "16px",
            flexDirection: "column",
          }}
        >
          <CustomText size="16px" color="#2959a8" fontWeight="bold">
            {doctor.firstName}&nbsp;{doctor.lastName}
          </CustomText>
          <CustomText size="16px" color="grey">
            {doctor.phoneNumber}
          </CustomText>
        </div>
      </div>
      <div className="content-wrapper">
        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">Therapist Id:</CustomText>
          </Col>
          <Col span={12}>
            <CustomText fontWeight="bold" color="grey">
              {doctor?._id}
            </CustomText>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">Gender:</CustomText>
          </Col>
          <Col span={12}>
            <CustomText fontWeight="bold" color="grey">
              {doctor.gender}
            </CustomText>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">Country:</CustomText>
          </Col>
          <Col span={12}>
            <CustomText fontWeight="bold" color="grey">
              {doctor.country}
            </CustomText>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">State:</CustomText>
          </Col>
          <Col span={12}>
            <CustomText fontWeight="bold" color="grey">
              {doctor.state}
            </CustomText>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">City:</CustomText>
          </Col>
          <Col span={12}>
            <CustomText fontWeight="bold" color="grey">
              {doctor.city}
            </CustomText>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">Address:</CustomText>
          </Col>
          <Col span={12}>
            <CustomText fontWeight="bold" color="grey">
              {doctor.address}
            </CustomText>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">Email:</CustomText>
          </Col>
          <Col span={12}>
            <CustomText fontWeight="bold" color="grey">
              {doctor.email}
            </CustomText>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">Date Of Birth:</CustomText>
          </Col>
          <Col span={12}>
            <CustomText fontWeight="bold" color="grey">
              {moment(doctor.dob).format("DD-MM-YYYY")}
            </CustomText>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">Consultation Fee:</CustomText>
          </Col>
          <Col span={12}>
            <CustomText fontWeight="bold" color="grey">
              {doctor.consultationFee} {doctor.currency}
            </CustomText>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">Consultation Duration:</CustomText>
          </Col>
          <Col span={12}>
            <CustomText fontWeight="bold" color="grey">
              {doctor.consultationDuration} mins
            </CustomText>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">Consultation Type:</CustomText>
          </Col>
          <Col span={12}>
            <CustomText fontWeight="bold" color="grey">
              {doctor.consultationType}
            </CustomText>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">Total work experience:</CustomText>
          </Col>
          <Col span={12}>
            <CustomText fontWeight="bold" color="grey">
              {doctor.totalWorkExperiance} years
            </CustomText>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">Tagline :</CustomText>
          </Col>
          <Col span={12}>
            <CustomText fontWeight="bold" color="grey">
              {doctor.tagLine}
            </CustomText>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">Rating :</CustomText>
          </Col>
          <Col span={12}>
            <CustomText fontWeight="bold" color="grey">
              {doctor.rating}
            </CustomText>
          </Col>
        </Row>

        {doctor.followUpDays && (
          <Row>
            <Col span={12}>
              <CustomText fontWeight="bold">Follow up days :</CustomText>
            </Col>
            <Col span={12}>
              <CustomText fontWeight="bold" color="grey">
                {doctor.followUpDays} days
              </CustomText>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">Review count :</CustomText>
          </Col>
          <Col span={12}>
            <CustomText fontWeight="bold" color="grey">
              {doctor.vote}
            </CustomText>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">Total earnings :</CustomText>
          </Col>
          <Col span={12}>
            <CustomText fontWeight="bold" color="grey">
              {doctor.totalEarnings} {doctor.currency}
            </CustomText>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">
              Total consulted patients :
            </CustomText>
          </Col>
          <Col span={12}>
            <CustomText fontWeight="bold" color="grey">
              {doctor.totalConsultedPatients}
            </CustomText>
          </Col>
        </Row>
        {doctor.vatPercentage && (
          <Row>
            <Col span={12}>
              <CustomText fontWeight="bold">VAT percentage:</CustomText>
            </Col>
            <Col span={12}>
              <CustomText fontWeight="bold" color="grey">
                {doctor.vatPercentage}
              </CustomText>
            </Col>
          </Row>
        )}
        {doctor.platformAmount && (
          <Row>
            <Col span={12}>
              <CustomText fontWeight="bold">Platform amount:</CustomText>
            </Col>
            <Col span={12}>
              <CustomText fontWeight="bold" color="grey">
                {doctor.platformAmount} {doctor.platformAmountType}
              </CustomText>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">About:</CustomText>
          </Col>
          <Col span={12}>
            <CustomText fontWeight="bold" color="grey">
              {doctor.aboutYou}
            </CustomText>
          </Col>
        </Row>
      </div>
    </CardWrapper>
  );
};

export default DoctorInfoCard;
