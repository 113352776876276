import React, { useState } from "react";
import { BackgroundLayer, FileContainer, CloseButton } from "./styledComponent";
import { CloseOutlined } from "@ant-design/icons";

const MediaViewer = ({ visible, src, close, type }) => {
  return (
    <>
      <BackgroundLayer visible={visible}>
        <FileContainer>
          {(() => {
            switch (type) {
              case "image":
                return <img src={src} alt="" />;
              default:
                return <></>;
            }
          })()}
          <CloseButton onClick={() => close(false)}>
            <CloseOutlined />
          </CloseButton>
        </FileContainer>
      </BackgroundLayer>
    </>
  );
};

export default MediaViewer;
