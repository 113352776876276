import React, { useState } from 'react'
import styled from 'styled-components'
import { Typography, Card, Tabs, Col, Divider, message } from 'antd'
import { useEffect } from 'react'
import ProfileInfo from './ProfileInfo'
import ApiCalls from '../../../apis/ApiCalls'
import InstitutionTitle from './InstitutionTitle';
import BankDetails from './BankDetails'

const Main = styled.div`
  .content {
    padding: 20px;
    background-color: #ffffff;
  }
`

const DetailedView = ({ selectedInstitution, handleRefetch, handleCancelModal }) => {
  const [cities, setCities] = useState([])
  const [specialization, setSpecialization] = useState([])
  const [reload, setReload] = useState(true)
  const [loading, setLoading] = useState(false)
  const [picLoading, setPicLoading] = useState(false)

  useEffect(() => {

    ApiCalls.getAvailableCities().then(response => {
      setCities(response.data.data.city)
    })
    ApiCalls.getSpecialisationList().then(response => {
      console.log(response.data.data)
      setSpecialization(response.data.data)
    })
  }, [])

  function saveProfileInfo(profileValues) {
    setLoading(true)
    ApiCalls.updateInstitution(profileValues)
      .then(response => {
        if (response.data.status === 1) {
          handleRefetch()
          message.success('Hospital Profile Updated Successfully')
          setLoading(false)
          handleCancelModal()
        }
      })
      .catch(err => {
        message.error('Hospital Profile Update Unsuccessful')
        setLoading(false)
      })
  }

  function updateProfilePicture(file) {
    setPicLoading(true)
    ApiCalls.updateProfilePicture(file)
      .then(res => {
        if (res.data.status === 1) {
          message.success(res.data.data)
          handleRefetch()
          setPicLoading(false)
        }
      })
      .catch(err => {
        console.log(err)
        message.error('Updation failed')
        setPicLoading(false)
      })
  }
  return (
    <>
      <Main>
        <div className='content'>
          <InstitutionTitle
            institution={selectedInstitution}
            updateProfilePicture={updateProfilePicture}
            picLoading={picLoading}
          />
          <Divider />
          <ProfileInfo
            institution={selectedInstitution}
            cities={cities}
            specialization={specialization}
            saveProfileInfo={saveProfileInfo}
            loading={loading}
          />
          {selectedInstitution.userType == "hospitalAdmin" &&
            <BankDetails
              institution={selectedInstitution}
            />
          }
        </div>
      </Main>
    </>
  )
}

export default DetailedView
