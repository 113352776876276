import React, { useState, useEffect } from 'react';
import { Row, Form, Col, Input, Button, message, Divider, Upload, Select } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import CustomText from '../../Components/CustomText';
import styled from 'styled-components';
import ApiCalls from '../../apis/ApiCalls';

const StyledCol = styled(Col)`
  .ant-picker-input > input {
    font-size: 14px;
    color: #5472a3;
  }

  .ant-input:placeholder-shown {
    border-radius: 5px;
  }

  .ant-form-item-label > label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    padding-left: 15px;
  }

  .ant-form-item-has-error .ant-form-item-explain,
  .ant-form-item-has-error .ant-form-item-split {
    font-family: 'Montserrat', sans-serif;
  }
`;

const ButtonWrapper = styled.div`
  font-family: Montserrat;
  font-style: normal;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  .ant-btn {
    border: none;
    border-radius: 8px;
    height: 40px;
    background-color: #e79c23;
    border-color: #e79c23;
    width: 150px;
  }
`;

const UploadButtonContainer = styled.div`
  .ant-btn {
    border: none;
  }
  .ant-btn-primary {
    background-color: #e4e4e4;
    border: none;
    width: 150px;
    color: #9d9d9d;
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }
`;

function AddBanners({ onCancel, institutionType, selectedItem, handleRefetch, specializationList }) {
    const [form] = Form.useForm();
    let formLayout = 'vertical';
    const [loading, setLoading] = useState(false);
    const [selectedSpecialization, setSelectedSpecialization] = useState(null);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [specializationId, setSpecializationId] = useState(null)
    const [imagePreview, setImagePreview] = useState(null);

    useEffect(() => {
        if (selectedItem) {
            form.setFieldsValue(selectedItem);
        }
    }, [selectedItem]);

    const onFinish = (model) => {
        if (institutionType.toLowerCase() === "banner content") {
            model.type = "banner";
        } else if (institutionType.toLowerCase() === "footer content") {
            model.type = "footer";
        } else {
            model.type = "image"
        }

        if (selectedItem) {
            model.id = selectedItem._id
        }

        const formData = new FormData();
        if (uploadedFile) {
            if (["image/jpg", "image/jpeg", "image/png"].includes(uploadedFile.type)) {
                formData.append("bannerImage", uploadedFile);
                formData.append("type", "image")
            } else {
                message.error("Image should be of the type jpeg or png");
            }
        }
        if (selectedItem) {
            formData.append("id", selectedItem._id)
            formData.append("category", selectedItem.category)
            formData.append('categoryId', selectedItem.categoryId)
            if (!uploadedFile && selectedItem.type == "image") {
                formData.append("url", selectedItem.url)
                formData.append("type", "image");
            }
        }
        if (selectedSpecialization) {
            formData.delete('category');
            formData.delete('categoryId');
            formData.append('categoryId', specializationId)
            formData.append("category", selectedSpecialization);
        }

        const apiCall = selectedItem ? ApiCalls.updateBannerData(institutionType.toLowerCase() === "banner image" ? formData : model) : ApiCalls.addBannerData(institutionType.toLowerCase() === "banner image" ? formData : model);

        setLoading(true);
        apiCall
            .then(res => {
                if (res.data.status === 1) {
                    onCancel(true);
                    handleRefetch(true);
                    message.success('Operation successful');
                }
            })
            .catch(err => {
                message.error('Operation failed');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleSpecializationChange = (value) => {
        setSelectedSpecialization(value);
        specializationList.filter((data) => {
            if (data.name == value) {
                setSpecializationId(data._id)
            }
        })
    };

    const handleFileChange = (info) => {
        if (info.file) {
            setUploadedFile(info.file)
        }
        const file = info.file
        if (file instanceof Blob) {
            setUploadedFile(file);
            const reader = new FileReader();
            reader.onload = (e) => {
                setImagePreview(e.target.result);
            };
            reader.readAsDataURL(file);
        } else {
            message.error("Selected file is not valid.");
        }
    };

    let formItem = null;

    switch (institutionType.toLowerCase()) {
        case 'banner content':
            formItem = (
                <Form.Item
                    name='bannerContent'
                    rules={[
                        {
                            required: true,
                            message: 'Banner Text is required',
                        },
                    ]}
                    label='Banner Text'
                >
                    <Input placeholder='Banner Text' size='large' />
                </Form.Item>
            );
            break;
        case 'footer content':
            formItem = (
                <Form.Item
                    name='footerContent'
                    rules={[
                        {
                            required: true,
                            message: 'Footer Text is required',
                        },
                    ]}
                    label='Footer Text'
                >
                    <Input placeholder='Footer Text' size='large' />
                </Form.Item>
            );
            break;
        case 'banner image':
            formItem = (
                <UploadButtonContainer>
                    <Form.Item
                        name="category"
                        label="Specialization"
                        rules={[{ required: true, message: 'Please select a specialization!' }]}
                    >
                        <Select
                            placeholder="Select Specialization"
                            size='large'
                            onChange={handleSpecializationChange}
                        // value={selectedItem.category}
                        >
                            {specializationList && specializationList.map((specialization, index) => (
                                <Select.Option key={specialization.name} value={specialization.name} onClick={() => { handleSpecializationChange(specialization) }}>
                                    {specialization.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    {(imagePreview || selectedItem?.url) && <img style={{ height: '150px' }} src={imagePreview || selectedItem?.url} alt="Preview" />}
                    <Upload
                        name="file"
                        showUploadList={false}
                        customRequest={(options) => {
                            handleFileChange(options);
                        }}
                    >
                        <Button
                            type="primary"
                            style={{ marginLeft: "30px", color: "#530808a8" }}
                        >
                            + Upload Image
                        </Button>
                    </Upload>
                </UploadButtonContainer>
            );
            break;
        default:
            formItem = null;
            break;
    }

    return (
        <>
            <Row justify="start" gutter={8} align="middle">
                <Col>
                    <PlusCircleFilled style={{ fontSize: '30px', fontWeight: 'bold', color: 'lightgrey' }} />
                </Col>
                <Col>
                    <CustomText size="16px" fontWeight="bold">
                        {selectedItem ? 'Update' : 'Add'} {`${institutionType}`}
                    </CustomText>
                </Col>
            </Row>
            <Divider />
            <Row justify='center'>
                <Form
                    layout={formLayout}
                    form={form}
                    style={{ width: '90%' }}
                    onFinish={onFinish}
                    initialValues={{ bannerContent: '', footerContent: '', ...selectedItem }}
                >
                    <Row>
                        <StyledCol span={24}>
                            {formItem}
                        </StyledCol>
                    </Row>
                    {/* {institutionType.toLowerCase() !== "banner image" && */}
                    <Row justify='end'>
                        <Col span={24}>
                            <Form.Item>
                                <ButtonWrapper>
                                    <Button style={{ color: 'orange', background: 'white', border: '1px solid' }} onClick={() => { onCancel(true) }}>
                                        Cancel
                                    </Button>
                                    <Button type='primary' htmlType='submit' loading={loading}>
                                        {selectedItem ? 'Update' : 'Submit'}
                                    </Button>
                                </ButtonWrapper>
                            </Form.Item>
                        </Col>
                    </Row>
                    {/* } */}
                </Form>
            </Row>
        </>
    );
}

export default AddBanners;
