import React from "react";

function ManagerInstitutionIcon({ height = "512", width = "512", active }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 834.35 834.34"
    >
      {active ? (
        <>
          <path
            fill="#efa322"
            d="M41.71 389.37a13.9 13.9 0 00-13.9 13.9v389.37a13.9 13.9 0 0027.81 0V403.27a13.9 13.9 0 00-13.91-13.9z"
            className="a"
          ></path>
          <path
            fill="#5472a3"
            d="M389.36 278.12v41.69H445v-41.69a27.82 27.82 0 0127.81-27.83h41.72v-55.62H472.8a27.8 27.8 0 01-27.8-27.8v-41.72h-55.64v41.73a27.8 27.8 0 01-27.81 27.8h-41.72v55.62h41.72a27.83 27.83 0 0127.81 27.82z"
            className="b"
          ></path>
          <path
            fill="#efa322"
            d="M792.63 500.59a13.93 13.93 0 00-13.91 13.93v278.12a13.91 13.91 0 0027.81 0V514.52a13.93 13.93 0 00-13.9-13.93zM250.31 695.29a13.9 13.9 0 0013.9 13.9h486.7V584h-500.6z"
            className="a"
          ></path>
          <path
            fill="#c8881d"
            d="M792.63 472.79a41.73 41.73 0 00-41.72 41.73v41.73H301a48.38 48.38 0 00-43.79-69.55h-97.29a48.38 48.38 0 00-43.79 69.55h-32.7v-153a41.72 41.72 0 10-83.43 0v389.39a41.62 41.62 0 0080.88 13.9h672.6a41.62 41.62 0 0080.88-13.9V514.52a41.73 41.73 0 00-41.73-41.73zm-737 319.85a13.9 13.9 0 01-27.81 0V403.27a13.9 13.9 0 0127.81 0zm104.3-278.12h97.34a20.86 20.86 0 010 41.73h-97.35a20.86 20.86 0 010-41.73zm591 264.19H83.43V584h139.06v111.29A41.72 41.72 0 00264.21 737h486.7zm0-69.52H264.21a13.9 13.9 0 01-13.9-13.9V584h500.6zm55.62 83.45a13.91 13.91 0 01-27.81 0V514.52a13.91 13.91 0 1127.81 0z"
            className="c"
          ></path>
          <path
            fill="none"
            d="M519.42 164.07H475.6v-43.84A29.22 29.22 0 00446.39 91H388a29.23 29.23 0 00-29.22 29.23v43.84h-43.85a29.2 29.2 0 00-29.22 29.2v58.42a29.22 29.22 0 0029.22 29.23h43.82v43.81A29.25 29.25 0 00388 354h58.43a29.24 29.24 0 0029.21-29.23v-43.85h43.82a29.22 29.22 0 0029.21-29.23v-58.42a29.2 29.2 0 00-29.25-29.2z"
            className="d"
          ></path>
          <path
            fill="#efa322"
            d="M417.17 27.8c-107.47.12-194.56 87.2-194.68 194.7s87.16 194.67 194.68 194.67S611.86 330 611.86 222.5 524.69 27.8 417.17 27.8zm131.45 223.9a29.22 29.22 0 01-29.21 29.23H475.6v43.81A29.24 29.24 0 01446.39 354H388a29.25 29.25 0 01-29.22-29.23v-43.85h-43.85a29.22 29.22 0 01-29.22-29.23v-58.42a29.2 29.2 0 0129.22-29.2h43.82v-43.84A29.23 29.23 0 01388 91h58.43a29.22 29.22 0 0129.21 29.23v43.84h43.82a29.2 29.2 0 0129.21 29.2z"
            className="a"
          ></path>
          <path
            fill="#c8881d"
            d="M417.17 0C294.29 0 194.68 99.62 194.68 222.5S294.36 444.84 417.17 445c122.88 0 222.49-99.62 222.49-222.5S540.05 0 417.17 0zm0 417.17c-107.52 0-194.68-87.15-194.68-194.67S309.7 27.92 417.17 27.8 611.86 115 611.86 222.5s-87.17 194.67-194.69 194.67z"
            className="c"
          ></path>
        </>
      ) : (
        <>
          <path
            fill="#6a6a6a"
            d="M792.63 472.8a41.72 41.72 0 00-41.72 41.72v41.72H301a48.17 48.17 0 004.88-20.86 48.67 48.67 0 00-48.67-48.67h-97.29a48.67 48.67 0 00-48.67 48.67 48.17 48.17 0 004.88 20.86H83.44v-153a41.72 41.72 0 00-83.44 0v389.39a41.62 41.62 0 0080.88 13.91h672.6a41.62 41.62 0 0080.88-13.91V514.52a41.72 41.72 0 00-41.72-41.72zm-41.71 236.4H264.21a13.91 13.91 0 01-13.91-13.91V584h500.62zM139.06 535.37a20.86 20.86 0 0120.86-20.86h97.34a20.86 20.86 0 010 41.72h-97.34a20.86 20.86 0 01-20.86-20.86zM41.72 806.54a13.91 13.91 0 01-13.91-13.91V403.27a13.91 13.91 0 0127.81 0v389.36a13.91 13.91 0 01-13.9 13.91zM83.44 584h139.05v111.29A41.72 41.72 0 00264.21 737h486.7v41.72H83.44zm723.1 208.59a13.91 13.91 0 11-27.81 0V514.52a13.91 13.91 0 0127.81 0z"
            className="a"
          ></path>
          <path
            fill="#6a6a6a"
            d="M417.18 445c122.88 0 222.49-99.61 222.49-222.49S540.05 0 417.18 0s-222.5 99.61-222.5 222.49S294.36 444.84 417.18 445zm0-417.18c107.52 0 194.68 87.16 194.68 194.68s-87.17 194.68-194.68 194.68S222.49 330 222.49 222.49 309.7 27.93 417.18 27.81z"
            className="a"
          ></path>
          <path
            fill="#6a6a6a"
            d="M319.83 278.12h41.72v41.72a27.81 27.81 0 0027.81 27.81H445a27.81 27.81 0 0027.81-27.81v-41.72h41.72a27.81 27.81 0 0027.81-27.81v-55.63a27.81 27.81 0 00-27.81-27.81H472.8v-41.72A27.81 27.81 0 00445 97.34h-55.64a27.81 27.81 0 00-27.81 27.81v41.72h-41.72A27.81 27.81 0 00292 194.68v55.62a27.81 27.81 0 0027.83 27.82zm0-83.44h41.72a27.81 27.81 0 0027.81-27.81v-41.72H445v41.72a27.81 27.81 0 0027.81 27.81h41.72v55.62H472.8a27.81 27.81 0 00-27.8 27.82v41.72h-55.64v-41.72a27.81 27.81 0 00-27.81-27.81h-41.72z"
            className="a"
          ></path>
        </>
      )}
    </svg>
  );
}

export default ManagerInstitutionIcon;
