import { Column } from "@ant-design/plots";
import { Select, Spin } from "antd";
import styled from "styled-components";

export const Main = styled.div`
  padding: 0 5%;
  font-family: "Montserrat", sans-serif;
`;

export const StyledColumn = styled(Column)`
  margin-top: 100px;
`;

export const StyledSelect = styled(Select)`
  width: 120px;
  margin-right: 30px;
`;

export const FilterWrapper = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  float: right;
`;

export const StyledHead = styled.h3`
  font-family: "Montserrat", sans-serif;
  margin-bottom: 1rem;
  color: #5472a3;
  font-weight: bold;
  font-size: 22px;
  line-height: 35px;
  padding-left: 8px;
  margin-top: ${props => (props.type === "Doctor" ? '0px' : '50px')};
`;

export const StyledSpin = styled(Spin)`
  position: absolute;
  left: 50%;
  top: 100;
`;

export const BarWrapper = styled.div`
  position: relative;
`;

export const NoData = styled.p`
  position: absolute;
  left: 40%;
  top: 50%;
  color: #5472a3;
  font-size: 18px;
`;