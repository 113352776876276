import React from "react";
import ProfileAvatar from "../../../../Components/ProfileAvatar";
import CustomText from "../../../../Components/CustomText";
import moment from "moment";
import { Row, Col } from "antd";
import { UserOutlined } from "@ant-design/icons";
import styled from "styled-components";

const SpecializationCard = ({ specializationName = "General Practice" }) => {
  const CardWrapper = styled.div`
    align-items: center;
    height: 50px;
    min-width: 100px;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    padding: 16px;
    margin: 15px 0;
    margin-right: 30px;
    .header-wrapper {
      border-bottom: 1px solid #d9d9d9;
      padding-bottom: 16px;
    }
    .content-wrapper {
      & > div {
        margin: 8px;
      }
    }
  `;
  return (
    <CardWrapper>
      <CustomText fontWeight="bold">{specializationName}</CustomText>
    </CardWrapper>
  );
};

export default SpecializationCard;
