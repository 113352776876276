import React, { useState, useEffect } from "react";
import { Row } from "antd";
import ApiCalls from "../../apis/ApiCalls";
import { CreatePlanWrapper, Title, Label, StyledSpin } from "./styledComponents";

const PlanDetails = ({ subscriptionId }) => {
  const [loading, setLoading] = useState(false);
  const [planDetails, setPlanDetails] = useState({});

  const getSubscriptionDetails = async() => {
    setLoading(true);
    try {
      let { data } = await ApiCalls.getSingleSubscriptionDetails(subscriptionId);
      if(data.data) {
        setPlanDetails({
          ...data.data
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getSubscriptionDetails();
  }, []);

  return (
    <CreatePlanWrapper>

      {/* Title */}
      <Title>Plan Details</Title>

      {loading ? (
        <StyledSpin />
      ) : (
        <>

          {/* Plan Name */}
          <Row>
            <Label fontWeight={600}>Plan Name&emsp;:&emsp;</Label>
            <Label>{planDetails.planName}</Label>
          </Row>

          {/* Plan Type */}
          <Row>
            <Label fontWeight={600}>Plan Type&emsp;:&emsp;</Label>
            <Label>{planDetails.planType}</Label>
          </Row>

          {/* Plan Price */}
          <Row>
            <Label fontWeight={600}>Plan Price&emsp;:&emsp;</Label>
            <Label>₹ {planDetails.planPrice}</Label>
          </Row>

          {/* Subscription Rank */}
          <Row>
            <Label fontWeight={600}>Subscription Rank&emsp;:&emsp;</Label>
            <Label>{planDetails.subscriptionRank}</Label>
          </Row>

          {/* Doctor Count */}
          <Row>
            <Label fontWeight={600}>Therapist Count&emsp;:&emsp;</Label>
            <Label>{planDetails.doctorCount}</Label>
          </Row>

          {/* Discount Percentage */}
          <Row>
            <Label fontWeight={600}>Discount Percentage&emsp;:&emsp;</Label>
            <Label>{planDetails.discountPercentage}</Label>
          </Row>

          {/* Plan duration */}
          <Row>
            <Label fontWeight={600}>Plan Duration&emsp;:&emsp;</Label>
            <Label>{planDetails.planDuration}</Label>
          </Row>

          {planDetails?.regionDetails ? (
            <>
              {/* Country name */}
              <Row>
                <Label fontWeight={600}>Country Name&emsp;:&emsp;</Label>
                <Label>{planDetails?.regionDetails?.countryName}</Label>
              </Row>

              {/* Country code */}
              <Row>
                <Label fontWeight={600}>Country Code&emsp;:&emsp;</Label>
                <Label>{planDetails?.regionDetails?.countryCode}</Label>
              </Row>

              {/* Country Currency */}
              <Row>
                <Label fontWeight={600}>Country Currency&emsp;:&emsp;</Label>
                <Label>{planDetails?.regionDetails?.countryCurrency}</Label>
              </Row>
            </>
          ) : null}
          
        </>
      )}

    </CreatePlanWrapper>
  );
}

export default PlanDetails;