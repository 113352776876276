import React from "react";
import { Row, Col, message } from "antd";
import styled from "styled-components";
import LoginSection from "./LoginSection";
import ImageSection from "./ImageSection";
import ApiCalls from "../apis/ApiCalls";
import storage from "../storage";
import reduxActions from "../redux-store/actions/index";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { useState } from "react";

const CustomColumn = styled(Col)`
  @media (max-width: 767px) {
    display: none;
  }
`;

const SignUp = (props) => {
  const [loading, setLoading] = useState(false);

  const login = (values) => {
    setLoading(true);
    ApiCalls.login(values)
      .then((response) => {
        if (response.data.status === 1) {
          setLoading(false);
          message.success("Successfully Logged In");
          storage.authToken.setItem(response.data.data.authToken);
          storage.user.setItem(response.data.data.userType);

          props.dispatch(
            reduxActions.authActions.setAuthToken(response.data.data.authToken)
          );
          props.dispatch(
            reduxActions.authActions.setUserData(response.data.data.userType)
          );
          props.history.push("/base/home/dashboard");
        } else {
          message.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error)
        message.error(
          error.response
            ? error.response.data.data.message
            : "No Response From Server",
          5
        );
        
        setLoading(false);
      });
  };

  return (
    <div>
      <div>
        <Row>
          <Col
            sm={{ span: 24 }}
            xs={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}
          >
            <LoginSection login={login} loading={loading} />
          </Col>
          <CustomColumn
            sm={{ span: 24 }}
            xs={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}
          >
            <ImageSection />
          </CustomColumn>
        </Row>
      </div>
    </div>
  );
};

const mapStateToProps = () => {
  return {};
};

export default compose(connect(mapStateToProps), withRouter)(SignUp);
