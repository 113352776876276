import { Card, Tabs, Spin, Pagination } from "antd";
import styled from "styled-components";

export const Main = styled.div`
  padding: 0 5%;
  font-family: "Montserrat", sans-serif;
`;

export const CustomTabs = styled(Tabs)`
  width: 100%;
  .ant-tabs-nav {
    width: 100%;
    margin: 0;
    & > div {
      width: 100%;
    }
  }
  .ant-tabs-tab-active {
    color: #3e8afa;
  }
  .ant-tabs-tab:hover {
    color: #1890ff;
  }
  .ant-tabs-tab {
    width: 250px;
    justify-content: center;
    font-weight: 600;
  }
  .tabs-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    svg {
      margin-right: 8px;
    }
  }
  .ant-tabs-ink-bar {
    height: 3px;
  }
`;

export const StyledCard = styled(Card)`
  width: 100%;
`;

export const StyledDiv = styled.div`
  background-color: white;
  padding: 0 2%;
`;

export const CardsWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
`;

export const StyledSpin = styled(Spin)`
  display: flex;
  justify-content: center;
  margin-top: 100px;
`;

export const StyledPagination = styled(Pagination)`
  margin: 0 auto;
  margin-top: 10px;
`;