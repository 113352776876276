import React, { useEffect } from "react";
import { Input, message } from "antd";
import { StyledForm, StyledModal } from "../MedicineLibrary/styledComponents";

const ManageSpecializationForm = ({
  selectedValue,
  showSpecializationModal,
  setShowSpecializationModal,
  onFinish,
}) => {
  const [form] = StyledForm.useForm();

  useEffect(() => {
    if (selectedValue) form.setFieldsValue({ ...selectedValue });
    else form.resetFields();
  }, [showSpecializationModal]);

  return (
    <>
      <StyledModal
        visible={showSpecializationModal}
        closable={false}
        title={`${selectedValue ? "Edit" : "Add New"} Specialization`}
        centered={true}
        onCancel={() => setShowSpecializationModal(false)}
        onOk={() => form.submit()}
        key={showSpecializationModal}
      >
        <StyledForm form={form} onFinish={onFinish}>
          <StyledForm.Item
            name="specializationName"
            label="Specialization"
            rules={[
              {
                required: true,
                message: "Specialization name is required",
              },
              { max: 60, message: "Character limit is 60" },
            ]}
          >
            <Input />
          </StyledForm.Item>
        </StyledForm>
      </StyledModal>
    </>
  );
};

export default ManageSpecializationForm;
