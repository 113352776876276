import React, { useEffect, useState } from "react";
import { Modal, Spin, Tabs, Col, message } from "antd";
import CustomLayout from "../../Components/CustomLayout";
import EmptyComponent from "../../Components/EmptyComponent";
import CompanyCard from "./CompanyCard";
import CompanyDetails from "./CompanyDetails";
import SelectedDoctors from './SelectedDoctors';
import Employees from './Employees';
import ApiCalls from "../../apis/ApiCalls";
import { 
  Main, 
  CardsWrapper, 
  StyledSpin, 
  Label, 
  AlignedRow, 
  StyledCard, 
  StyledDiv, 
  CustomTabs,
  RejectButton,
  StyledTextArea,
  StyledPagination
} from "./styledComponents";

const { TabPane } = Tabs;

const ManageCompanies = () => {
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [detailsModal, setDetailsModal] = useState({ show: false, modalType: null, company: null });
  const [approveLoading, setApproveLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [companyRejectionReason, setCompanyRejectionReason] = useState('');
  const [itemCount, setItemCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  // Get companies
  const getAllCompanies = async(currentPage) => {
    try {
      setLoading(true);
      let { data } = await ApiCalls.getAllCompanies(activeTab, currentPage);
      if(data?.data?.data?.length > 0) {
        setCompanies([...data.data.data]);
        if(data?.data?.count) {
          setItemCount(data?.data?.count);
        }
      } else {
        setCompanies([]);
      }
      setLoading(false);
    } catch (error) {
      setCompanies([]);
      setLoading(false);
    }
  }

  useEffect(() => {
    // Get companies list
    setCurrentPage(1);
    getAllCompanies(0);
  }, [activeTab]);

  useEffect(() => {
    getAllCompanies(currentPage - 1);
  }, [currentPage]);

  // close modal
  const closeModal = (refresh = false) => {
    if(refresh) {
      setCurrentPage(1);
      getAllCompanies(0);
    }
    setDetailsModal({ show: false, modalType: null, company: null });
  }

  // Confirm approve company
  const confirmApprove = async() => {
    setApproveLoading(true);
    let data = {
      companyApprovalStatus: '1'
    }
    ApiCalls.updateCompanyStatus(detailsModal.company?._id, data)
      .then(res => {
        closeModal(true);
        setApproveLoading(false);
      })
      .catch(error => {
        if(error?.response?.data?.data?.message) {
          message.error(error?.response?.data?.data?.message);
        }
        setApproveLoading(false);
      });
  }

  // Approve company
  const approveCompany = (company) => {
    setDetailsModal({ show: true, modalType: 'approve', company });
  }

  // Confirm reject company
  const confirmReject = async() => {
    setApproveLoading(true);
    let data = {
      companyApprovalStatus: '2',
      companyRejectionReason
    }
    ApiCalls.updateCompanyStatus(detailsModal.company?._id, data)
      .then(res => {
        closeModal(true);
        setApproveLoading(false);
        setCompanyRejectionReason('');
      })
      .catch(error => {
        if(error?.response?.data?.data?.message) {
          message.error(error?.response?.data?.data?.message);
        }
        setApproveLoading(false);
      });
  }

  // Reject company
  const rejectCompany = (company) => {
    setDetailsModal({ show: true, modalType: 'reject', company });
  }

  // view company details
  const viewCompanyDetails = (company) => {
    setDetailsModal({ show: true, modalType: 'details', company });
  }

  // View company selected doctors
  const viewSelectedDoctors = (company) => {
    setDetailsModal({ show: true, modalType: 'selectedDoctors', company });
  }

  // View employees in company
  const viewEmployees = (company) => {
    setDetailsModal({ show: true, modalType: 'employees', company });
  }

  return (
    <Main>
      <CustomLayout
        mainTitle="Manage Companies"
        makePaddingZero={true}
      />

      {/* Tabs */}
      <StyledCard>
        <Col span={24}>
          <StyledDiv>
            <CustomTabs
              defaultActiveKey="0"
              size="large"
              onChange={setActiveTab}
            >
              <TabPane
                tab={<div className="tabs-wrapper">Pending Approval</div>}
                key="0"
              />
              <TabPane
                tab={<div className="tabs-wrapper">Accepted</div>}
                key="1"
              />
              <TabPane
                tab={<div className="tabs-wrapper">Rejected</div>}
                key="2"
              />
            </CustomTabs>
          </StyledDiv>
        </Col>
      </StyledCard>

      {loading ? (
        <StyledSpin spinning={true} size="large" /> 
      ) : (
        <CardsWrapper>
          {companies.length === 0 ? (
            <EmptyComponent message="No data found" />
          ) : (
            <>

              {/* Company list */}
              <CompanyCard 
                data={companies} 
                approveCompany={approveCompany} 
                rejectCompany={rejectCompany}
                viewCompanyDetails={viewCompanyDetails} 
                viewSelectedDoctors={viewSelectedDoctors}
                viewEmployees={viewEmployees}
              />

              {/* Pagination */}
              <StyledPagination 
                current={currentPage} 
                pageSize={10} 
                total={itemCount} 
                onChange={page => setCurrentPage(page)} 
              />

            </>
          )}
        </CardsWrapper>
      )}

      {/* Modal */}
      <Modal
        visible={detailsModal.show}
        onCancel={() => closeModal(false)}
        footer={false}
        width={
          detailsModal.modalType === "details" 
          ? "50vw" 
          : (detailsModal.modalType === "selectedDoctors" || detailsModal.modalType === "employees")
          ? "72%"
          : detailsModal.modalType === "approve" 
          ? "25rem" 
          : "30rem"
        }
        style={{ 
          maxWidth: 
            detailsModal.modalType === 'details' 
            ? '50vw' 
            : (detailsModal.modalType === "selectedDoctors" || detailsModal.modalType === "employees") 
            ? "72%" 
            : '45rem' 
          }}
        centered={true}
        destroyOnClose={true}
      >
        {detailsModal.modalType === 'details' ? (

          // Company details
          <CompanyDetails company={detailsModal.company} />

        ) : detailsModal.modalType === "selectedDoctors" ? (

          // Company selected doctors
          <SelectedDoctors company={detailsModal.company} />

        ) : detailsModal.modalType === "employees" ? (
          <Employees company={detailsModal.company} />
        ) : detailsModal.modalType === "approve" ? (

          // Approve company
          <div>
            <Label fontWeight={600} marginTop="20px">You're about to approve the company, Are you sure?</Label>
            <AlignedRow>
              <Label marginRight="50px" color="#e60000" hover onClick={confirmApprove}>
                Yes {approveLoading ? <Spin /> : null}
              </Label>
              <Label color="#0000cc" hover onClick={() => closeModal(false)}>No</Label>
            </AlignedRow>
          </div>

        ) : (

          // Reject company
          <div>
            <Label fontWeight={600} marginTop="20px">You're about to reject the company, Are you sure?</Label>
            <StyledTextArea
              placeholder='Company rejection reason'
              value={companyRejectionReason}
              onChange={e => setCompanyRejectionReason(e.target.value)}
            />
            <AlignedRow>
              <RejectButton disabled={companyRejectionReason?.trim('')?.length === 0} onClick={confirmReject}>
                Reject company {approveLoading ? <Spin /> : null}
              </RejectButton>
            </AlignedRow>
          </div>

        )}
      </Modal>

    </Main>
  );
}

export default ManageCompanies;