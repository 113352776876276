import React, { useEffect, useState } from "react";
import { Divider, Row, Col, Spin, Modal } from "antd";
import styled from "styled-components";
import CustomText from "../../../Components/CustomText";
import PatientInfoCard from "./PatientInfoCard";
import FamilyMemberCard from "./PatientInfoCard/FamilyMemberCard";
import ApiCalls from "../../../apis/ApiCalls";
import CustomButton from "../../../Components/CustomButton";
import {
  StyledColumn,
  DoctorRow,
  StyledTable,
  StyledText,
  StyledButton
} from "../../manage-companies/styledComponents";
import { useHistory } from "react-router-dom";
import moment from "moment";

const SectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const DetailsContainer = styled.div`
  height: 700px;
  min-height: 700px;
  overflow-y: scroll;
  overflow-x: hidden;
`;
const LoadingWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default function DetailsModal({ id, details, fromAppointments }) {
  const [selectedPatient, setSelectedPatient] = useState({});
  const [loading, setLoading] = useState(true);
  const [formList, setFormList] = useState([]);
  const [detailsModal, setDetailsModal] = useState({ show: false, id: null });
  const history = useHistory();

  const fetchPatientDetails = async (id) => {

    let { data } = await ApiCalls.patientDetails(id);
    console.log(data);
    if (data?.data?.length > 0) {
      setSelectedPatient({ ...data.data[0] });
    }
    setLoading(false);
  }

  const fetchFormList = async (id) => {
    let { data } = await ApiCalls.fetchFormList(id);
    if (data?.data?.data?.length > 0) {
      setFormList(data.data.data)
    }
    setLoading(false);
  }

  useEffect(() => {
    if (fromAppointments) {
      fetchPatientDetails(id);
      fetchFormList(id);
    } else {
      fetchPatientDetails(details._id)
      fetchFormList(details._id);
    }
  }, []);

  const ViewMore = (details) => {
    setDetailsModal({ show: true, id: details._id });
  }

  const viewDetails = async (details) => {
    history.push(`/base/home/survay-form/form-details/${details._id}`)
  }

  if (loading)
    return (
      <DetailsContainer>
        <SectionContainer>
          <Col>
            <CustomText color="#000" size="16px" fontWeight="bold">
              Patient Details
            </CustomText>
          </Col>
          <Divider />
        </SectionContainer>
        <LoadingWrapper>
          <Spin />
        </LoadingWrapper>
      </DetailsContainer>
    );

  return (
    <div>
      <SectionContainer>
        <Col>
          <CustomText color="#000" size="16px" fontWeight="bold">
            Patient Details
          </CustomText>
        </Col>
      </SectionContainer>
      <DetailsContainer>
        <Divider />
        <Row gutter={16}>
          <Col span={12} style={{ marginBottom: "16px" }}>
            <CustomText color="grey" fontWeight="bold">
              Patient Information
            </CustomText>
            <PatientInfoCard patient={selectedPatient} />

            {selectedPatient?.familyMemberDetails?.length > 0 && (
              <>
                <CustomText color="grey" fontWeight="bold">
                  Family Member Information
                </CustomText>
                {selectedPatient?.familyMemberDetails?.map(fam => (
                  <FamilyMemberCard key={fam?._id} data={fam} />
                ))}
              </>
            )}
            <Divider style={{ margin: "10px" }} />


          </Col>

          {/* <Col span={12}>
          <CustomText color="grey" fontWeight="bold">
            Specialization
          </CustomText>
          <ConsultationInfoCard
            consultationInfo={selectedClaim.consultationInfo}
          />
        </Col>
        <Col span={12} style={{ marginBottom: "16px" }}>
          <CustomText color="grey" fontWeight="bold">
            Insurance Details
          </CustomText>
          <InsuranceInfoCard
            insuranceInfo={selectedClaim.insuranceCardDetails}
            transactionInfo={selectedClaim.transactionDetails}
          />
        </Col> */}
        </Row>
        <StyledTable>

          {/* Header */}
          {
            formList.length > 0 ?
           ( <DoctorRow style={{ marginLeft: "10px" }}>
            <StyledColumn header={true}>Patient Name</StyledColumn>
            <StyledColumn header={true}>Patient Email</StyledColumn>
            <StyledColumn header={true}>Created At</StyledColumn>
          </DoctorRow>
           ) : ( null )
          }
          

          {formList?.slice(0, 3).map((item) => (
            <DoctorRow key={item._id} style={{ marginLeft: "10px", width: "100%" }}>

              {/* patient name */}
              <StyledColumn>
                <StyledText type="name">{item.patientDetails.patientName}</StyledText>
              </StyledColumn>

              {/* patient name */}
              <StyledColumn>
                <StyledText type="name">{item.patientDetails.patientEmail}</StyledText>
              </StyledColumn>

              {/* form for category */}
              <StyledColumn>
                <StyledText type="formForCategory">{moment(item?.createdAt).format("HH:MM a, DD-MM-YYYY")}</StyledText>
              </StyledColumn>

              {/* View form details */}
              <StyledColumn>
                <StyledButton onClick={() => viewDetails(item)}>
                  <p>View Details</p>
                </StyledButton>
              </StyledColumn>
              <Divider style={{ width: "100%" }} />
            </DoctorRow>
          ))}

        </StyledTable>
        {
          formList.length > 3 ? <CustomButton onClick={() => ViewMore(details)}>View More</CustomButton> : null
        }

        <Modal
          visible={detailsModal.show}
          onCancel={() => setDetailsModal({ show: false, id: null })}
          footer={false}
          width={"75%"}
          centered={true}
          destroyOnClose={true}
        >
          <StyledTable>

            {/* Header */}
            <DoctorRow>
              <StyledColumn header={true}>Form Name</StyledColumn>
              <StyledColumn header={true}>Form For Category</StyledColumn>
            </DoctorRow>

            {formList?.map((item) => (
              <DoctorRow key={item._id}>

                {/* patient name */}
                <StyledColumn>
                  <StyledText type="name">{item?.formName}</StyledText>
                </StyledColumn>

                {/* form for category */}
                <StyledColumn>
                  <StyledText type="formForCategory">{item?.formForCategory}</StyledText>
                </StyledColumn>

                {/* View form details */}
                <StyledColumn>
                  <StyledButton onClick={() => viewDetails(item)}>
                    <p>View Details</p>
                  </StyledButton>
                </StyledColumn>

              </DoctorRow>
            ))}

          </StyledTable>
        </Modal>

      </DetailsContainer>
    </div>
  );
}
