import React from "react";

function ManagerFormIcon({ height = "512", width = "512", active }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 578.73 784.52"
    >
      {active ? (
        <>
          <path
            fill="none"
            d="M422.92 462.75H200.33c-12.29 0-22.26 10.26-22.26 23s10 23 22.26 23h222.59c12.29 0 22.26-10.29 22.26-23s-9.97-23-22.26-23zM422.92 554.66H200.33c-12.29 0-22.26 10.29-22.26 23s10 23 22.26 23h222.59c12.29 0 22.26-10.29 22.26-23s-9.97-23-22.26-23z"
            className="a"
          ></path>
          <ellipse
            fill="none"
            cx="111.3"
            cy="393.79"
            className="a"
            rx="22.26"
            ry="22.98"
          ></ellipse>
          <path
            fill="none"
            d="M111.3 554.66c-12.3 0-22.3 10.29-22.3 23.01s10 23 22.26 23 22.26-10.29 22.26-23-9.93-23.01-22.22-23.01zM111.3 462.75C99 462.75 89 473 89 485.73s10 23 22.26 23 22.26-10.29 22.26-23-9.93-22.98-22.22-22.98zM422.92 183.85h79.82L400.66 78.45v82.43c0 12.68 9.99 22.97 22.26 22.97zM178.07 393.79c0 12.69 10 23 22.26 23h222.59c12.29 0 22.26-10.29 22.26-23s-10-23-22.26-23H200.33c-12.33.02-22.26 10.31-22.26 23z"
            className="a"
          ></path>
          <path
            fill="#efa322"
            d="M356.14 46H66.78c-12.27 0-22.26 10.32-22.26 23v646.56c0 12.65 10 23 22.26 23H512c12.27 0 22.26-10.32 22.26-23V229.84H422.92c-36.82 0-66.77-30.94-66.77-69zM111.3 600.64c-12.3 0-22.3-10.29-22.3-22.97s10-23 22.26-23 22.26 10.29 22.26 23-9.93 22.97-22.22 22.97zm0-91.94C99 508.7 89 498.41 89 485.73s10-23 22.26-23 22.26 10.26 22.26 23-9.93 22.97-22.22 22.97zm0-91.94c-12.3 0-22.3-10.29-22.3-22.97s10-23 22.26-23 22.26 10.29 22.26 23-9.93 22.97-22.22 22.97zm311.62 183.88H200.33c-12.29 0-22.26-10.29-22.26-23s10-23 22.26-23h222.59c12.29 0 22.26 10.29 22.26 23s-9.97 23-22.26 23zm0-91.94H200.33c-12.29 0-22.26-10.29-22.26-23s10-23 22.26-23h222.59c12.29 0 22.26 10.26 22.26 23s-9.97 23-22.26 23zm0-137.89c12.29 0 22.26 10.29 22.26 23s-10 23-22.26 23H200.33c-12.29 0-22.26-10.29-22.26-23s10-23 22.26-23z"
            className="b"
          ></path>
          <path
            fill="#c8881d"
            d="M572.21 190.6L394.14 6.72A22.07 22.07 0 00378.4 0H66.78C30 0 0 30.94 0 68.93v646.63c0 38 30 69 66.78 69H512c36.82 0 66.77-30.94 66.77-69v-508.7a23.63 23.63 0 00-6.56-16.26zm-38 525c0 12.65-10 23-22.26 23H66.78c-12.27 0-22.26-10.32-22.26-23V68.93c0-12.65 10-23 22.26-23h289.36v114.94c0 38 30 69 66.77 69h111.3zM400.66 160.87V78.45l102.08 105.4h-79.82c-12.27 0-22.26-10.29-22.26-22.98z"
            className="c"
          ></path>
          <path
            fill="#5472a3"
            d="M178.07 393.79c0 12.69 10 23 22.26 23h222.59c12.29 0 22.26-10.29 22.26-23s-10-23-22.26-23H200.33c-12.33.02-22.26 10.31-22.26 23zM422.92 462.75H200.33c-12.29 0-22.26 10.26-22.26 23s10 23 22.26 23h222.59c12.29 0 22.26-10.29 22.26-23s-9.97-23-22.26-23zM422.92 554.66H200.33c-12.29 0-22.26 10.29-22.26 23s10 23 22.26 23h222.59c12.29 0 22.26-10.29 22.26-23s-9.97-23-22.26-23z"
            className="d"
          ></path>
          <ellipse
            fill="#5472a3"
            cx="111.3"
            cy="393.79"
            className="d"
            rx="22.26"
            ry="22.98"
          ></ellipse>
          <path
            fill="#5472a3"
            d="M111.3 462.75C99 462.75 89 473 89 485.73s10 23 22.26 23 22.26-10.29 22.26-23-9.93-22.98-22.22-22.98zM111.3 554.66c-12.3 0-22.3 10.29-22.3 23.01s10 23 22.26 23 22.26-10.29 22.26-23-9.93-23.01-22.22-23.01z"
            className="d"
          ></path>
        </>
      ) : (
        <>
          <path
            fill="#6a6a6a"
            d="M572.2 190.6L394.14 6.73A22.06 22.06 0 00378.4 0H66.78C30 0 0 30.93 0 69v646.57c0 38 30 69 66.78 69H512c36.82 0 66.78-30.93 66.78-69V206.86a23.6 23.6 0 00-6.58-16.26zM400.66 78.47l102.07 105.4h-79.81c-12.27 0-22.26-10.31-22.26-23zM512 738.55H66.78c-12.27 0-22.26-10.31-22.26-23V69c0-12.67 10-23 22.26-23h289.36v114.89c0 38 30 69 66.78 69h111.29v485.68c0 12.67-9.99 22.98-22.21 22.98z"
            className="a"
          ></path>
          <path
            fill="#6a6a6a"
            d="M422.92 370.81H200.33c-12.29 0-22.26 10.29-22.26 23s10 23 22.26 23h222.59c12.29 0 22.26-10.29 22.26-23s-9.97-23-22.26-23zM422.92 462.75H200.33c-12.29 0-22.26 10.29-22.26 23s10 23 22.26 23h222.59c12.29 0 22.26-10.29 22.26-23s-9.97-23-22.26-23zM422.92 554.68H200.33c-12.29 0-22.26 10.29-22.26 23s10 23 22.26 23h222.59c12.29 0 22.26-10.29 22.26-23s-9.97-23-22.26-23z"
            className="a"
          ></path>
          <ellipse
            fill="#6a6a6a"
            cx="111.29"
            cy="393.79"
            className="a"
            rx="22.26"
            ry="22.98"
          ></ellipse>
          <ellipse
            fill="#6a6a6a"
            cx="111.29"
            cy="485.73"
            className="a"
            rx="22.26"
            ry="22.98"
          ></ellipse>
          <ellipse
            fill="#6a6a6a"
            cx="111.29"
            cy="577.67"
            className="a"
            rx="22.26"
            ry="22.98"
          ></ellipse>
        </>
      )}
    </svg>
  );
}

export default ManagerFormIcon;
