import { DownloadOutlined } from '@ant-design/icons'
import { Card, Row,Col } from 'antd'
import React from 'react'
import styled from 'styled-components'
import CustomText from './CustomText'
import { API_SERVER } from '../apis/ServerConfig';
const FileCard = ({ name, url }) => {

    const Wrapper = styled(Card)`
    max-width:400px;
    background-color:#f0f2f5;
    `
    const handleDownload = () => {
        window.open(`${API_SERVER}/api/public/${url}`)
    }
    return (
        <Wrapper onClick={handleDownload} hoverable={true}>
            <Row gutter={24} align="middle">
                <Col>
                    <DownloadOutlined style={{fontSize:'32px'}}></DownloadOutlined>
                </Col>
                <Col>
                    <CustomText color="blue">
                        {name}
                    </CustomText>
                </Col>
            </Row>
        </Wrapper>
    )
}
export default FileCard