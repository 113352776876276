import React from 'react'
import styled from 'styled-components'
import { Typography, Card, Tabs, Col, Divider } from 'antd'
import { useEffect } from 'react'
import ProfileTitle from './ProfileTitle'
import ProfileInfo from './ProfileInfo'

const PatientDetailModal = ({
    selectedPatient = {},
    handleCancelModal,
    handleDeleteModalOpen,
}) => {

    const Main = styled.div`

    .content {
        padding:30px;
        background-color:#ffffff;
    }
    `
const handleDelete = ()=>{
    handleCancelModal()
    handleDeleteModalOpen(selectedPatient)
}




    return (<>
        <Main>
            <div className="content">
                <ProfileTitle
                    patient={selectedPatient}
                    handleDeleteModalOpen={handleDelete}
                />
                <Divider />
                <ProfileInfo doctor={selectedPatient} />
            </div>
        </Main>
    </>)
}

export default PatientDetailModal