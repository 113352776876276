import React from "react";
import { 
  StyledColumn, 
  StyledRow, 
  StyledTable, 
  StyledText, 
  StyledButton
} from "./styledComponents";

const CompanyCard = ({ 
  data,
  viewCompanyDetails, 
  approveCompany, 
  rejectCompany, 
  viewSelectedDoctors,
  viewEmployees 
}) => (
  <StyledTable>

    {/* Header */}
    <StyledRow>
      <StyledColumn header={true}>Company Name</StyledColumn>
      <StyledColumn header={true}>Company Email</StyledColumn>
      <StyledColumn header={true}>Company Category</StyledColumn>
    </StyledRow>

    {data?.map(item => (
      <StyledRow key={item._id}>

        {/* Company name */}
        <StyledColumn>
          <StyledText>{item?.companyName}</StyledText>
        </StyledColumn>

        {/* Company email */}
        <StyledColumn>
          <StyledText>{item?.companyEmail}</StyledText>
        </StyledColumn>

        {/* Company category */}
        <StyledColumn>
          <StyledText>{item?.companyCategory?.categoryName}</StyledText>
        </StyledColumn>

        {/* View company details */}
        <StyledColumn>
          <StyledButton onClick={() => viewCompanyDetails(item)}>
            <p>View Company Details</p>
          </StyledButton>
        </StyledColumn>

        {item?.companyApprovalStatus === "accepted" ? (
          <>

            {/* Selected Doctors */}
            <StyledColumn>
              <StyledButton onClick={() => viewSelectedDoctors(item)}>
                <p>Selected Therapists</p>
              </StyledButton>
            </StyledColumn>

            {/* Employees */}
            <StyledColumn>
              <StyledButton onClick={() => viewEmployees(item)}>
                <p>Employees</p>
              </StyledButton>
            </StyledColumn>

          </>
        ) : null}

        {(item?.companyApprovalStatus !== "accepted" && item?.companyApprovalStatus !== "rejected") ? (
          <>

            {/* Approve company */}
            <StyledColumn>
              <StyledButton onClick={() => approveCompany(item)}>
                <p>Approve Company</p>
              </StyledButton>
            </StyledColumn>

            {/* Reject company */}
            <StyledColumn>
              <StyledButton onClick={() => rejectCompany(item)} type="delete">
                <p>Reject Company</p>
              </StyledButton>
            </StyledColumn>

          </>
        ) : null}

      </StyledRow>
    ))}

  </StyledTable>
);

export default CompanyCard;