import React, { useState, useEffect } from "react";
import {
  StyledTable,
  StyledRow,
  StyledColumn,
  Main,
  PaginationContainer,
  PageFunctionContainer,
  ButtonsContainer,
  TabButton,
  StyledButtonsContainerForLastColumn,
} from "../MedicineLibrary/styledComponents";
import CustomLayout from "../../Components/CustomLayout";
import CustomText from "../../Components/CustomText";
import { LoadingOutlined } from "@ant-design/icons";
import ApiCalls from "../../apis/ApiCalls";
import { message, Pagination, Input, Button, Modal } from "antd";
import ManageServiceForm from "./ManageServiceForm";
import DeleteModal from "./DeleteModal";
import EmptyComponent from "../../Components/EmptyComponent";

const ManageServices = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showServiceModal, setShowServiceModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);

  const updateService = (values) => {
    const payload = {
      ...values,
      serviceId: selectedValue ? selectedValue._id : undefined,
    };
    (selectedValue
      ? ApiCalls.editService(payload)
      : ApiCalls.addService(payload)
    )
      .then((response) => {
        if (response.data.status) {
          setSelectedValue(null);
          setShowServiceModal(false);
          getServices();
          message.success(
            `${selectedValue ? "Edited" : "Added"} service successfully`
          );
        }
      })
      .catch((error) =>
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        )
      );
  };

  const getServices = () => {
    let params = {
      pageNo: currentPage,
      limit: 10,
      searchKey: searchValue,
    };
    setLoading(true);
    ApiCalls.fetchServices(params)
      .then((response) => {
        if (response.data.status) {
          setTotalCount(response.data.data.serviceCount);
          setServices(response.data.data.services);
          setLoading(false);
        }
      })
      .catch((err) => {
        setServices([]);
        setLoading(false);
      });
  };

  useEffect(() => {
    setCurrentPage(0);
  }, [searchValue]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getServices();
    }, 400);
    return () => {
      clearInterval(delayDebounceFn);
    };
  }, [searchValue, currentPage]);

  useEffect(() => {
    if (!showServiceModal) {
      setSelectedValue(null);
    }
  }, [showServiceModal]);

  useEffect(() => {
    if (!deleteModalVisibility) {
      setSelectedValue(null);
    }
  }, [deleteModalVisibility]);

  return (
    <Main>
      <CustomLayout
        mainTitle="Manage Services"
        makePaddingZero={true}
        headerComponent={
          <PageFunctionContainer>
            <Input
              autoFocus={true}
              placeholder="Search"
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              allowClear
            />
            <ButtonsContainer>
              <Button type="primary" onClick={() => setShowServiceModal(true)}>
                Add Service
              </Button>
            </ButtonsContainer>
          </PageFunctionContainer>
        }
      ></CustomLayout>
      <StyledTable>
        <StyledRow addedBy={"services"}>
          <StyledColumn header={true}>S.No</StyledColumn>
          <StyledColumn header={true}>Service</StyledColumn>
        </StyledRow>
        {services.length === 0 && !loading && (
          <EmptyComponent message={"No data found"} />
        )}
        {loading ? (
          <LoadingOutlined />
        ) : (
          services.map((item, count) => {
            return (
              <StyledRow id={item._id} addedBy={"services"}>
                <StyledColumn>{currentPage * 10 + count + 1}</StyledColumn>
                <StyledColumn>{item.serviceName}</StyledColumn>
                <StyledColumn center>
                  <Button
                    onClick={() => {
                      setSelectedValue(item);
                      setShowServiceModal(true);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    style={{ marginLeft: "30px" }}
                    onClick={() => {
                      setSelectedValue(item);
                      setDeleteModalVisibility(true);
                    }}
                  >
                    Delete
                  </Button>
                </StyledColumn>
              </StyledRow>
            );
          })
        )}
      </StyledTable>
      {totalCount > 10 && (
        <PaginationContainer>
          <Pagination
            size="large"
            pageSize={10}
            total={totalCount}
            current={currentPage + 1}
            onChange={(val) => setCurrentPage(val - 1)}
            showSizeChanger={false}
          />
        </PaginationContainer>
      )}

      <ManageServiceForm
        selectedValue={selectedValue}
        showServiceModal={showServiceModal}
        setShowServiceModal={setShowServiceModal}
        onFinish={updateService}
      />

      <Modal
        visible={deleteModalVisibility}
        onCancel={() => {
          setDeleteModalVisibility(false);
        }}
        footer={false}
        width={700}
        centered={true}
        destroyOnClose={true}
        title={
          <CustomText size="16" fontWeight="bold">
            Confirmation
          </CustomText>
        }
      >
        <DeleteModal
          selectedService={selectedValue}
          handleModalCancel={setDeleteModalVisibility}
          fetchServices={getServices}
        />
      </Modal>
    </Main>
  );
};

export default ManageServices;
