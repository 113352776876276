import React, { useState } from 'react'
import ReadOnlyInput from '../../../Components/ReadOnlyInput'
import { Col, Row, Divider } from 'antd'
import CustomText from '../../../Components/CustomText'
import moment from 'moment'
import { useEffect } from 'react'
import { API_SERVER } from '../../../apis/ServerConfig'
import { FileOutlined } from '@ant-design/icons'
import styled from 'styled-components'

const StyledMessage = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
`

const PatientInfo = ({ patientFile = {} }) => {
  const [reports, setReports] = useState([])
  useEffect(() => {
    if (Object.keys(patientFile).length === 0) {
      setReports([])
    } else {
      patientFile.allergies &&
        setReports(report => {
          return report.concat({
            title: 'Medical Allergies',
            content: patientFile.allergies.toString(),
            items: []
          })
        })
      patientFile.currentMedications &&
        setReports(report => {
          return report.concat({
            title: 'Current Medications',
            content: patientFile.currentMedications.toString(),
            items: []
          })
        })

      patientFile.medicalConditions &&
        setReports(report => {
          return report.concat({
            title: 'Medical Conditions',
            content: patientFile.medicalConditions.toString(),
            items: []
          })
        })
      patientFile.surgeries &&
        setReports(report => {
          const content = patientFile.surgeries.map(surgery => {
            return `${surgery.name} on ${surgery.month} ${surgery.year}`
          })
          return report.concat({
            title: 'Surgeries',
            content: content.toString(),
            items: []
          })
        })
      patientFile.medicalReports &&
        patientFile.medicalReports.length &&
        setReports(report => {
          return report.concat({
            title: 'Report Uploads ',
            content: '',
            items: patientFile.medicalReports
          })
        })
    }
  }, [])

  function getAge (birthday) {
    // birthday is a date
    console.log(moment(birthday))
    return moment(birthday)
      .fromNow({ withoutSuffix: true })
      .toString()
      .toUpperCase()
  }

  return (
    <div>
      <Row gutter={40} style={{ margin: '8px' }} justify='space-between'>
        <Col span={12}>
          {patientFile.gender ? (
            <ReadOnlyInput label='Gender' value={patientFile.gender} />
          ) : (
            <ReadOnlyInput label='Gender' value='Not Provided' />
          )}
        </Col>

        <Col span={12}>
          {patientFile.height > 0 ? (
            <ReadOnlyInput
              label='Height'
              value={patientFile.height}
              unit='Cm'
            />
          ) : (
            <ReadOnlyInput label='Height' value='Not Provided' />
          )}
        </Col>

        <Col span={12}>
          {patientFile.weight > 0 ? (
            <ReadOnlyInput
              label='Weight'
              value={patientFile.weight}
              unit='Kg'
            />
          ) : (
            <ReadOnlyInput label='Weight' value='Not Provided' />
          )}
        </Col>

        {/* </Row> */}
        {/* <Row gutter={40} style={{ margin: '8px' }}> */}

        <Col span={12}>
          {patientFile.dob ? (
            <ReadOnlyInput
              label='Date of Birth'
              value={moment(patientFile.dob).format('DD/MM/YYYY')}
            />
          ) : (
            <ReadOnlyInput label='Date of Birth' value='Not Provided' />
          )}
        </Col>
        {patientFile.dob && (
          <Col span={12}>
            <ReadOnlyInput label='Age' value={getAge(patientFile.dob)} />
          </Col>
        )}
      </Row>
      <Divider />
      <Row gutter={16} style={{ width: '100%', marginLeft: '8px' }}>
        {console.log(reports, 'reports')}
        {reports && reports.length > 0 ? (
          reports.map(report => (
            <Col
              span={7}
              style={{
                border: '1px solid #AEAEAE',
                height: '200px',
                padding: '16px',
                margin: '16px',
                overflowY: 'auto'
              }}
            >
              <CustomText color='black' size='13px' fontWeight='bold'>
                {report.title}
              </CustomText>
              <CustomText size='13px'>{report.content}</CustomText>
              {report.items.map(file => {
                return (
                  <div
                    onClick={() =>
                      window.open(`${API_SERVER}/api/public/${file.url}`)
                    }
                    style={{
                      display: 'flex',
                      margin: '8px auto 8px auto',
                      border: '1px solid #7C7C7C',
                      padding: '16px',
                      borderRadius: '5px',
                      cursor: 'pointer'
                    }}
                  >
                    <FileOutlined
                      style={{ fontSize: '24px', marginRight: '8px' }}
                    />
                    <CustomText color='black'>{file.name}</CustomText>
                  </div>
                )
              })}
            </Col>
          ))
        ) : (
          <div style={{ width: '100%' }}>
            <StyledMessage>No Reports Found</StyledMessage>
          </div>
        )}
      </Row>
    </div>

    // </Space>
  )
}
export default PatientInfo
