import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import EmptyComponent from '../../Components/EmptyComponent';
import DoctorCard from './DoctorCard';
import DetailsModal from '../ManageDoctors/DetailsModal';
import ApiCalls from "../../apis/ApiCalls";
import { CreateCompanyWrapper, Title, StyledSpin, CardsWrapper } from "./styledComponents";

const SelectedDoctors = ({ company }) => {
  const [loading, setLoading] = useState(false);
  const [doctorsList, setDoctorsList] = useState([]);
  const [detailsModal, setDetailsModal] = useState({ show: false, id: null });

  // Get company selected doctors
  const getCompanySelectedDoctors = async() => {
    setLoading(true);
    try {
      let { data } = await ApiCalls.getCompanySelectedProviders(company?._id);
      if(data?.data?.length > 0 && data?.status) {
        setDoctorsList(data.data);
      } else {
        setDoctorsList([]);
      }
      setLoading(false);
    } catch (error) {
      setDoctorsList([]);
      setLoading(false);
    }
  }

  useEffect(() => {
    getCompanySelectedDoctors();
  }, []);

  // View doctor details
  const viewDoctorDetails = (item) => {
    setDetailsModal({ show: true, id: item?._id });
  }

  return (
    <CreateCompanyWrapper>

      {/* Title */}
      <Title>Company Selected Therapists</Title>

      {loading ? (
        <StyledSpin />
      ) : (
        doctorsList?.length > 0 ? (

          // Doctors list
          <CardsWrapper>
            <DoctorCard data={doctorsList} viewDetails={viewDoctorDetails} />
          </CardsWrapper>

        ) : (
          <EmptyComponent message="No data found" />
        )
      )}

      <Modal
        visible={detailsModal.show}
        onCancel={() => setDetailsModal({ show: false, id: null })}
        footer={false}
        width={"72%"}
        centered={true}
        destroyOnClose={true}
      >
        <DetailsModal id={detailsModal.id} fromAppointments />
      </Modal>

    </CreateCompanyWrapper>
  );
}

export default SelectedDoctors;