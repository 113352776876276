import { Spin } from "antd";
import styled from "styled-components";

export const Main = styled.div`
  padding: 0 5%;
  font-family: "Montserrat", sans-serif;
`;

export const CardsWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
`;

export const StyledSpin = styled(Spin)`
  display: flex;
  justify-content: center;
  margin-top: 100px;
`;

export const CreateButton = styled.div`
  background-color: #5472a3;
  border-radius: 10px;
  padding: 10px 20px;
  width: fit-content;
  align-items: center;
  justify-content: center;
  float: ${({ type }) => type === "modalButton" ? 'right' : ''};
  margin-top: ${({ type }) => type === "modalButton" ? "20px" : ''};
  opacity: ${({ disabled }) => disabled ? 0.4 : 1};
  pointer-events: ${({ disabled }) => disabled ? 'none' : 'all'};

  &:hover {
    cursor: ${({ disabled }) => disabled ? "" : "pointer"};
    opacity: ${({ disabled }) => disabled ? 0.4 : 0.7};
  }
`;

export const ButtonTitle = styled.p`
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #FFFFFF;
  margin: 0px;
  padding: 0px;
`;

export const CreatePlanWrapper = styled.div`
  padding: 50px 50px 100px 50px;
`;

export const Label = styled.p`
  margin: 0px;
  padding: 0px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: ${({ fontWeight }) => fontWeight ?? 400};
  color: ${({ color }) => color ?? "#000000"};
  margin-bottom: 10px;
  margin-top: ${({ marginTop }) => marginTop ?? "10px"};
  margin-right: ${({ marginRight }) => marginRight ?? "0px"};

  &:hover {
    cursor: ${({ hover }) => hover ? "pointer" : ""};
    opacity: ${({ hover }) => hover ? 0.7 : 1}
  }
`;

export const Title = styled.p`
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  text-align: center;
`;

export const StyledMenuItem = styled.div`
  padding: 5px 10px;

  &:hover {
    background-color: lightblue;
    cursor: pointer;
  }
`;

export const StyledMenuItemText = styled.p`
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  margin: 0px;
  padding: 0px;
`;

export const StyledDropdownInput = styled.div`
  height: 30px;
  border: 1px solid lightgrey;
  display: flex;
  justify-content: space-between;
  border-radius: 3px;
  padding: 0px 10px;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledTable = styled.table`
  position: relative;
  min-height: calc(100vh - 64px - 220px);
  width: 100%;
  padding: 30px;
  border-radius: 10px;
  background: #fff;

  & > tr {
    border-bottom: 1px solid #ededed;
  }

  & > tr > :last-child {
    min-width: 150px;
  }

  & > :last-child {
    border-bottom: none;
  }
`;

export const StyledRow = styled.tr`
  display: grid;
  grid-template-columns: repeat(6, 2fr);
  min-height: 64px;
  padding: 15px 40px;
  cursor: pointer;
`;

export const StyledColumn = styled.td`
  display: flex;
  align-items: center;
  font-size: 14px;
  vertical-align: middle;
  line-height: 34px;
  cursor: pointer;
  font-weight: ${({ header }) => (header ? "bold" : "normal")};
  overflow-x: hidden;
`;

export const StyledText = styled.p`
  padding: 0px;
  margin: 0px;
  max-width: 60%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
`;

export const StyledButton = styled.button`
  border-radius: 15px;
  border-width: 0;
  padding: 5px 0px;
  width: 150px;
  display: flex;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
  background-color: ${({ type }) => type === "delete" ? "#cc0000" : "rgb(64, 169, 255)"};

  & > p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: rgb(255, 255, 255);
    text-transform: capitalize;
    margin: 0px;
    padding: 0px;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

export const AlignedRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;