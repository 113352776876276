import React from "react";
import styled from "styled-components";
import { Divider } from "antd";
import ProfileTitle from "./ProfileTitle";
import ProfileInfo from "./ProfileInfo";

const ActionModal = ({
  selectedDoctor = {},
  handleCancelModal,
  handleDeleteModalOpen,
  handleModelConfirm,
}) => {
  const Main = styled.div`
    .content {
      padding: 30px;
      background-color: #ffffff;
    }
  `;

  let acceptConditions = {
    doctorId: selectedDoctor.id,
    platformAmountType: "fixed",
  };
  let reasonForRejection;

  const handleChange = (name, value) => {
    if (selectedDoctor.action === "accept") {
      acceptConditions = { ...acceptConditions, [name]: value };
    } else {
      reasonForRejection = { [name]: value, doctorId: selectedDoctor.id };
    }
  };

  const determineConfirmation = (type) => {
    type === "accept"
      ? handleModelConfirm(type, acceptConditions)
      : handleModelConfirm(type, reasonForRejection);
  };

  return (
    <>
      <Main>
        <div className="content">
          <ProfileTitle
            doctor={selectedDoctor}
            handleModelConfirm={determineConfirmation}
            reasonForRejection={reasonForRejection}
            acceptConditions={acceptConditions}
          />
          <Divider />
          <ProfileInfo
            action={selectedDoctor.action}
            handleChange={handleChange}
            reasonsForRejection={reasonForRejection}
            acceptConditions={acceptConditions}
          />
        </div>
      </Main>
    </>
  );
};

export default ActionModal;
