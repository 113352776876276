import { Divider, Row, Col } from "antd";
import React from "react";
import ConsultationInfoCard from "./ConsultationInfoCard";
import PatientInfoCard from "./PatientInfoCard";
import InsuranceInfoCard from "./InsuranceInfoCard";
import CustomText from "../../Components/CustomText";
import CustomButton from "../../Components/CustomButton";
import { useState } from "react";
import ApiCalls from "../../apis/ApiCalls";

export default function ClaimDetails({
  handleCancelModal,
  selectedClaim,
  activeKey,
  handleRefetch,
}) {
  const [completeButtonLoading, setCompleteButtonLoading] = useState(false);
  const [failedButtonLoading, setFailedButtonLoading] = useState(false);

  const handleCompleted = (transaction) => {
    setCompleteButtonLoading(true);
    ApiCalls.updateInsuranceClaim({
      transactionId: transaction._id,
      claimStatus: "completed",
    })
      .then((res) => {
        if (res.data.status === 1) {
          setCompleteButtonLoading(false);
          handleRefetch();
          handleCancelModal();
        }
      })
      .catch((e) => {
        setCompleteButtonLoading(false);
        console.log(e);
      });
  };
  const handleFailed = (transaction) => {
    setFailedButtonLoading(true);
    ApiCalls.updateInsuranceClaim({
      transactionId: transaction._id,
      claimStatus: "failed",
    })
      .then((res) => {
        if (res.data.status === 1) {
          setFailedButtonLoading(false);
          handleRefetch();
          handleCancelModal();
        }
      })
      .catch((e) => {
        setFailedButtonLoading(false);
        console.log(e);
      });
  };
  return (
    <div>
      <Row>
        <Col>
          <CustomText color="#000" size="16px" fontWeight="bold">
            Details
          </CustomText>
        </Col>
      </Row>
      <Divider />
      <Row gutter={16}>
        <Col span={12} style={{ marginBottom: "16px" }}>
          <CustomText color="grey" fontWeight="bold">
            Patient Information
          </CustomText>
          <PatientInfoCard patient={selectedClaim.patientInfo} />
        </Col>
        <Col span={12}>
          <CustomText color="grey" fontWeight="bold">
            Encounter Information
          </CustomText>
          <ConsultationInfoCard
            consultationInfo={selectedClaim.consultationInfo}
          />
        </Col>
        <Col span={12} style={{ marginBottom: "16px" }}>
          <CustomText color="grey" fontWeight="bold">
            Insurance Details
          </CustomText>
          <InsuranceInfoCard
            insuranceInfo={selectedClaim.insuranceCardDetails}
            transactionInfo={selectedClaim.transactionDetails}
          />
        </Col>
      </Row>
      {activeKey === "pending" ? (
        <Row justify="end" gutter={16}>
          <Col>
            <CustomButton
              onClick={() => handleFailed(selectedClaim.transactionDetails)}
              loading={failedButtonLoading}
            >
              Failed
            </CustomButton>
          </Col>
          <Col>
            <CustomButton
              type="primary"
              onClick={() => handleCompleted(selectedClaim.transactionDetails)}
              loading={completeButtonLoading}
            >
              Completed
            </CustomButton>
          </Col>
        </Row>
      ) : (
        <Row justify="end ">
          <Col>
            <CustomButton type="primary" onClick={handleCancelModal}>
              Cancel
            </CustomButton>
          </Col>
        </Row>
      )}
    </div>
  );
}
