import { Divider, Row, Col, Spin } from "antd";
import React, { useEffect } from "react";

import DoctorInfoCard from "./DoctorInfoCard/DoctorInfoCard";
import NameCard from "./NameCard";
import MedicalLicenseCard from "./MedicalLicensesCard";
import CertificateDetailsCard from "./CertificateDetailsCard";
import EducationalDetailsCard from "./EducationalDetailsCard";
import ExperienceDetailsCard from "./ExperienceDetailsCard";
import BankDetailsCard from "./BankDetailsCard";

import CustomText from "../../../Components/CustomText";
import { useState } from "react";
import ApiCalls from "../../../apis/ApiCalls";
import styled from "styled-components";

const SectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const DetailsContainer = styled.div`
  height: 700px;
  min-height: 700px;
  overflow-y: scroll;
  overflow-x: hidden;
`;
const LoadingWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default function DetailsModal({ handleCancelModal, id, fromAppointments }) {
  const [loading, setLoading] = useState(true);
  const [selectedDoctor, selectDoctor] = useState({});

  useEffect(() => {
    fetchTheDoctorDetails(id);
  }, []);

  const fetchTheDoctorDetails = async (id) => {
    let { data } = await ApiCalls[fromAppointments ? 'doctorDetails'  : 'fetchDoctorDetails'](id);
    if(fromAppointments) {
      selectDoctor({ ...data?.data[0] });
    } else {
      selectDoctor({ ...data.data });
    }

    setLoading(false);
  };
  if (loading)
    return (
      <DetailsContainer>
        <SectionContainer>
          <Col>
            <CustomText color="#000" size="16px" fontWeight="bold">
              Therapist Details
            </CustomText>
          </Col>
          <Divider />
        </SectionContainer>
        <LoadingWrapper>
          <Spin />
        </LoadingWrapper>
      </DetailsContainer>
    );
  return (
    <div>
      <SectionContainer>
        <Col>
          <CustomText color="#000" size="16px" fontWeight="bold">
            Therapist Details
          </CustomText>
        </Col>
      </SectionContainer>
      <DetailsContainer>
        <Divider />
        <Row gutter={16}>
          <Col span={12} style={{ marginBottom: "16px" }}>
            <CustomText color="grey" fontWeight="bold">
              Therapist Information
            </CustomText>
            <DoctorInfoCard doctor={selectedDoctor} />
          </Col>
        </Row>
        <Row>
          <Col>
            <CustomText color="grey" fontWeight="bold">
              Bank Details
            </CustomText>
          </Col>
        </Row>
        {selectedDoctor.accountDetails && (
          <SectionContainer className="medical-licenses-section-container">
            <BankDetailsCard {...selectedDoctor.accountDetails} />
          </SectionContainer>
        )}
        {selectedDoctor?.specialization?.length > 0 && (
          <>
            {" "}
            <Row>
              <Col>
                <CustomText color="grey" fontWeight="bold">
                  Specialization
                </CustomText>
              </Col>
            </Row>
            <SectionContainer className="specialization-section-container">
              {selectedDoctor.specialization.map((item) => {
                return <NameCard specializationName={item.name} />;
              })}
            </SectionContainer>
          </>
        )}
        <Row>
          <Col>
            <CustomText color="grey" fontWeight="bold">
              Services
            </CustomText>
          </Col>
        </Row>
        <SectionContainer className="services-section-container">
          {selectedDoctor?.services?.map((item) => {
            return <NameCard specializationName={item.name} />;
          })}
          {selectedDoctor?.doctorAddedService &&
            selectedDoctor?.doctorAddedService.map((item) => {
              return <NameCard specializationName={item} />;
            })}
        </SectionContainer>
        {selectedDoctor?.languagesKnown?.length > 0 && (
          <>
            <Row>
              <Col>
                <CustomText color="grey" fontWeight="bold">
                  Languages Known
                </CustomText>
              </Col>
            </Row>
            <SectionContainer className="services-section-container">
              {selectedDoctor.languagesKnown.map((item) => {
                return <NameCard specializationName={item} />;
              })}
            </SectionContainer>
          </>
        )}
        {selectedDoctor?.medicalLicences?.length > 0 && (
          <>
            <Row>
              <Col>
                <CustomText color="grey" fontWeight="bold">
                  Medical Licenses
                </CustomText>
              </Col>
            </Row>
            <SectionContainer className="medical-licenses-section-container">
              {selectedDoctor.medicalLicences.map((item) => {
                return <MedicalLicenseCard {...item} />;
              })}
            </SectionContainer>
          </>
        )}
        {selectedDoctor?.certificateDetails?.length > 0 && (
          <>
            <Row>
              <Col>
                <CustomText color="grey" fontWeight="bold">
                  Certificate Details
                </CustomText>
              </Col>
            </Row>
            <SectionContainer className="medical-licenses-section-container">
              {selectedDoctor.certificateDetails.map((item) => {
                return <CertificateDetailsCard {...item} />;
              })}
            </SectionContainer>
          </>
        )}
        {selectedDoctor?.educationalDetails?.length > 0 && (
          <>
            <Row>
              <Col>
                <CustomText color="grey" fontWeight="bold">
                  Educational Details
                </CustomText>
              </Col>
            </Row>
            <SectionContainer className="medical-licenses-section-container">
              {selectedDoctor.educationalDetails.map((item) => {
                return <EducationalDetailsCard {...item} />;
              })}
            </SectionContainer>
          </>
        )}
        {selectedDoctor?.experienceDetails?.length > 0 && (
          <>
            <Row>
              <Col>
                <CustomText color="grey" fontWeight="bold">
                  Experience Details
                </CustomText>
              </Col>
            </Row>
            <SectionContainer className="medical-licenses-section-container">
              {selectedDoctor.experienceDetails.map((item) => {
                return <ExperienceDetailsCard {...item} />;
              })}
            </SectionContainer>
          </>
        )}
      </DetailsContainer>
    </div>
  );
}
