import React, { useState, useEffect } from 'react';
import { Row, Form, Col, Input, Button, message, Divider, Upload } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import CustomText from '../../Components/CustomText';
import styled from 'styled-components';
import ApiCalls from '../../apis/ApiCalls';

const StyledCol = styled(Col)`
  .ant-picker-input > input {
    font-size: 14px;
    color: #5472a3;
  }

  .ant-input:placeholder-shown {
    border-radius: 5px;
  }

  .ant-form-item-label > label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    padding-left: 15px;
  }

  .ant-form-item-has-error .ant-form-item-explain,
  .ant-form-item-has-error .ant-form-item-split {
    font-family: 'Montserrat', sans-serif;
  }
`;

const ButtonWrapper = styled.div`
  font-family: Montserrat;
  font-style: normal;
  float: right;
  .ant-btn {
    border: none;
    border-radius: 8px;
    height: 40px;
    background-color: #e79c23;
    border-color: #e79c23;
    width: 150px;
  }
`;

const UploadButtonContainer = styled.div`
  .ant-btn {
    border: none;
  }
  .ant-btn-primary {
    background-color: #e4e4e4;
    border: none;
    width: 150px;
    color: #9d9d9d;
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }
`;

function DeleteBanner({ onCancel, institutionType, selectedItem, handleRefetch }) {
  const [loading, setLoading] = useState(false)
  const handleDelete = () => {
    setLoading(true);
    ApiCalls.deleteBannerData({ id: selectedItem._id })
      .then(res => {
        if (res.data.status === 1) {
          onCancel(true);
          handleRefetch(true);
          setLoading(false)
          message.success('Operation successful');
        }
      })
      .catch(err => {
        message.error('Operation failed');
      })
      .finally(() => {
        setLoading(false);
      });
  }
  const handleCancel = () => {
    onCancel(true)
  }
  return (
    <>
      <Row justify="start" gutter={8} align="middle">
        <Col>
          <CustomText size="16px" fontWeight="bold">
            {`Delete ${institutionType}`}
          </CustomText>
        </Col>
      </Row>
      <Divider />
      <Row justify='center'>
        <CustomText style={{ fontSize: '16px', fontWeight: '900', marginBottom: '30px' }}>Are you sure want to delete?</CustomText>
        <ButtonWrapper>
          <Button type='primary' htmlType='submit' loading={loading} onClick={handleDelete} style={{ marginRight: '10px' }}>
            Delete
          </Button>
          <Button type='primary' htmlType='submit' onClick={handleCancel} style={{ background: 'white', color: 'red', marginLeft: '10px', border: '1px solid' }}>
            Cancel
          </Button>
        </ButtonWrapper>
      </Row>
    </>
  );
}

export default DeleteBanner;
