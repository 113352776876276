import React from 'react';
import ProfileAvatar from '../../Components/ProfileAvatar';
import CustomText from '../../Components/CustomText';
import moment from 'moment';
import { Row, Col } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import styled from 'styled-components';



const PatientInfoCard = ({ patient = {} }) => {
    function calculateAge(birthday) {
        // birthday is a date

        return birthday ?
            moment(birthday, 'DD/MM/YYYY')
                .fromNow({ withoutSuffix: true })
                .toUpperCase() : ""
    }

    const CardWrapper = styled.div`
    min-height:250px;
    border:1px solid #D9D9D9;
    padding:16px;
    margin:8px auto;
    .header-wrapper{
        border-bottom:1px solid #D9D9D9;
        padding-bottom:16px;
    }
    .content-wrapper{
        & > div{
            margin:8px;
        }        
    }
    
    `
    return (
        <CardWrapper>
            <div style={{ display: 'flex', alignItems: 'center' }} className="header-wrapper">
                <div>
                    <ProfileAvatar
                        url={patient.profilePic}
                        icon={<UserOutlined />}
                        size={60}
                    />
                </div>
                <div style={{ display: 'flex', marginLeft: '16px', flexDirection: 'column' }}>
                    <CustomText
                        size="16px"
                        color="#2959a8"
                        fontWeight="bold"
                    >
                        {patient.firstName}&nbsp;{patient.lastName}
                    </CustomText>
                    <CustomText
                        size="16px"
                        color="grey"
                    >
                        {patient.mobile}
                    </CustomText>
                </div>
            </div>
            <div className="content-wrapper">
                <Row>
                    <Col span={12}>
                        <CustomText fontWeight="bold">
                            Gender:
                    </CustomText>
                    </Col>
                    <Col span={12}>
                        <CustomText fontWeight="bold" color="grey">
                            {patient.gender}
                        </CustomText>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <CustomText fontWeight="bold">
                            Date Of Birth:
                    </CustomText>
                    </Col>
                    <Col span={12}>
                        <CustomText fontWeight="bold" color="grey">
                            {moment(moment(patient.dob).format()).format("DD/MM/YYYY")}


                        </CustomText>
                    </Col>
                </Row>

                <Row>
                    <Col span={12}>
                        <CustomText fontWeight="bold">
                            Age
                    </CustomText>
                    </Col>
                    <Col span={12}>
                        <CustomText fontWeight="bold" color="grey">
                            {calculateAge(moment(moment(patient.dob).format()).format("DD/MM/YYYY"))}
                        </CustomText>
                    </Col>
                </Row>
            </div>

        </CardWrapper >
    )
}

export default PatientInfoCard