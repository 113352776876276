import React from "react";
import { 
  StyledColumn, 
  StyledRow, 
  StyledTable, 
  StyledText, 
  StyledButton
} from "./styledComponents";

const CategoryCard = ({ data, deleteCategory, viewCategoryDetails, editCategory }) => {
  return (
    <StyledTable>

      {/* Header */}
      <StyledRow>
        <StyledColumn header={true}>Category Name</StyledColumn>
      </StyledRow>

      {data?.map(item => (
        <StyledRow key={item._id}>
          <StyledColumn>
            <StyledText>{item?.categoryName}</StyledText>
          </StyledColumn>
          <StyledColumn>
            <StyledButton onClick={() => viewCategoryDetails(item)}>
              <p>View Category Details</p>
            </StyledButton>
          </StyledColumn>
          <StyledColumn>
            <StyledButton onClick={() => editCategory(item)}>
              <p>Edit Category</p>
            </StyledButton>
          </StyledColumn>
          <StyledColumn>
            <StyledButton type="delete" onClick={() => deleteCategory(item)}>
              <p>Delete Category</p>
            </StyledButton>
          </StyledColumn>
        </StyledRow>
      ))}

    </StyledTable>
  );
}

export default CategoryCard;