import React from "react";
import CustomText from "../../../../Components/CustomText";
import { Row, Col, Image } from "antd";
import styled from "styled-components";

const EducationalDetailsCard = ({
  instituteName,
  qualifiction,
  instituteAddress,
  url,
}) => {
  const CardWrapper = styled.div`
    min-height: 250px;
    width: 650px;
    border: 1px solid #d9d9d9;
    padding: 16px;
    margin: 15px 0;
    margin-right: 10px;
    .header-wrapper {
      border-bottom: 1px solid #d9d9d9;
      padding-bottom: 16px;
    }
    .content-wrapper {
      & > div {
        margin: 8px;
        width: 400px;
      }
    }
  `;

  return (
    <>
      <CardWrapper>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="content-wrapper">
            <Row>
              <Col span={12}>
                <CustomText fontWeight="bold">Institute Name:</CustomText>
              </Col>
              <Col span={12}>
                <CustomText fontWeight="bold" color="grey">
                  {instituteName}
                </CustomText>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <CustomText fontWeight="bold">Institute Address :</CustomText>
              </Col>
              <Col span={12}>
                <CustomText fontWeight="bold" color="grey">
                  {instituteAddress}
                </CustomText>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <CustomText fontWeight="bold">Qualification:</CustomText>
              </Col>
              <Col span={12}>
                <CustomText fontWeight="bold" color="grey">
                  {qualifiction}
                </CustomText>
              </Col>
            </Row>
          </div>
          <Image src={url} height={180} />
        </div>
      </CardWrapper>
    </>
  );
};

export default EducationalDetailsCard;
