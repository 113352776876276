import React from "react";

import PatientList from "./patientList";

let ListController = ({ selectedTab, searchKey }) => {
  console.log(searchKey);
  return (
    <>
      <PatientList searchTerm={searchKey} />
    </>
  );
};

export default ListController;
