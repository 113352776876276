import React from 'react'
import styled from 'styled-components'
import { Card, Col, Row, Button, message, Pagination, Spin, Divider } from 'antd'
import { WarningOutlined } from '@ant-design/icons'

import { useEffect } from 'react'
import CustomText from '../../Components/CustomText'
import ApiCalls from '../../apis/ApiCalls'
import { useState } from 'react'

const DeleteButton = styled.div`
  width: 100%;

  .ant-btn-primary {
    /* background: #e79c23; */
    border: 0;
    border-radius: 5px;
    height: 39px;
    font-size: 16px;
    width: 106px;
    align-items: center;
  }
`

const CancelButton = styled.div`
  width: 100%;
  .ant-btn-primary {
    border-radius: 5px;
    height: 39px;
    font-size: 16px;
    width: 106px;
    align-items: center;
    border: 0;
  }
`

const Warning = styled(WarningOutlined)`
  height: 30px;
  width: 55px;
  svg {
    height: 100%;
    width: 100%;
  }
`

const DeleteView = ({ selectedInstitution, handleRefetch, handleCancelModal, institutionType = "" }) => {
  const [loading, setLoading] = useState(false)

  const handleDelete = event => {
    setLoading(true)

    const model = {
      id: selectedInstitution._id,
      userType: selectedInstitution.userType
    }

    ApiCalls.deleteInstitution(model)
      .then(response => {
        setLoading(false)
        handleCancelModal()
        handleRefetch()
        message.success('Delete Successful')
      })
      .catch(error => {
        setLoading(false)
        message.error('Delete Unsuccessful')
        handleCancelModal()
      })
  }

  return (
    <>
      <Row gutter={16} align="middle">
        <Col span={4}>
          <Warning />
        </Col >
        <Col span={20}>
          <CustomText size='14px' style={{ display: 'flex' }}>
            Are you sure you want to delete {`${selectedInstitution.name} ${institutionType.toLowerCase()}`}
          </CustomText>
        </Col>
      </Row>
      <Row
        gutter={16}
        justify="end"
      >
        <Col>
          <CancelButton>
            <Button
              onClick={handleCancelModal}
            >
              Cancel
            </Button>
          </CancelButton>
        </Col>
        <Col>
          {/* <DeleteButton> */}
          <Button
            loading={loading}
            type='primary'
            htmlType='submit'
            onClick={event => handleDelete(event)}
          >
            Delete{' '}
          </Button>
          {/* </DeleteButton> */}
        </Col>
      </Row>
    </>
  )
}

export default DeleteView
