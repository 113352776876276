import React from "react";
import ProfileAvatar from "../../../../Components/ProfileAvatar";
import CustomText from "../../../../Components/CustomText";
import moment from "moment";
import { Row, Col } from "antd";
import { UserOutlined } from "@ant-design/icons";
import styled from "styled-components";

const PatientCard = ({
  profilePic,
  firstName,
  lastName,
  phoneNumber,
  dob,
  city,
  country,
  gender,
  state,
  age,
}) => {
  const CardWrapper = styled.div`
    min-height: 250px;
    width: 450px;
    border: 1px solid #d9d9d9;
    padding: 16px;
    margin: 15px 0;
    margin-right: 10px;
    .header-wrapper {
      border-bottom: 1px solid #d9d9d9;
      padding-bottom: 16px;
    }
    .content-wrapper {
      & > div {
        margin: 8px;
      }
    }
  `;

  const ImageWrapper = styled.div`
    & > img {
      height: 100px;
      width: 100px;
      border-radius: 50px;
    }
  `;
  return (
    <CardWrapper>
      <div className="content-wrapper">
        {profilePic && (
          <Row gutter={[24, 32]}>
            <Col span={12}>
              <ImageWrapper>
                <img src={profilePic}></img>
              </ImageWrapper>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">Name:</CustomText>
          </Col>
          <Col span={12}>
            <CustomText fontWeight="bold" color="grey">
              {firstName} {lastName}
            </CustomText>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">Phone number:</CustomText>
          </Col>
          <Col span={12}>
            <CustomText fontWeight="bold" color="grey">
              {phoneNumber}
            </CustomText>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">Dob:</CustomText>
          </Col>
          <Col span={12}>
            <CustomText fontWeight="bold" color="grey">
              {dob}
            </CustomText>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">City:</CustomText>
          </Col>
          <Col span={12}>
            <CustomText fontWeight="bold" color="grey">
              {city}
            </CustomText>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">Country:</CustomText>
          </Col>
          <Col span={12}>
            <CustomText fontWeight="bold" color="grey">
              {country}
            </CustomText>
          </Col>
        </Row>
      </div>
    </CardWrapper>
  );
};

export default PatientCard;
