import React from 'react';
import CustomText from '../../Components/CustomText';
import { Card, Row, Col, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const CardItem = ({ item, handleRemoveItem, handleEditItem }) => {

    const handleEdit = () => {
        handleEditItem(item)
    };

    const handleDelete = () => {
        handleRemoveItem(item)
    }

    return (
        <Card>
            <Row justify="space-between" align="middle">
                <Col span={8}>
                    {item.type == "image" ?
                        <img
                            style={{ height: '200px', width: '200px' }}
                            src={item.url}
                            alt='Banner Image'
                        /> : <CustomText fontWeight="bold" size="16px">{item?.bannerContent ? item.bannerContent : item?.footerContent ? item?.footerContent : "Banner content"}</CustomText>}
                </Col>

                <Col span={8}>
                    <CustomText fontWeight="bold" size="16px">{item?.category ? item.category : ''}</CustomText>
                </Col>

                <Col span={8}>
                    <Row justify="end" gutter={16}>
                        <Col>
                            <Button
                                style={{ fontSize: '16px' }}
                                icon={<DeleteOutlined />}
                                onClick={handleDelete}
                                shape="round"
                                size="large"
                            >
                                Delete
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                type='primary'
                                style={{ fontSize: '16px' }}
                                onClick={handleEdit}
                                size="large"
                                shape="round"
                            >
                                View/Edit
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    );
}

export default CardItem;
