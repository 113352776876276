import axios from "axios";
import storage from "../storage";
import { API_SERVER } from "./ServerConfig";
const https = require("https");

const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled
    ? false
    : true;
};

const requestHandler = (request) => {
  if (isHandlerEnabled(request)) {
    request.headers.Authorization = `Bearer ${storage.authToken.getItem()}`;
  }
  return request;
};

const errorHandler = (error) => {
  if (isHandlerEnabled(error.config)) {
    if (error.message == "Invalid Token") {
    }
  }
  return Promise.reject({ ...error });
};

const successHandler = (response) => {
  if (isHandlerEnabled(response.config)) {
  }
  return response;
};
// https.globalAgent.options.rejectUnauthorized = false;
const httpsAgent = new https.Agent({
  rejectUnauthorized: false, // (NOTE: this will disable client verification)
  // cert: fs.readFileSync("./telecert.pem"),
  // key: fs.readFileSync("./telehealth.pem"),
  // passphrase: "telehealth"
});

const axiosInstance = axios.create({
  baseURL: `${API_SERVER}`,
  httpsAgent: httpsAgent,
});

// Add interceptors
axiosInstance.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

export default axiosInstance;
