import React from "react";
import { Input, message } from "antd";
import { StyledForm, StyledModal } from "./styledComponents";
import ApiCalls from "../../apis/ApiCalls";

const AddNewMedicine = ({
  showAddMedicine,
  setShowAddMedicine,
  fetchMedicineList,
}) => {
  const [form] = StyledForm.useForm();

  const onFinish = (val) => {
    ApiCalls.saveNewMedicine(val)
      .then((response) => {
        if (response.data.status) {
          message.success("Successfully added medicine to library");
          fetchMedicineList();
        }
        setShowAddMedicine(false);
        form.resetFields();
      })
      .catch((error) => {
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  return (
    <>
      <StyledModal
        visible={showAddMedicine}
        closable={false}
        title="Add New Medicine"
        centered={true}
        onCancel={() => setShowAddMedicine(false)}
        onOk={() => form.submit()}
        key={showAddMedicine}
      >
        <StyledForm form={form} onFinish={onFinish}>
          <StyledForm.Item
            name="medicineName"
            label="Medicine Name"
            rules={[
              {
                required: true,
                message: "Medicine name is required",
              },
            ]}
          >
            <Input />
          </StyledForm.Item>

          <StyledForm.Item
            name="drugType"
            label="Drug Type"
            rules={[
              {
                required: true,
                message: "Drug Type is required",
              },
            ]}
          >
            <Input />
          </StyledForm.Item>

          <StyledForm.Item
            name="dosageForm"
            label="Dosage Form"
            rules={[
              {
                required: true,
                message: "Dosage Form is required",
              },
            ]}
          >
            <Input />
          </StyledForm.Item>

          <StyledForm.Item
            name="description"
            label="Description"
            rules={[
              {
                required: true,
                message: "Description is required",
              },
            ]}
          >
            <Input />
          </StyledForm.Item>

          <StyledForm.Item
            name="strength"
            label="Strength"
            rules={[
              {
                required: true,
                message: "Strength is required",
              },
            ]}
          >
            <Input />
          </StyledForm.Item>

          <StyledForm.Item
            name="volume"
            label="Volume"
            rules={[
              {
                required: true,
                message: "Volume is required",
              },
            ]}
          >
            <Input />
          </StyledForm.Item>
        </StyledForm>
      </StyledModal>
    </>
  );
};

export default AddNewMedicine;
