import React from "react";
import { 
  StyledColumn, 
  DoctorRow, 
  StyledTable, 
  StyledText, 
  StyledButton
} from "./styledComponents";

const EmployeeCard = ({ data, viewDetails }) => {
  return (
    <StyledTable>

      {/* Header */}
      <DoctorRow>
        <StyledColumn header={true}>Name</StyledColumn>
        <StyledColumn header={true}>Email</StyledColumn>
      </DoctorRow>

      {data?.map(item => (
        <DoctorRow key={item._id}>

          {/* Employee name */}
          <StyledColumn>
            <StyledText type="doctor">{item?.fullName}</StyledText>
          </StyledColumn>

          {/* Employee email */}
          <StyledColumn>
            <StyledText type="doctor">{item?.email}</StyledText>
          </StyledColumn>

          {/* View doctor details */}
          <StyledColumn>
            <StyledButton onClick={() => viewDetails(item)}>
              <p>View Details</p>
            </StyledButton>
          </StyledColumn>

        </DoctorRow>
      ))}

    </StyledTable>
  );
}

export default EmployeeCard;