import React from "react";
import styled from "styled-components";
import {
  Card,
  Col,
  Row,
  Button,
  Typography,
  Pagination,
  Spin,
  Modal,
} from "antd";
import ProfileAvatar from "../../../Components/ProfileAvatar";
import { UserOutlined, DeleteOutlined } from "@ant-design/icons";

import ApiCalls from "../../../apis/ApiCalls";

import { useState, useEffect } from "react";
import DoctorCard from "../DoctorCard";
import EmptyComponent from "../../../Components/EmptyComponent";
import EditVatForm from "./EditVatForm";

const CardsWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
`;

let AcceptedDoctorsList = ({ searchTerm = "" }) => {
  let [doctors, setDoctors] = useState([]);
  let [loading, setLoading] = useState(true);
  let [totalPages, setTotalPages] = useState(0);
  let [currentPage, setCurrentPage] = useState(0);
  const [showVatModal, setShowVatModal] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState(null);

  useEffect(() => {
    getAcceptedDoctors();
  }, [currentPage, searchTerm]);

  useEffect(() => {
    setCurrentPage(0);
  }, [searchTerm]);

  const getAcceptedDoctors = async () => {
    try {
      setLoading(true);
      let { data } = await ApiCalls.getDoctors(
        "active",
        5,
        searchTerm,
        currentPage
      );
      setDoctors(data.data.doctors);
      setTotalPages(data.data.doctorCount);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handlePageChange = (pageNumber, pageLimit) => {
    setCurrentPage(pageNumber - 1);
  };
  return (
    <>
      {!loading && (
        <CardsWrapper>
          {doctors.length === 0 ? (
            <EmptyComponent message="No data found" />
          ) : null}
          {doctors.map((item) => {
            return (
              <DoctorCard
                name={item.name}
                key={item.name}
                email={item.email}
                id={item._id}
                profilePic={item.profilePic}
                showVatEdit
                setShowVatModal={setShowVatModal}
                setSelectedDoctor={setSelectedDoctor}
              />
            );
          })}
        </CardsWrapper>
      )}
      {loading && (
        <Spin
          spinning={loading}
          size="large"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        />
      )}
      {!loading && (
        <PaginationWrapper>
          <Pagination
            defaultCurrent={1}
            total={totalPages}
            pageSize={5}
            hideOnSinglePage={true}
            onChange={handlePageChange}
            current={currentPage + 1}
          />
        </PaginationWrapper>
      )}
      <Modal
        visible={showVatModal}
        onCancel={() => setShowVatModal(false)}
        footer={false}
        width={"72%"}
        centered={true}
        destroyOnClose={true}
      >
        <EditVatForm
          selectedDoctor={selectedDoctor}
          setShowVatModal={setShowVatModal}
          getAcceptedDoctors={getAcceptedDoctors}
        />
      </Modal>
    </>
  );
};

export default AcceptedDoctorsList;
