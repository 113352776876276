import React from "react";
import { Avatar } from "antd";
import { API_SERVER } from "../apis/ServerConfig";
import { UserOutlined } from "@ant-design/icons";

const ProfileAvatar = ({ url, icon, size, style, custom }) => {
  return url ? (
    <Avatar
      // src={`${API_SERVER}/api/public/${url}`}
      src={url}
      size={size}
      style={style}
    />
  ) : (
    <Avatar icon={<UserOutlined />} size={size} style={style} />
  );
};
export default ProfileAvatar;
