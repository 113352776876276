import styled from "styled-components";

export const BackgroundLayer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: ${(props) => (props.visible ? "block" : "none")};
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.4);
  padding: 70px;
  z-index: 5000;
`;

export const FileContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  & img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    width: auto;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }

  & video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    width: auto;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: -20px;
  right: -20px;
  height: 30px;
  width: 30px;
  border: none;
  border-radius: 50%;
  background: #65667d;
  cursor: pointer;

  & .anticon-close {
    position: absolute;
    top: 8px;
    right: 8px;
    color: white;
  }
`;

