import React from "react";
import styled from "styled-components";
import { Typography, Card, Tabs, Col, Row, Input } from "antd";
import PatientList from "./PatientList";
import ProviderList from "./ProviderList";
import { useState } from "react";

const { TabPane } = Tabs;

const Main = styled.div`
  padding: 3% 5%;
  font-family: "Montserrat", sans-serif;
`;
const MainTitle = styled(Typography)`
  margin-bottom: 0.5em;
  color: #5472a3;
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
`;
const CustomTabs = styled(Tabs)`
  width: 100%;

  .ant-tabs-nav {
    width: 100%;
    margin: 0;
    & > div {
      width: 100%;
    }
  }
  .ant-tabs-tab-active {
    color: #3e8afa;
    svg {
      /* fill: #1890ff; */
    }
  }
  .ant-tabs-tab:hover {
    color: #1890ff;
    svg {
      /* fill: #1890ff; */
    }
  }
  .ant-tabs-tab {
    width: 250px;
    justify-content: center;
    font-weight: 600;
  }
  .tabs-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    svg {
      margin-right: 8px;
    }
  }
  .ant-tabs-ink-bar {
    height: 3px;
    /* background-color: #1890ff; */
  }
`;
const StyledDiv = styled.div`
  // display: flex;
`;

const StyledCard = styled(Card)`
  width: 100%;
`;

const ManageUsers = () => {
  const [activeKey, setActiveKey] = useState("patient");
  const [searchKey, setSearchKey] = useState("");

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  const handleSearch = (e) => {
    setSearchKey(e.target.value);
  };

  return (
    <Main>
      <div>
        <Row justify="space-between" align="middle">
          <Col>
            <MainTitle>Manage Users</MainTitle>
          </Col>
          <Col>
            <Input.Search
              placeholder="Search"
              onChange={handleSearch}
              style={{ width: 256 }}
            />
          </Col>
        </Row>
      </div>
      <div>
        <StyledCard>
          <Col span={24}>
            <StyledDiv>
              <CustomTabs
                defaultActiveKey="1"
                size="large"
                onChange={handleTabChange}
              >
                <TabPane
                  tab={<div className="tabs-wrapper">Patient</div>}
                  key="patient"
                />

                <TabPane
                  tab={<div className="tabs-wrapper">Provider</div>}
                  key="provider"
                />
              </CustomTabs>
            </StyledDiv>
          </Col>
        </StyledCard>

        {
          {
            patient: <PatientList searchKey={searchKey} />,
            provider: <ProviderList searchKey={searchKey} />,
          }[activeKey]
        }
      </div>
    </Main>
  );
};

export default ManageUsers;
