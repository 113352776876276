import styled from "styled-components";

export const StyledTable = styled.table`
  position: relative;
  min-height: calc(100vh - 64px - 220px);
  width: 100%;
  padding: 30px;
  border-radius: 10px;
  background: #fff;

  & > tr {
    border-bottom: 1px solid #ededed;
  }

  & > tr > :last-child {
    min-width: 150px;
  }

  & > :last-child {
    border-bottom: none;
  }
`;

export const StyledRow = styled.tr`
  display: grid;
  grid-template-columns: repeat(6, 2fr);
  min-height: 64px;
  padding: 15px 40px;
  cursor: pointer;
`;

export const StyledColumn = styled.td`
  display: flex;
  align-items: center;
  font-size: 14px;
  vertical-align: middle;
  line-height: 34px;
  cursor: pointer;
  font-weight: ${({ header }) => (header ? "bold" : "normal")};
  overflow-x: hidden;
`;

export const StyledText = styled.p`
  padding: 0px;
  margin: 0px;
  max-width: 60%;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

export const StyledButton = styled.button`
  border-radius: 20px;
  border-width: 0;
  height: 40px;
  width: 150px;
  display: flex;
  justify-content: space-around;
  -moz-box-align: center;
  align-items: center;
  background-color: rgb(64, 169, 255);

  & > p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: rgb(255, 255, 255);
    text-transform: capitalize;
    padding-top: 17px;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;