import React from "react";
import moment from "moment";
import { Col } from "antd";
import CustomText from "../../../../Components/CustomText";
import { FamilyMemberWrapper, StyledRow } from './styledComponents';

const FamilyMemberCard = ({ data }) => (
  <FamilyMemberWrapper>
    <StyledRow>
      <Col span={12}>
        <CustomText fontWeight="bold">Name:</CustomText>
      </Col>
      <Col span={12}>
        <CustomText fontWeight="bold" color="grey">
          {data?.fullName}
        </CustomText>
      </Col>
    </StyledRow>
    <StyledRow>
      <Col span={12}>
        <CustomText fontWeight="bold">Relationship:</CustomText>
      </Col>
      <Col span={12}>
        <CustomText fontWeight="bold" color="grey">
          {data?.relationship}
        </CustomText>
      </Col>
    </StyledRow>
    <StyledRow>
      <Col span={12}>
        <CustomText fontWeight="bold">Date of birth:</CustomText>
      </Col>
      <Col span={12}>
        <CustomText fontWeight="bold" color="grey">
          {moment(data?.dob).format("DD-MM-YYYY")}
        </CustomText>
      </Col>
    </StyledRow>
  </FamilyMemberWrapper>
);

export default FamilyMemberCard;