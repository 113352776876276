import React from "react";
import styled from "styled-components";
import { Form, Input, Button, Typography, Row, Col } from "antd";
import "antd/dist/antd.css";
import Logo from "../images/js/Logo";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const LoginSection = (props) => {
  const { Title } = Typography;
  const [form] = Form.useForm();

  const MainForm = styled.div`
    margin: 0 auto;
    padding: 20px;
    background: #f8fafb;
    height: 100vh;
  `;

  const StyledColumn = styled(Col)`
    position: relative;

    width: 100%;
    padding: 15%;

    @media (max-width: 768px) {
      padding: 80% 0;
    }

    @media (max-width: 414px) {
      padding: 50% 0;
    }
  `;

  const CustomCol = styled(Col)`
    & input {
      padding: 21.5px;
      font-size: 16px;
      border-radius: 10px 10px 0px 0px;
      margin-bottom: 0;
      font-family: Montserrat;
      color: #6b6b6b;
      // border-bottom: none;
    }

    &input:hover {
      border: 1px solid black;
    }

    & > div {
      margin-bottom: 0px;
    }
    .success:hover {
      border: 2px solid red;
    }

    .ant-input:hover {
      border-color: #40a9ff;
      border-bottom: 1px solid #40a9ff;
      border-right-width: 1px !important;
    }

    & .ant-form-item-has-error .ant-form-item-explain,
    .ant-form-item-has-error .ant-form-item-split {
      font-family: Montserrat;
    }

    .ant-form-item-has-error .ant-input,
    .ant-form-item-has-error .ant-input-affix-wrapper,
    .ant-form-item-has-error .ant-input:hover,
    .ant-form-item-has-error .ant-input-affix-wrapper:hover {
      border-radius: 10px 10px 0px 0px;
    }

    .ant-input-affix-wrapper {
      border-radius: 0 0 10px 10px;
    }

    .ant-input:focus,
    .ant-input-focused {
      border-bottom: 1px solid #40a9ff;
    }
  `;

  const PasswordStyle = styled(Input.Password)`
    border-radius: 0 0 10px 10px !important;
    padding: 21.5px;

    .ant-form-item-has-error .ant-input,
    .ant-form-item-has-error .ant-input-affix-wrapper,
    .ant-form-item-has-error .ant-input:hover,
    .ant-form-item-has-error .ant-input-affix-wrapper:hover {
      border-bottom: 0 !important;
    }

    .ant-input {
      margin: 0;
      border-bottom: 0 !important;
    }
  `;

  const ButtonWrapper = styled.div`
    .ant-btn {
      border: none;
      border-radius: 8px;
      height: 40px;
      width: 100%;
      /* background-color: #1890ff; */
      /* border-color: #1890ff; */
      font-family: Montserrat;
      font-weight: 600;
    }
  `;

  const FormStyle = styled(Form)`
    width: 100%;
  `;

  const CenterStyled = styled.div`
    text-align: center;
    position: relative;
  `;

  const LogoStyle = styled(Row)`
    margin-bottom: 7%;
    justify-content: center;
  `;

  const TitleStyle = styled(Row)`
    margin-bottom: 5%;
    width: 100%;
    justify-content: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 37px;
    @media (max-width: 1440px) {
      width: 102%;
    }
    @media (max-width: 1024px) {
      h2.ant-typography,
      .ant-typography h2 {
        font-size: 24px;
      }
    }

    @media (max-width: 768px) {
      h2.ant-typography,
      .ant-typography h2 {
        font-size: 24px;
      }
    }
  `;

  const onFinish = (values) => {
    props.login(values);
  };

  return (
    <MainForm>
      <Row justify="center">
        <StyledColumn>
          <CenterStyled>
            <LogoStyle>
              <Logo />
            </LogoStyle>
          </CenterStyled>
          <CenterStyled>
            <TitleStyle>
              <Title level={2}>Welcome Back</Title>
            </TitleStyle>
          </CenterStyled>
          <Row>
            <FormStyle
              layout="vertical"
              form={form}
              initialValues={{
                layout: "vertical",
              }}
              onFinish={onFinish}
            >
              <Row>
                <CustomCol span={24}>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Email is required",
                      },
                      {
                        type: "email",
                        message: "Please enter valid Email",
                      },
                    ]}
                  >
                    <Input placeholder="Your Email" size="large" />
                  </Form.Item>
                </CustomCol>
              </Row>

              <Row>
                <CustomCol span={24}>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Password is required",
                      },
                    ]}
                  >
                    <PasswordStyle
                      placeholder="Your Password"
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                  </Form.Item>
                </CustomCol>
              </Row>

              <Row justify="center" style={{ marginTop: "20px" }}>
                <Col span={24}>
                  <Form.Item>
                    <ButtonWrapper>
                      <Button
                        type="primary"
                        block={true}
                        htmlType="submit"
                        loading={props.loading}
                      >
                        Log In
                      </Button>
                    </ButtonWrapper>
                  </Form.Item>
                </Col>
              </Row>
            </FormStyle>
          </Row>
        </StyledColumn>
      </Row>
    </MainForm>
  );
};

export default LoginSection;
