import React from "react";
import styled from "styled-components";
import { Card, Col, Row, Button, message, Pagination, Spin } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import CustomText from "../../Components/CustomText";
import ApiCalls from "../../apis/ApiCalls";

const DeleteButton = styled.div`
  width: 100%;

  .ant-btn {
    /* background: #e79c23; */
    border: 0;
    border-radius: 5px;
    height: 39px;
    font-size: 16px;
    width: 106px;
    align-items: center;
  }
`;

const CancelButton = styled.div`
  width: 100%;
  .ant-btn {
    border-radius: 5px;
    height: 39px;
    font-size: 16px;
    width: 106px;
    align-items: center;
  }
`;

const Warning = styled(WarningOutlined)`
  height: 30px;
  width: 55px;
  svg {
    height: 100%;
    width: 100%;
  }
`;

const DeleteModal = ({ selectedService, handleModalCancel, fetchServices }) => {
  const handleModalConfirm = async (id) => {
    try {
      let response = await ApiCalls.deleteService(id);

      if (response.data.status) {
        message.success("Successfully deleted Service");
        fetchServices();
      }
      handleModalCancel(false);
    } catch (error) {
      console.log(error);
      message.error(
        error.response
          ? error?.response?.data?.data?.message
          : "No Response From Server",
        5
      );
    }
  };
  return (
    <>
      <CustomText size="18px" style={{ display: "flex" }}>
        <Warning />
        {`Are you sure you want to delete
        ${selectedService?.serviceName} ?`}
      </CustomText>
      <Row
        justify="end"
        gutter={16}
        style={{ height: "100px", alignContent: "flex-end" }}
      >
        <Col>
          <CancelButton>
            <Button onClick={() => handleModalCancel(false)}>Cancel</Button>
          </CancelButton>
        </Col>
        <Col>
          <DeleteButton>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => handleModalConfirm(selectedService._id)}
            >
              Delete
            </Button>
          </DeleteButton>
        </Col>
      </Row>
    </>
  );
};

export default DeleteModal;
