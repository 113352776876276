import React, { useState, useEffect } from "react";
import {
  StyledTable,
  StyledRow,
  StyledColumn,
  Main,
  PaginationContainer,
  PageFunctionContainer,
  ButtonsContainer,
} from "../MedicineLibrary/styledComponents";
import CustomLayout from "../../Components/CustomLayout";
import { LoadingOutlined } from "@ant-design/icons";
import ApiCalls from "../../apis/ApiCalls";
import { message, Pagination, Input, Button, Upload } from "antd";
import ManageSpecializationForm from "./ManageSpecializationForm";
import EmptyComponent from "../../Components/EmptyComponent";

const ManageSpecializations = () => {
  const [specializations, setSpecializations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showSpecializationModal, setShowSpecializationModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);

  const updateSpecialization = (values) => {
    const payload = {
      ...values,
      specializationId: selectedValue ? selectedValue._id : undefined,
    };
    (selectedValue
      ? ApiCalls.editSpecialization(payload)
      : ApiCalls.addSpecialization(payload)
    )
      .then((response) => {
        if (response.data.status) {
          setSelectedValue(null);
          setShowSpecializationModal(false);
          getSpecialization();
          message.success(
            `${selectedValue ? "Edited" : "Added"} specialization successfully`
          );
        }
      })
      .catch((error) =>
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        )
      );
  };

  const uploadFile = (file, id) => {
    if (["image/jpg", "image/jpeg", "image/png"].includes(file.type)) {
      const formData = new FormData();
      formData.append("uploadFile", file);
      formData.append("updateFieldId", id);
      formData.append("type", "specializationImg");
      setUploadLoading(id);
      ApiCalls.uploadSpecializationImage(formData)
        .then((response) => {
          if (response.data.status) {
            message.success("Uploaded image succesfully");
            setUploadLoading(null);
            getSpecialization();
          }
        })
        .catch((err) => {
          setUploadLoading(null);
        });
    } else {
      message.error("Image should be of the type jpeg or png");
    }
  };

  const getSpecialization = () => {
    let params = {
      pageNo: currentPage,
      limit: 10,
      searchKey: searchValue,
    };
    setLoading(true);
    ApiCalls.fetchSpecialization(params)
      .then((response) => {
        if (response.data.status) {
          setTotalCount(response.data.data.specializationCount);
          setSpecializations(response.data.data.specializations);
          setLoading(false);
        }
      })
      .catch((err) => {
        setSpecializations([]);
        setLoading(false);
      });
  };

  useEffect(() => {
    setCurrentPage(0);
  }, [searchValue]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getSpecialization();
    }, 400);
    return () => {
      clearInterval(delayDebounceFn);
    };
  }, [searchValue, currentPage]);

  useEffect(() => {
    if (!showSpecializationModal) {
      setSelectedValue(null);
    }
  }, [showSpecializationModal]);

  useEffect(() => {
    if (!deleteModalVisibility) {
      setSelectedValue(null);
    }
  }, [deleteModalVisibility]);

  return (
    <>
      <Main>
        <CustomLayout
          mainTitle="Manage Specialization"
          makePaddingZero={true}
          headerComponent={
            <PageFunctionContainer>
              <Input
                autoFocus={true}
                placeholder="Search"
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
                allowClear
              />
              <ButtonsContainer>
                <Button
                  type="primary"
                  onClick={() => setShowSpecializationModal(true)}
                >
                  Add Specialization
                </Button>
              </ButtonsContainer>
            </PageFunctionContainer>
          }
        ></CustomLayout>
        <StyledTable>
          <StyledRow addedBy={"specialization"}>
            <StyledColumn header>S.No</StyledColumn>
            <StyledColumn header>Specialization</StyledColumn>
            <StyledColumn header>Image</StyledColumn>
            <StyledColumn header>
              Upload Image 1 * 1 (Height * width)
            </StyledColumn>
          </StyledRow>
          {specializations.length === 0 && !loading && (
            <EmptyComponent message={"No data found"} />
          )}
          {loading ? (
            <LoadingOutlined />
          ) : (
            specializations.map((item, count) => {
              return (
                <StyledRow id={item._id} addedBy={"specialization"}>
                  <StyledColumn>{currentPage * 10 + count + 1}</StyledColumn>
                  <StyledColumn>{item.specializationName}</StyledColumn>
                  <StyledColumn>
                    <img
                      src={item.url}
                      alt=""
                      style={{
                        height: "30px",
                        width: "30px",
                        objectFit: "contain",
                      }}
                    />
                  </StyledColumn>

                  <StyledColumn center>
                    <Button
                      onClick={() => {
                        setSelectedValue(item);
                        setShowSpecializationModal(true);
                      }}
                    >
                      Edit
                    </Button>
                    <Upload
                      name="file"
                      showUploadList={false}
                      beforeUpload={(file) => uploadFile(file, item._id)}
                      customRequest={() => {}}
                    >
                      <Button
                        type="primary"
                        style={{ marginLeft: "30px" }}
                        loading={uploadLoading === item._id}
                      >
                        Upload Image
                      </Button>
                    </Upload>
                  </StyledColumn>
                </StyledRow>
              );
            })
          )}
        </StyledTable>
        {totalCount > 10 && (
          <PaginationContainer>
            <Pagination
              size="large"
              pageSize={10}
              total={totalCount}
              current={currentPage + 1}
              onChange={(val) => setCurrentPage(val - 1)}
              showSizeChanger={false}
            />
          </PaginationContainer>
        )}

        <ManageSpecializationForm
          selectedValue={selectedValue}
          showSpecializationModal={showSpecializationModal}
          setShowSpecializationModal={setShowSpecializationModal}
          onFinish={updateSpecialization}
        />
      </Main>
    </>
  );
};

export default ManageSpecializations;
