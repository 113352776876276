import styled from "styled-components";
import { Form, Modal } from "antd";

export const Main = styled.div`
  padding: 0 5%;
`;

export const MedicinesPageWrapper = styled.div`
  position: relative;
  min-height: calc(100vh - 80px - 60px);
  height: 100%;
  width: 100%;
  padding: 30px 30px 30px 50px;
  font-family: "Montserrat", sans-serif;
  & .anticon-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    & svg {
      height: 2rem;
      width: 3rem;
    }
  }
`;

export const StyledTable = styled.table`
  position: relative;
  min-height: calc(100vh - 64px - 220px);
  width: 100%;
  padding: 30px;
  border-radius: 10px;
  background: #fff;
  & > :first-child {
    border-top: ${(props) => (props.patient ? "1px solid #ededed" : null)};
    background: ${(props) => (props.patient ? "#F7F8F9" : null)};
  }

  & > tr {
    border-bottom: 1px solid #ededed;
  }

  & > tr > :last-child {
    min-width: 150px;
  }

  & > :last-child {
    border-bottom: ${(props) => (props.patient ? null : "none")};
  }

  & .anticon-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    & svg {
      height: 1.5em;
      width: 1.5em;
    }
  }

  & > span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: #65667d;
  }
`;

export const StyledRow = styled.tr`
  display: grid;
  grid-template-columns: ${({ addedBy }) =>
    addedBy === "services"
      ? "repeat(3, 1fr)"
      : addedBy === "specialization"
      ? "repeat(4, 1fr)"
      : addedBy === "admin"
      ? "repeat(7, 1fr)"
      : "repeat(8, 1fr)"};
  min-height: 64px;
  padding: 15px ${(props) => (props.patient ? "15px" : "30px")};
  cursor: ${(props) => (props.pointer ? "pointer" : null)};
`;

export const StyledColumn = styled.td`
  display: ${(props) =>
    props.delete || props.fileName || props.center ? "flex" : null};
  justify-content: ${(props) => (props.fileName ? null : "center")};
  align-items: center;
  font-size: 14px;
  vertical-align: middle;
  line-height: 34px;
  /* text-transform: ${(props) => (props.fileName ? null : "capitalize")}; */
  cursor: ${(props) => (props.fileName ? "pointer" : null)};
  font-weight: ${(props) => (props.header ? "bold" : "normal")};
`;

export const PaginationContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

export const StyledForm = styled(Form)`
  & .ant-form-item-label {
    width: 120px;
    text-align: left;
  }

  & input {
    border-radius: 4px;
    height: 40px;
  }
`;

export const UploadContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 130px;
  width: 470px;
  border: 1px dashed lightgrey;
  border-radius: 5px;
`;

export const UploadInfo = styled.p``;

export const UploadText = styled.p``;

export const DownloadLink = styled.p`
  margin-top: 20px;
  cursor: pointer;
`;

export const PageFunctionContainer = styled.div`
  & > :first-child {
    max-width: 300px;
  }
  display: flex;
  justify-content: space-between;
  width: 600px;
  & input {
    width: 300px;
  }
`;

export const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  width: 266px !important;
`;

export const TabButton = styled.button`
  border: none;
  background: none;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: ${(props) => (props.active ? "#1890ff" : null)};
  cursor: pointer;
`;

export const StyledModal = styled(Modal)`
  & .ant-modal-content {
    border-radius: 7px;
  }

  & .ant-modal-header {
    border-radius: 7px;
    & .ant-modal-title {
      font-weight: bold;
    }
  }
`;

export const StyledButtonsContainerForLastColumn = styled.div`
  display: flex;
  justify-content: space-between;

  & > button {
    width: 70px;
  }
`;
