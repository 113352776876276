import React, { useState, useEffect } from "react";
import {
    FormListContainer,
    FormWrapper,
    HeadingText,
    SubmitButton,
    Container,
    Wrapper,
    QuesText,
    ProgressDiv,
    SmallText,
    ProgressText,
} from "./styledComponent";
import { Button, Divider, Progress, message } from "antd";
import { useParams, useHistory } from "react-router-dom";
import ApiCalls from "../../apis/ApiCalls";

const ViewAnswers = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [singleFormDetails, setSingleFormDetails] = useState({});
    const [allSubmissionSingleFormDetails, setAllSubmissionSingleFormDetails] = useState({});
    const param = useParams();

    const ViewInDetails = () => {
        history.push(`/base/home/survay-form/form-details/view-answers/View-in-details/${param.id}`)
    }
    // get single form Api
    const getSingleFormDetails = () => {
        ApiCalls.getSingleForm(param.id)
            .then((res) => {
                setLoading(false);
                if (res?.data?.data) {
                    setSingleFormDetails(res.data.data);
                }
            })
            .catch((error) => {
                setSingleFormDetails({});
                setLoading(false);
                if (error.response) {
                    message.info(error.response.data.message);
                } else if (error.request) {
                    message.error("Server not responding");
                } else {
                    message.error("Something went wrong");
                }
            });
    }

    //All Submission Single Form API
    const allSubmissionSignleFormApi = () => {
        ApiCalls.getAllSubmissionSingleForm(param.id)
            .then((res) => {
                setLoading(false);
                if (res?.data?.data?.data) {
                    let temp=res?.data?.data?.data

                    Object.keys(temp).forEach(item=>{
                        let total=0;
                        Object.keys(temp[item]).filter(opt => opt!== 'questionId').forEach(ans=>{
                            total += temp[item][ans]
                        })
                        temp[item]["total"] = total;
                    })
                    
                    setAllSubmissionSingleFormDetails(temp);
                    message.success("View Answers Details is here");
                }
            })
            .catch((error) => {
                setAllSubmissionSingleFormDetails({});
                setLoading(false);
                if (error.response) {
                    message.info(error.response.data.message);
                } else if (error.request) {
                    message.error("Server not responding");
                } else {
                    message.error("Something went wrong");
                }
            });
    }

    useEffect(() => {
        getSingleFormDetails();
        allSubmissionSignleFormApi();
    }, [])

    return (
        <FormListContainer>
            <FormWrapper Viewanswer={true}>
                <HeadingText>{singleFormDetails.formName}</HeadingText>
                <SubmitButton>
                    <Button type="primary" htmlType="submit" onClick={ViewInDetails}>
                        View in detail
                    </Button>
                </SubmitButton>
            </FormWrapper>
            <Divider />
            {Object.keys(allSubmissionSingleFormDetails)?.map((item, index) => (
                <Wrapper>
                    <Container>
                        <QuesText>Q {index + 1}{item}?</QuesText>
                        {
                            Object.keys(allSubmissionSingleFormDetails[item]).filter(opt => opt !== 'questionId' && opt !== 'total').map((ans) => (
                                <ProgressDiv>
                                    <SmallText>{ans}</SmallText>
                                    <Progress
                                        percent={Math.round((allSubmissionSingleFormDetails[item][ans] / allSubmissionSingleFormDetails[item].total) *100)}
                                        showInfo={false}
                                        strokeColor={{ from: '#32AFBF', to: '#AFEDF5' }}
                                    />
                                    <ProgressText>{(allSubmissionSingleFormDetails[item][ans] / allSubmissionSingleFormDetails[item].total) *100}%</ProgressText>
                                </ProgressDiv>
                            ))
                        }
                    </Container>
                </Wrapper>
            ))
            }
        </FormListContainer>
    )
}
export default ViewAnswers;