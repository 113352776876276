import React from "react";
import { 
  StyledCompanyColumn, 
  StyledCompanyRow, 
  StyledTable, 
  StyledText
} from "./styledComponents";

const CompanyCard = ({ data }) => {
  return (
    <StyledTable type="company">

      {/* Header */}
      <StyledCompanyRow>
        <StyledCompanyColumn header={true}>Company Email</StyledCompanyColumn>
        <StyledCompanyColumn header={true}>Company Address</StyledCompanyColumn>
      </StyledCompanyRow>

      {data?.map(item => (
        <StyledCompanyRow key={item?.companyEmail}>

          {/* Company email */}
          <StyledCompanyColumn>
            <StyledText type="company">{item?.companyEmail}</StyledText>
          </StyledCompanyColumn>

          {/* Company address */}
          <StyledCompanyColumn>
            <StyledText type="company">{item?.companyAddress}</StyledText>
          </StyledCompanyColumn>

        </StyledCompanyRow>
      ))}

    </StyledTable>
  );
}

export default CompanyCard;