import React from "react";
import styled from "styled-components";
import { Card, Col, Row, Button, Typography, Pagination, Spin } from "antd";
import ProfileAvatar from "../../../Components/ProfileAvatar";
import { UserOutlined, DeleteOutlined } from "@ant-design/icons";
import ActionModal from "../ActionModal";

import ApiCalls from "../../../apis/ApiCalls";
import Modal from "antd/lib/modal/Modal";
import { useState, useEffect } from "react";
import RefundCard from "../RefundCard";
import EmptyComponent from "../../../Components/EmptyComponent";

const CardsWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  min-height: 500px;
`;
const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
`;

let AcceptedRefundsList = () => {
  let [refunds, setRefunds] = useState([]);
  let [loading, setLoading] = useState(true);
  let [currentPage, setCurrentPage] = useState(0);
  let [totalPages, setTotalPages] = useState(0);
  const [isModalVisible, setModalVisibility] = useState(false);
  const [currentSelectedRefund, setCurrentSelectedRefund] = useState(null);

  useEffect(() => {
    getAcceptedRefundsList();
  }, [currentPage]);

  const getAcceptedRefundsList = async () => {
    try {
      setLoading(true);
      let { data } = await ApiCalls.getRefunds("accepted", currentPage);
      console.log(data);
      setRefunds(data.data.data);
      setTotalPages(data.data.count);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handlePageChange = (pageNumber, pageLimit) => {
    setCurrentPage(pageNumber - 1);
  };

  return (
    <>
      <CardsWrapper>
        {!loading && (
          <>
            {refunds.length === 0 ? (
              <EmptyComponent message="No data to show" />
            ) : null}
            {refunds.map((item) => {
              return (
                <RefundCard
                  id={item._id}
                  name={item.name}
                  key={item._id}
                  email={item.email}
                  transactionId={item.transactionData?.transactionId}
                  details={item}
                  profilePic={item.profilePic}
                />
              );
            })}
          </>
        )}
      </CardsWrapper>
      {loading && <Spin spinning={true} size="large" />}

      {!loading && (
        <PaginationWrapper>
          <Pagination
            defaultCurrent={1}
            total={totalPages}
            pageSize={5}
            hideOnSinglePage={true}
            onChange={handlePageChange}
            current={currentPage + 1}
          />
        </PaginationWrapper>
      )}
    </>
  );
};

export default AcceptedRefundsList;
