import React, { useState } from "react";
import {
    FormListContainer,
    FormWrapper,
    HeadingText,
    SubmitButton,
    SubText,
    Wrapper,
    StyledFormItem,
    Text,
} from "./styledComponent";
import {Divider, Button, message } from "antd";
import ApiCalls from "../../apis/ApiCalls";
import { Radio, Space } from 'antd';
import { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";

const IndividualAnswers = () => {
    const [idChekedFromRequest,setIdChekedFromRequest] = useState(1);
    const [singleFormDetails, setSingleFormDetails] = useState({});  
    const [loading, setLoading] = useState(false);
    const param = useParams();
    const history = useHistory();

//Get single form submitted by single patient API
const SingleFormSubmittedBySinglePatient = () => {
    ApiCalls.getSingleFormSubmittedBySinglePatient(param.formId, param.patientId)
        .then((res) => {
            setLoading(false);
            if (res?.data?.data) {
                setSingleFormDetails(res.data.data);
                message.success("SingleFormSubmittedBySinglePatient Details is here");
            }
        })
        .catch((error) => {
            setSingleFormDetails({});
            setLoading(false);
            if (error.response) {
                message.info(error.response.data.message);
            } else if (error.request) {
                message.error("Server not responding");
            } else {
                message.error("Something went wrong");
            }
        });
}

useEffect(() => {
    SingleFormSubmittedBySinglePatient();
}, [])

    return (
        <FormListContainer>
            <FormWrapper IndividualAnswers={true}>
                <div style={{display:'flex', flexDirection:"column", justifyContent:'flex-start'}}>
                    <HeadingText Sub={true}>{singleFormDetails.formName}</HeadingText>
                    <SubText Sub={true}>Submission time: {moment(singleFormDetails?.createdAt).format("HH:MM a, DD-MM-YYYY")}</SubText>
                </div>
                <div>

                </div>
                
            </FormWrapper>

            <Divider />
            {singleFormDetails?.questionsList?.map((item, index) => (
                <Wrapper key={item._id}>
                    <Radio.Group>
                        <SubText>Q. {index + 1} {item.question}</SubText>
                        <Space direction="vertical" style={{ marginTop: 5, marginBottom: 10 }}>
                            {
                                item?.options?.map((item, index) => (

                                    <Radio 
                                    key={item._id}
                                    onChange={()=>setIdChekedFromRequest(index)} 
                                    checked={index===idChekedFromRequest}>
                                    {item}
                                    </Radio>

                                ))
                            }
                        </Space>
                    </Radio.Group>
                    <Text>{item.answerType}</Text>
                </Wrapper>
            ))}


        </FormListContainer>
    )
}
export default IndividualAnswers;