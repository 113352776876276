import React, { useEffect } from 'react'
import CustomText from '../../Components/CustomText';
import { Card, Row, Col, Button } from 'antd';
import ProfileAvatar from '../../Components/ProfileAvatar';
import { DeleteOutlined } from '@ant-design/icons';
const CardItem = ({ item = {}, handleRemoveItem, handleEditItem }) => {
    return (
        // <Wrapper>
        <Card>
            <Row
                align='middle'
                justify="space-between"
            >
                <Col>
                    <Row gutter={8} align='middle'>
                        <Col>
                            <ProfileAvatar
                                url={item.profilePic}
                                size={60}
                            />
                        </Col>
                        <Col>
                            <Row>
                                <CustomText fontWeight="bold" size="16px">{item.name}</CustomText>
                            </Row>
                            <Row>
                                {item.status === 'active' && (
                                    <CustomText  color="green" fontWeight="600">
                                        Active
                                    </CustomText>
                                )}

                                {item.status === 'pending' && (
                                    <CustomText  color="orange" fontWeight="600">
                                        Pending
                                    </CustomText>
                                )}
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row gutter={16} align="middle">
                        <Col>
                            {/* <PrimaryButton> */}
                            <Button
                                style={{ fontSize: '16px' }}
                                icon={<DeleteOutlined />}
                                onClick={handleRemoveItem}
                                disabled={item.status === 'pending'}
                                shape="round"
                                size="large"
                            >
                                Remove
                                    </Button>
                            {/* </PrimaryButton> */}
                        </Col>
                        <Col>
                            {/* <PrimaryButton> */}
                            <Button
                                type='primary'
                                style={{ fontSize: '16px' }}
                                onClick={handleEditItem}
                                size="large"
                                shape="round"
                            >
                                View/Edit
                                    </Button>
                            {/* </PrimaryButton> */}
                        </Col>
                    </Row>
                </Col>

            </Row>
        </Card>
        // </Wrapper>

    )
}
export default CardItem