import React, { useState } from "react";
import { message, Upload } from "antd";
import {
  UploadContainer,
  UploadText,
  UploadInfo,
  DownloadLink,
  StyledModal,
} from "./styledComponents";
import ApiCalls from "../../apis/ApiCalls";

const ImportMedicines = ({
  showImportMedicine,
  setShowImportMedicine,
  fetchMedicineList,
  samplePdfUrl,
}) => {
  const [file, setFile] = useState(null);

  const beforeUpload = (file) => {
    if (file.type === "text/csv") {
      const formData = new FormData();
      formData.append("uploadFile", file);
      setFile(formData);
    } else {
      message.error("Uploaded file is not of the type .csv");
    }
  };

  const uploadFile = () => {
    if (file) {
      ApiCalls.uploadMedicinesFile(file)
        .then((response) => {
          if (response.data.status) {
            message.success("Succesfully added medicines to medicine library");
            setShowImportMedicine(false);
            setFile(null);
            fetchMedicineList();
          }
        })
        .catch((error) => {
          console.log(error);
          message.error(
            error.response
              ? error?.response?.data?.data?.message
              : "No Response From Server",
            5
          );
        });
    } else {
      message.error("Select file before upload");
    }
  };

  return (
    <>
      <StyledModal
        visible={showImportMedicine}
        closable={false}
        title="Add New Medicine"
        centered={true}
        onCancel={() => {
          setFile(null);
          setShowImportMedicine(false);
        }}
        key={showImportMedicine}
        okText="Upload"
        onOk={() => uploadFile()}
      >
        <>
          <UploadInfo>
            Upload csv file in the format of the sample file given below
          </UploadInfo>
          {file ? (
            <UploadContainer>
              <UploadText>File ready for upload</UploadText>
            </UploadContainer>
          ) : (
            <Upload
              name="file"
              showUploadList={false}
              beforeUpload={beforeUpload}
              customRequest={() => {}}
            >
              <UploadContainer>
                <UploadText>Click here to upload file</UploadText>
              </UploadContainer>
            </Upload>
          )}
          <DownloadLink>
            <a href={samplePdfUrl}>Download sample file</a>
          </DownloadLink>
        </>
      </StyledModal>
    </>
  );
};

export default ImportMedicines;
