import React from "react";
import styled from "styled-components";
import { Typography } from "antd";

const CustomLayout = ({
  children,
  mainTitle,
  headerComponent,
  makePaddingZero,
}) => {
  const Main = styled.div`
    padding: ${({ makePaddingZero }) => (makePaddingZero ? "3%  0px" : "3% 5%")};
    .ant-input {
      background-color: transparent;
    }
    .ant-input-search {
      background-color: transparent;
    }
    .content-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .content {
      padding: 8px;
    }
    .content > div {
      width: 100%;
    }
  `;

  const MainTitle = styled(Typography)`
    font-family: "Montserrat", sans-serif;
    margin-bottom: 0.5em;
    color: #5472a3;
    font-weight: bold;
    font-size: 30px;
    line-height: 37px;
    padding-left: 8px;
  `;

  return (
    <Main makePaddingZero={makePaddingZero}>
      <div className="content-header">
        <MainTitle>{mainTitle}</MainTitle>
        {headerComponent}
      </div>

      <div className="content">{children}</div>
    </Main>
  );
};

export default CustomLayout;
