import styled from 'styled-components';
import { Button } from 'antd';

const CustomButton = styled(Button)`
font-family: Montserrat;
font-size:13px;
font-weight: 600;
min-height:40px;
min-width:160px;
`
export default CustomButton