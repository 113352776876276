import React from "react";

function ManageUserIcon({ height = "512", width = "512", active }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 731.02 636.78"
    >
      {active ? (
        <>
          <path
            fill="none"
            d="M365.51 205.58a81.38 81.38 0 10-81.38-81.37 81.47 81.47 0 0081.38 81.37zM130.07 339.81H96.95c-29.84 0-54.11 24-54.11 53.51v154.93h129.92c0-80.28-3-102.76 11.42-140.26v-14.67c0-29.51-24.27-53.51-54.11-53.51zM114.22 205.58a35.69 35.69 0 10-35.69-35.69 35.73 35.73 0 0035.69 35.69zM616.8 205.58a35.69 35.69 0 10-35.69-35.69 35.73 35.73 0 0035.69 35.69zM688.18 393.32c0-29.51-24.28-53.51-54.11-53.51h-33.12c-29.84 0-54.11 24-54.11 53.51V408c14.38 37.44 11.42 59.39 11.42 140.26 137.91 0 129.93.25 129.93-.47z"
            className="a"
          ></path>
          <path
            fill="#efa322"
            d="M385.47 339.81h-39.92c-71.66 0-130 58.3-130 129.94v117a7.19 7.19 0 007.17 7.18h285.54a7.19 7.19 0 007.17-7.18v-117c-.01-71.64-58.31-129.94-129.96-129.94z"
            className="b"
          ></path>
          <path
            fill="#204983"
            d="M365.51 248.43a124.21 124.21 0 10-124.22-124.22 124.35 124.35 0 00124.22 124.22zm0-205.61a81.38 81.38 0 11-81.38 81.4 81.49 81.49 0 0181.38-81.41zM616.8 248.43a78.52 78.52 0 10-78.53-78.54 78.62 78.62 0 0078.53 78.54zm0-114.23a35.69 35.69 0 11-35.69 35.69 35.73 35.73 0 0135.69-35.7z"
            className="c"
          ></path>
          <path
            fill="#c8881d"
            d="M634.07 297h-33.12a97.1 97.1 0 00-88.08 56.18A172.36 172.36 0 00385.47 297h-39.92a172.35 172.35 0 00-127.4 56.18A97.11 97.11 0 00130.07 297H96.95C43.49 297 0 340.21 0 393.32v155.93a42 42 0 0042 41.85h131a50 50 0 0049.81 45.67h285.45a50.05 50.05 0 0049.81-45.67h129.46A43.45 43.45 0 00731 547.79V393.32c0-53.11-43.47-96.32-96.93-96.32zM508.26 594h-285.5a7.19 7.19 0 01-7.17-7.18v-117c0-71.64 58.3-129.94 130-129.94h39.92c71.66 0 130 58.3 130 129.94v117a7.19 7.19 0 01-7.25 7.18zM184.18 408c-14.4 37.5-11.42 60-11.42 140.26H42.83V393.32c0-29.51 24.28-53.51 54.11-53.51h33.13c29.84 0 54.11 24 54.11 53.51zm374.08 140.25c0-80.87 3-102.82-11.42-140.26v-14.67c0-29.51 24.28-53.51 54.11-53.51h33.13c29.84 0 54.11 24 54.11 53.51v154.47c-.01.71 7.98.46-129.93.46z"
            className="d"
          ></path>
          <path
            fill="#204983"
            d="M114.22 248.43a78.52 78.52 0 10-78.53-78.54 78.62 78.62 0 0078.53 78.54zm0-114.23a35.69 35.69 0 11-35.69 35.69 35.73 35.73 0 0135.69-35.7z"
            className="c"
          ></path>
          <circle cx="616.8" cy="169.9" r="38.78" className="e"></circle>
          <circle cx="114.22" cy="169.9" r="38.78" className="e"></circle>
          <circle cx="365.51" cy="124.21" r="81.38" className="e"></circle>
        </>
      ) : (
        <>
          <path
            fill="#6a6a6a"
            d="M365.51 248.43a124.22 124.22 0 10-124.22-124.21 124.36 124.36 0 00124.22 124.21zm0-205.6a81.38 81.38 0 11-81.38 81.38 81.47 81.47 0 0181.38-81.38zM616.8 248.43a78.53 78.53 0 10-78.53-78.53 78.62 78.62 0 0078.53 78.53zm0-114.22a35.69 35.69 0 11-35.7 35.69 35.73 35.73 0 0135.7-35.69zM634.07 297h-33.12a97.09 97.09 0 00-88.08 56.19A172.37 172.37 0 00385.47 297h-39.92a172.37 172.37 0 00-127.4 56.19A97.09 97.09 0 00130.07 297H96.95C43.49 297 0 340.2 0 393.34v155.92a42 42 0 0042 41.84h131a50.06 50.06 0 0049.8 45.69h285.46a50.06 50.06 0 0049.8-45.69h129.47A43.45 43.45 0 00731 547.78V393.34c0-53.14-43.47-96.34-96.93-96.34zM42.83 393.34c0-29.51 24.27-53.53 54.11-53.53h33.12c29.84 0 54.11 24 54.11 53.53V408c-14.4 37.5-11.42 60-11.42 140.26H42.83zm472.59 193.44a7.18 7.18 0 01-7.17 7.17H222.76a7.18 7.18 0 01-7.17-7.17v-117a130.1 130.1 0 01130-130h39.92a130.1 130.1 0 01130 130zm172.76-39c0 .74 8 .49-129.93.49 0-80.88 3-102.83-11.42-140.26v-14.67c0-29.51 24.27-53.53 54.11-53.53h33.12c29.84 0 54.11 24 54.11 53.53zM114.22 248.43a78.53 78.53 0 10-78.53-78.53 78.62 78.62 0 0078.53 78.53zm0-114.22a35.69 35.69 0 11-35.69 35.69 35.73 35.73 0 0135.69-35.69z"
            className="a"
          ></path>
        </>
      )}
    </svg>
  );
}

export default ManageUserIcon;
