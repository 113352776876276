import React from "react";
import WaitingForApprovalList from "./PendingDoctorsList";
import AcceptedDoctorsList from "./AcceptedDoctorsList";
import RejectedDoctorsList from "./RejectedDoctorsList";

let ListController = ({ selectedTab, searchKey }) => {
  return (
    <>
      {(() => {
        switch (selectedTab) {
          case "waiting-for-approval":
            return <WaitingForApprovalList searchTerm={searchKey} />;
          case "accepted":
            return <AcceptedDoctorsList searchTerm={searchKey} />;
          case "rejected":
            return <RejectedDoctorsList searchTerm={searchKey} />;
        }
      })()}
    </>
  );
};

export default ListController;
