import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Row,
  Col,
  Button,
  Form,
  Select,
  Input,
  Radio,
  DatePicker,
  Spin,
  message,
} from "antd";
import moment from "moment";
import ApiCalls from "../../../apis/ApiCalls";
import ProfileTitle from "../ActionModal/ProfileTitle";

const StyledCol = styled(Col)`
  .ant-radio-button-wrapper {
    width: 200px;
    display: flex;
    justify-content: center;
    /* height: 50px; */
    align-items: center;
    /* margin: 12px */
    border-radius: 5px;
    height: 57px;
    text-transform: capitalize;
  }
  .ant-radio-group {
    display: flex;
    width: 100%;
  }
  .ant-radio-button-wrapper-checked {
    color: "#000000";
    height: 57px;
  }
  .ant-picker {
    width: 100%;
    border-radius: 5px;
    padding: 15.5px;
    text-transform: capitalize;
  }
  .ant-btn {
    border-radius: 25px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    width: 170px;
    height: 40px;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    color: #ffffff;
  }

  .ant-form-item-label > label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #6b6b6b;
    padding-left: 15px;
    text-transform: capitalize;
  }

  .ant-select-multiple .ant-select-selector,
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
    padding: 10.5px;
    text-transform: capitalize;
  }

  .ant-form-item-label > label.ant-form-item-required::before {
    display: none;
  }

  .ant-input-lg {
    padding: 15.5px 15px;
    font-size: 16px;
    color: #2f2f2f;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    text-transform: capitalize;
  }
`;

const StyledInput = styled(Input)`
  border-radius: 5px;
`;

const EditVatForm = ({
  selectedDoctor,
  setShowVatModal,
  getAcceptedDoctors,
}) => {
  const [loading, setLoading] = useState(false);
  const [doctorData, setDoctorData] = useState([]);
  const [form] = Form.useForm();

  const onFinish = (val) => {
    val.doctorId = doctorData._id;
    ApiCalls.editVat(val)
      .then((response) => {
        if (response.data.status) {
          setLoading(true);
          message.success("Update VAT details successfully");
          setShowVatModal(false);
          getAcceptedDoctors();
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);

        message.error(
          error.response
            ? error.response.data.data.message
            : "No Response From Server",
          5
        );
      });
  };

  const fetchDoctorDetails = async () => {
    try {
      setLoading(true);
      let { data } = await ApiCalls.fetchDoctorDetails(selectedDoctor);
      form.setFieldsValue({ ...data.data });
      setDoctorData(data.data);
      setLoading(false);
    } catch (e) {}
  };

  useEffect(() => {
    fetchDoctorDetails();
  }, [selectedDoctor]);

  return (
    <Spin spinning={loading}>
      <ProfileTitle
        doctor={doctorData}
        handleModelConfirm={() => form.submit()}
        vat
      />
      <br />
      <br />
      <Row>
        <p>Currency: {doctorData.currency}</p>
      </Row>
      <Row>
        <p>Consultation Fee: {doctorData.consultationFee}</p>
      </Row>

      <br />
      <br />

      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={24}>
          <StyledCol
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 10 }}
            lg={{ span: 12 }}
          >
            <Form.Item
              name="vatPercentage"
              label="Vat percentage*"
              rules={[
                {
                  required: true,
                  message: "Vat percentage is required",
                },
                {
                  pattern: new RegExp("^[1-9]?[0-9]{1}$|^100$"),
                  message: "Please use numbers within 1 and 100",
                },
              ]}
              hasFeedback
            >
              <StyledInput
                placeholder="Vat percentage"
                size="large"
                name="vatPercentage"
              />
            </Form.Item>
          </StyledCol>
        </Row>
        <Row gutter={24}>
          <StyledCol
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <Form.Item
              name="platformAmountType"
              label="Platform amount type*"
              rules={[
                {
                  required: true,
                  message: "Platform amount type is required",
                },
              ]}
            >
              <Radio.Group size="large">
                <Radio.Button value="fixed">Fixed</Radio.Button>
                <Radio.Button value="percentage">Percentage</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </StyledCol>
        </Row>
        <Row gutter={24}>
          <StyledCol
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 10 }}
            lg={{ span: 12 }}
          >
            <Form.Item
              label="Platform amount*"
              name="platformAmount"
              rules={[
                {
                  required: true,
                  message: "Platform amount is required",
                },
                {
                  pattern: new RegExp("^(0|[1-9][0-9]*)$"),
                  message: "Please use numbers",
                },
              ]}
              hasFeedback
            >
              <StyledInput
                name="platformAmount"
                placeholder="Platform amount"
                size="large"
                style={{ textTransform: "lowercase" }}
              />
            </Form.Item>
          </StyledCol>
        </Row>
        <Row gutter={24}></Row>
      </Form>
    </Spin>
  );
};

export default EditVatForm;
