import React, { useState, useEffect } from "react";
import { Input, Dropdown, Space, Menu, notification, Spin, AutoComplete } from "antd";
import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import ApiCalls from "../../apis/ApiCalls";
import { 
  CreatePlanWrapper, 
  Label, 
  Title, 
  StyledMenuItem, 
  StyledMenuItemText,
  StyledDropdownInput,
  CreateButton,
  ButtonTitle,
  StyledSpin
} from "./styledComponents";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const CreatePlan = ({ closeModal, type, subscriptionId }) => {
  const [planName, setPlanName] = useState('');
  const [planType, setPlanType] = useState({ label: 'General', value: 'generic' });
  const [planPrice, setPlanPrice] = useState('');
  const [subscriptionRank, setSubscriptionRank] = useState('');
  const [doctorCount, setDoctorCount] = useState('');
  const [discountPercentage, setDiscountPercentage] = useState('');
  const [planDuration, setPlanDuration] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [countryName, setCountryName] = useState('');
  const [countryCurrency, setCountryCurrency] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [countriesSearchList, setCountriesSearchList] = useState([]);
  const [countrySearchText, setCountrySearchText] = useState('');
  const [countryCodeSearchList, setCountryCodeSearchList] = useState([]);
  const [countryCodeSearchText, setCountryCodeSearchText] = useState('');
  const [currencySearchText, setCurrencySearchText] = useState('');
  const [currencies, setCurrencies] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch subscription details
  const getSubscriptionDetails = async() => {
    setLoading(true);
    try {
      let { data } = await ApiCalls.getSingleSubscriptionDetails(subscriptionId);
      if(data.data) {
        setPlanName(data.data?.planName);
        setPlanType({ label: data.data?.planType === "custom" ? "Custom" : "General", value: data.data?.planType });
        setPlanPrice(data.data?.planPrice?.toString());
        setSubscriptionRank(data.data?.subscriptionRank?.toString());
        setDoctorCount(data.data?.doctorCount);
        setDiscountPercentage(data.data?.discountPercentage);
        setPlanDuration(data.data?.planDuration);
        if(data?.data?.regionDetails) {
          setCountryCode(data?.data?.regionDetails?.countryCode);
          setCountryCodeSearchText(data?.data?.regionDetails?.countryCode);
          setCountryName(data?.data?.regionDetails?.countryName);
          setCountrySearchText(data?.data?.regionDetails?.countryName);
          setCountryCurrency(data?.data?.regionDetails?.countryCurrency);
          setCurrencySearchText(data?.data?.regionDetails?.countryCurrency);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  // Fetch countries
  const fetchCountries = async() => {
    try {
      let { data } = await ApiCalls.getCountries();
      if(data?.data?.length > 0) {
        setCountries(data.data);
        setCountriesSearchList(data.data);
        setCountryCodeSearchList(data.data);
      } else {
        setCountries([]);
        setCountriesSearchList([]);
        setCountryCodeSearchList([]);
      }
    } catch (error) {
      setCountries([]);
      setCountriesSearchList([]);
      setCountryCodeSearchList([]);
    }
  }

  // Fetch currencies
  const fetchCurrencies = async() => {
    try {
      let { data } = await ApiCalls.fetchCurrencies(currencySearchText);
      if(data?.data?.currencies?.length > 0) {
        setCurrencies(data?.data?.currencies);
      } else {
        setCurrencies([]);
      }
    } catch (error) {
      setCurrencies([]);
    }
  }

  useEffect(() => {
    fetchCountries();
    if(type === "editPlan") {
      getSubscriptionDetails();
    }
  }, []);

  useEffect(() => {
    fetchCurrencies();
  }, [currencySearchText]);

  // On field change
  const onFieldChange = (e) => {
    e.preventDefault();
    let value = e.target.value;
    switch(e.target.name) {
      case 'planName':
        setPlanName(value);
        break;
      case 'planType':
        setPlanType(value);
        break;
      case 'planPrice':
        setPlanPrice(value);
        break;
      case 'subscriptionRank':
        setSubscriptionRank(value);
        break;
      case 'doctorCount':
        setDoctorCount(value);
        break;
      case 'discountPercentage':
        setDiscountPercentage(value);
        break;
      case 'planDuration':
        setPlanDuration(value)
        break;
      default:
        break;
    }
  }

  // Submitting the form
  const submit = async() => {
    try {
      setButtonLoading(true);
      let dat = {
        subscriptionRank,
        planType: planType.value,
        planPrice,
        doctorCount,
        discountPercentage,
        planDuration
      };
      if(type !== "editPlan") {
        dat = {
          ...dat,
          planName,
          countryCode,
          countryName,
          countryCurrency
        }
      }
      let { data } = type === "editPlan" ? await ApiCalls.updateSubscription(subscriptionId, dat) : await ApiCalls.createSubscription(dat)
      if(data) {
        closeModal(true);
      }
      setButtonLoading(false);
    } catch (error) {
      notification.error({
        message: String(error?.response?.data?.data?.message),
        placement: 'topRight'
      });
      setButtonLoading(false);
    }
  }

  // On search
  const onSearch = (value, type) => {
    let temp = [];
    if(type === 'countryName') {
      if(value?.trim('')?.length === 0) {
        temp = [...countries];
      } else {
        countries.forEach(item => {
          if(item.name?.toLowerCase()?.includes(value?.toLowerCase())) {
            temp.push(item);
          }
        });
      }
      setCountriesSearchList(temp);
    } else if(type === "countryCode") {
      if(value?.trim('')?.length === 0) {
        temp = [...countries];
      } else {
        countries.forEach(item => {
          if(item.countryCode?.toLowerCase()?.includes(value?.toLowerCase())) {
            temp.push(item);
          }
        });
      }
      setCountryCodeSearchList(temp);
    } else if(type === "currency") {

    }
  }

  // Choosing country
  const chooseCountry = (value) => {
    setCountryName(value);
    setCountrySearchText(value);
  }

  // Choose country code
  const chooseCountryCode = (value) => {
    setCountryCode(value);
    setCountryCodeSearchText(value);
  }

  // Choose currency
  const chooseCurrency = (value) => {
    setCountryCurrency(value);
    setCurrencySearchText(value);
  }

  // Plan type dropdown menu
  const menu = (
    <Menu>
      <StyledMenuItem onClick={() => setPlanType({ label: 'General', value: 'generic' })}>
        <StyledMenuItemText>General</StyledMenuItemText>
      </StyledMenuItem>
      <StyledMenuItem onClick={() => setPlanType({ label: 'Custom', value: 'custom' })}>
        <StyledMenuItemText>Custom</StyledMenuItemText>
      </StyledMenuItem>
    </Menu>
  );

  return (
    <CreatePlanWrapper>

      {/* Title */}
      <Title>{type === "editPlan" ? "Update" : "Create"} Plan</Title>

      {loading ? (
        <StyledSpin />
      ) : (
        <>

          {/* Plan name */}
          <Label>Plan Name</Label>
          <Input 
            placeholder='Plan name' 
            name='planName'
            value={planName} 
            onChange={onFieldChange} 
            disabled={type === "editPlan"}
          />

          {/* Plan type */}
          <Label>Plan Type</Label>
          <Dropdown overlay={menu} trigger={['click']}>
            <StyledDropdownInput>
              <StyledMenuItemText>{planType.label}</StyledMenuItemText>
              <Space><DownOutlined /></Space>
            </StyledDropdownInput>
          </Dropdown>

          <Label>Plan Price (₹)</Label>
          <Input 
            placeholder='Plan price' 
            name='planPrice'
            value={planPrice} 
            onChange={onFieldChange} 
            type="number"
          />

          {/* Subscription rank */}
          <Label>Subscription Rank</Label>
          <Input 
            placeholder='Subscription rank' 
            name='subscriptionRank'
            value={subscriptionRank} 
            onChange={onFieldChange} 
            type="number"
          />

          {/* Doctor count */}
          <Label>Therapist Count</Label>
          <Input 
            placeholder='Therapist Count' 
            name='doctorCount'
            value={doctorCount} 
            onChange={onFieldChange} 
          />

          {/* Discount Percentage */}
          <Label>Discount Percentage</Label>
          <Input 
            placeholder='Discount percentage' 
            name='discountPercentage'
            value={discountPercentage} 
            onChange={onFieldChange} 
          />

          {/* Plan duration */}
          <Label>Plan Duration</Label>
          <Input 
            placeholder='Plan Duration' 
            name='planDuration'
            value={planDuration} 
            onChange={onFieldChange} 
          />

          {/* Country Code */}
          <Label>Country Code</Label>
          <AutoComplete
            style={{ width: '100%' }}
            value={countryCodeSearchText}
            options={countryCodeSearchList?.map(item => ({ value: item.countryCode, label: item.countryCode }))}
            onSelect={chooseCountryCode}
            onSearch={value => onSearch(value, 'countryCode')}
            onChange={value => setCountryCodeSearchText(value)}
            placeholder='Country Code'
            disabled={type === "editPlan"}
          />

          {/* Country Name */}
          <Label>Country Name</Label>
          <AutoComplete
            style={{ width: '100%' }}
            value={countrySearchText}
            options={countriesSearchList?.map(item => ({ value: item.name, label: item.name }))}
            onSelect={chooseCountry}
            onSearch={value => onSearch(value, 'countryName')}
            onChange={value => setCountrySearchText(value)}
            placeholder="Country Name"
            disabled={type === "editPlan"}
          />

          {/* Country Currency */}
          <Label>Country Currency</Label>
          {/* <Input 
            placeholder='Country Currency' 
            name='countryCurrency'
            value={countryCurrency} 
            onChange={onFieldChange} 
            disabled={type === "editPlan"}
          /> */}
          <AutoComplete
            style={{ width: '100%' }}
            value={currencySearchText}
            options={currencies?.map(item => ({ value: item.code, label: item.code }))}
            onSelect={chooseCurrency}  
            onChange={value => setCurrencySearchText(value)}
            placeholder='Country Currency'
            disabled={type === "editPlan"}
          />

          {/* Create / update button */}
          <CreateButton 
            type="modalButton"
            disabled={
              planName?.trim('')?.length === 0 ||
              subscriptionRank?.trim('')?.length === 0 ||
              planPrice?.trim('')?.length === 0 ||
              doctorCount?.trim('')?.length === 0 ||
              discountPercentage?.trim('')?.length === 0 ||
              planDuration?.trim('')?.length === 0 ||
              countrySearchText?.trim('')?.length === 0 ||
              countrySearchText !== countryName ||
              countryCodeSearchText?.trim('')?.length === 0 ||
              countryCodeSearchText !== countryCode ||
              currencySearchText?.trim('')?.length === 0 ||
              currencySearchText !== countryCurrency
            }
            onClick={submit}
          >
            {buttonLoading ? (
              <Spin indicator={antIcon} />
            ) : (
              <ButtonTitle>{type === "editPlan" ? "Update" : "Create"}</ButtonTitle>
            )}
          </CreateButton>

        </>
      )}

    </CreatePlanWrapper>
  );
}

export default CreatePlan;