import React from 'react'
import styled from 'styled-components'
import CustomText from '../../Components/CustomText'
import CalendarFilterForm from './CalendarFilterForm'

const PaymentReport = type => {
  return (
    <>
      <div>
        <CustomText color='#5472a3' size='1vw' fontWeight='600'>
          Payment Report
        </CustomText>
      </div>

      <div>
        <CalendarFilterForm type="payment"/>
      </div>
    </>
  )
}

export default PaymentReport
