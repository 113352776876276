import React, { useState, useEffect } from "react";
import { Input, notification, Spin, DatePicker, Menu, Dropdown, Space, Select, Avatar } from "antd";
import moment from 'moment/moment';
import { LoadingOutlined, DownOutlined } from '@ant-design/icons';
import ApiCalls from "../../apis/ApiCalls";
import { 
  CreateCouponWrapper, 
  Label, 
  Title,
  CreateButton,
  ButtonTitle,
  StyledSpin,
  StyledMenuItemText,
  StyledMenuItem,
  StyledDropdownInput
} from "./styledComponents";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const CreateCoupon = ({ closeModal, type, couponId }) => {
  const [couponCode, setCouponCode] = useState('');
  const [startTime, setStartTime] = useState(moment(new Date()));
  const [endTime, setEndTime] = useState(moment(new Date()));
  const [maxAvailsPerUser, setMaxAvailsPerUser] = useState('');
  const [minValue, setMinValue] = useState('');
  const [discountPercentage, setDiscountPercentage] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [specialCoupon, setSpecialCoupon] = useState({ label: 'No', value: false });
  const [providers, setProviders] = useState([]);
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [providersLoading, setProvidersLoading] = useState(false);
  const [providersPageNo, setProvidersPageNo] = useState(0);
  const [providerSearchValue, setProviderSearchValue] = useState('');
  const [providersTotalPage, setProvidersTotalPage] = useState(1);

  const getCouponDetails = async() => {
    setLoading(true);
    try {
      let { data } = await ApiCalls.getSingleCouponDetails(couponId);
      if(data.data?.couponDetail) {
        let details = data.data?.couponDetail;
        setCouponCode(details?.couponCode);
        setStartTime(moment(details?.startTime));
        setEndTime(moment(details?.endTime));
        setMaxAvailsPerUser(details?.tnC?.maxAvailsPerUser?.toString());
        setMinValue(details?.tnC?.minValue?.toString());
        setDiscountPercentage(details?.discountPercentage?.toString());
        setDescription(details?.tnC?.description);
        if(details?.tnC?.isSpecialCoupon) {
          setSpecialCoupon({ label: 'Yes', value: true });
          let temp = [];
          details.tnC.specialCouponDetails[0].applicableList.forEach(item => {
            temp.push({
              label: item.name,
              value: JSON.stringify({
                _id: item._id,
                email: item.email,
                phoneNumber: item.phoneNumber,
                profilePic: item.profilePic,
                name: item.name
              })
            });
          })
          setSelectedProviders(temp);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  // Get accepted providers
  const getAcceptedDoctors = async(pageNo, searchValue) => {
    try {
      setProvidersLoading(true);
      let { data } = await ApiCalls.getDoctors(
        "active",
        10,
        searchValue,
        pageNo
      );
      let temp = [];
      data.data.doctors.forEach(item => {
        temp.push({
          label: item.name,
          value: JSON.stringify({
            _id: item._id,
            email: item.email,
            phoneNumber: item.phoneNumber,
            profilePic: item.profilePic,
            name: item.name
          })
        });
      });
      if(providersPageNo === 0) {
        setProviders(temp);
      } else {
        setProviders(providers => [...providers, ...temp]);
      }
      setProvidersTotalPage(Math.ceil(data.data.doctorCount/10));
      setProvidersLoading(false);
    } catch (err) {
      // Error
    }
  };

  useEffect(() => {
    if(type === "editCoupon") {
      getCouponDetails();
    }
  }, []);

  useEffect(() => {
    if(providersPageNo !== 0) {
      getAcceptedDoctors(providersPageNo, providerSearchValue);
    }
  }, [providersPageNo]);

  useEffect(() => {
    if(type !== "editCoupon") {
      setProvidersPageNo(0);
      setProvidersTotalPage(1);
      getAcceptedDoctors(0, providerSearchValue);
    }
  }, [providerSearchValue]);

  // On field change
  const onFieldChange = (e) => {
    e.preventDefault();
    let value = e.target.value;
    switch(e.target.name) {
      case 'couponCode':
        setCouponCode(value);
        break;
      case 'startTime':
        setStartTime(value);
        break;
      case 'endTime':
        setEndTime(value);
        break;
      case 'maxAvailsPerUser':
        setMaxAvailsPerUser(value);
        break;
      case 'minValue':
        setMinValue(value);
        break;
      case 'discountPercentage':
        setDiscountPercentage(value);
        break;
      case 'description':
        setDescription(value);
        break;
      default:
        break;
    }
  }

  // On update or create coupon
  const submit = async() => {
    try {
      setButtonLoading(true);
      let dat = {
        maxAvailsPerUser,
        startTime: moment(startTime).format("YYYY-MM-DD"),
        endTime: moment(endTime).format("YYYY-MM-DD"),
        minValue,
        discountPercentage
      };
      if(type !== "editCoupon") {
        dat = {
          ...dat,
          couponCode,
          isSpecialCoupon: specialCoupon.value,
          description
        }
        if(specialCoupon.value) {
          dat.applicableTo = 'provider';
          let temp = [];
          selectedProviders.forEach(item => {
            let tempItem = JSON.parse(item.value);
            temp.push({
              _id: tempItem._id,
              email: tempItem.email,
              phoneNumber: tempItem.phoneNumber,
              profilePic: tempItem.profilePic,
              name: tempItem.name
            }) 
          });
          dat.applicableList = temp;
        }
      }
      let { data } = type === "editCoupon" ? await ApiCalls.updateCoupon(couponId, dat) : await ApiCalls.createCoupon(dat)
      if(data) {
        closeModal(true);
      }
      setButtonLoading(false);
    } catch (error) {
      notification.error({
        message: error?.response?.data?.data?.message,
        placement: 'topRight'
      });
      setButtonLoading(false);
    }
  }

  // Start time change
  const startTimeChange = (date, dateString) => {
    setStartTime(date);
  }

  // End time change
  const endTimeChange = (date, dateString) => {
    setEndTime(date);
  }

  // On providers list scroll
  const onProvidersSelectScroll = (e) => {
    if (!providersLoading && (e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight) && providersPageNo < providersTotalPage) {
      setProvidersPageNo(providersPageNo => providersPageNo + 1);
    }
  }

  // Special coupon dropdown menu
  const menu = (
    <Menu>
      <StyledMenuItem onClick={() => setSpecialCoupon({ label: 'Yes', value: true })}>
        <StyledMenuItemText>Yes</StyledMenuItemText>
      </StyledMenuItem>
      <StyledMenuItem onClick={() => setSpecialCoupon({ label: 'No', value: false })}>
        <StyledMenuItemText>No</StyledMenuItemText>
      </StyledMenuItem>
    </Menu>
  );

  return (
    <CreateCouponWrapper>

      {/* Title */}
      <Title>{type === "editCoupon" ? "Update" : "Create"} Coupon</Title>

      {loading ? (
        <StyledSpin />
      ) : (
        <>

          {/* Coupon code */}
          <Label>Coupon Code</Label>
          <Input 
            placeholder='Coupon code' 
            name='couponCode'
            value={couponCode} 
            onChange={onFieldChange} 
            disabled={type === "editCoupon"}
          />

          {/* Start time */}
          <Label>Start Time</Label>
          <DatePicker style={{ width: '100%' }} onChange={startTimeChange} picker="date" value={startTime} />

          {/* End time */}
          <Label>End Time</Label>
          <DatePicker style={{ width: '100%' }} onChange={endTimeChange} picker="date" value={endTime} />

          {/* Maximum avails per user */}
          <Label>Maximum avails per user</Label>
          <Input 
            placeholder='Maximum avails per user' 
            name='maxAvailsPerUser'
            value={maxAvailsPerUser} 
            onChange={onFieldChange} 
            type="number"
          />

          {/* Minimum value */}
          <Label>Minimum Value</Label>
          <Input 
            placeholder='Minimum Value' 
            name='minValue'
            value={minValue} 
            onChange={onFieldChange} 
            type="number"
          />

          {/* Discount Percentage */}
          <Label>Discount Percentage</Label>
          <Input 
            placeholder='Discount percentage' 
            name='discountPercentage'
            value={discountPercentage} 
            onChange={onFieldChange} 
          />

          {/* Special Coupon */}
          <Label>Special Coupon (Only applicable for providers)</Label>
          <Dropdown overlay={menu} trigger={['click']} disabled={type === "editCoupon"}>
            <StyledDropdownInput>
              <StyledMenuItemText>{specialCoupon.label}</StyledMenuItemText>
              <Space><DownOutlined /></Space>
            </StyledDropdownInput>
          </Dropdown>

          {/* Providers for special coupon */}
          {specialCoupon.value ? (
            <>
              <Label>Providers</Label>
              <Select
                mode="multiple"
                size='middle'
                placeholder="Choose providers for special coupon"
                onChange={value => setSelectedProviders(value)}
                style={{ width: '100%' }}
                onPopupScroll={onProvidersSelectScroll}
                searchValue={providerSearchValue}
                value={selectedProviders}
                onSearch={value => setProviderSearchValue(value)}
                filterOption={false}
                labelInValue
                disabled={type === 'editCoupon'}
              >
                {providers.map(item => {
                  let data = JSON.parse(item.value);
                  return (
                    <Select.Option value={item.value} key={data._id}>
                      <Avatar src={data.profilePic} size={20} style={{ marginTop: '-3px', marginRight: '5px' }} /> {item.label}, {data.email}, {data.phoneNumber}
                    </Select.Option>
                  )
                })}
              </Select>
            </>
          ) : null}

          {/* Coupon description */}
          <Label>Coupon Description</Label>
          <Input 
            placeholder='Coupon description' 
            name='description'
            value={description} 
            onChange={onFieldChange} 
            disabled={type === "editCoupon"}
          />

          {/* Create / update button */}
          <CreateButton 
            type="modalButton"
            disabled={
              couponCode?.trim('')?.length === 0 ||
              maxAvailsPerUser?.trim('')?.length === 0 ||
              minValue?.trim('')?.length === 0 ||
              discountPercentage?.trim('')?.length === 0 ||
              description?.trim('')?.length === 0 ||
              (specialCoupon.value && selectedProviders.length === 0)
            }
            onClick={submit}
          >
            {buttonLoading ? (
              <Spin indicator={antIcon} />
            ) : (
              <ButtonTitle>{type === "editCoupon" ? "Update" : "Create"}</ButtonTitle>
            )}
          </CreateButton>

        </>
      )}

    </CreateCouponWrapper>
  );
}

export default CreateCoupon;