import styled from "styled-components";
import { Radio, Form } from "antd";

export const FormListContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 100%;
  height: auto;
  padding-right: 30px;
  margin-top:25px;
`;

export const HeadingText = styled.h2`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  color: #03183C;
  text-transform: capitalize;
  width: ${(props) => (props.Sub ? '50%' : 'none')};
`;

export const SubmitButton = styled.div`
  font-family: "Montserrat", sans-serif;

  .ant-btn-primary {
    background: ${(props) => (props.Sub ? '#fff' : props.delete ? '#fff' : '#32AFBF')};
    border: ${(props) => (props.Sub ? '1' : '')};
    border-radius: 5px;
    border-color: ${(props) => (props.Sub ? '#32AFBF' : props.delete ? '#cc0000' : '')};
    height: 44px;
    font-size: 14px;
    width: 150px;
    align-items: center;
    font-weight: 700;
    margin-right:  ${(props) => (props.Sub ? '10px' : '14px')};
    color: ${(props) => (props.Sub ? '#32AFBF': props.delete ? '#cc0000' : '')};
  }
  
  @media only screen and (max-width: 900px) {
    height:"auto";
    float:"none";
    font-size: 15px;
  }
`;

export const FormListBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: space-between;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 50px;
  @media only screen and (max-width: 700px) {
    width: auto;
    justify-content: start;
    flex-direction: column;
  }
`;

export const FormListWrapper = styled.h2`
 display: flex;
 flex-direction: ${(props) => (props.Sub ? 'row' : 'Column')};
 width: 100%;
 .ant-divider-horizontal {
  margin: 0px;
 }
  @media only screen and (max-width: 700px) {
    width: auto;
    justify-content: start;
    flex-direction: column;
  }
`;

export const Wrapper = styled.div`
 display: flex;
 flex-direction: row;
 justify-content: ${(props) => (props.Sub ? 'flex-end' : 'space-between')};
 align-items: ${(props) => (props.Sub ? 'flex-end' : 'flex-start')};
 align-content: center;
 align-self: center;
  width: 100%;
  padding-left: 20px;
  margin-top: ${(props) => (props.profile ? '20px' : 'none')};
  @media only screen and (max-width: 700px) {
    width: auto;
    justify-content: start;
    flex-direction: column;
  }
`;

export const SubHeadingText = styled.h4`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #13140F;
  margin-top: 25px;
`;

export const StyledFormItem = styled(Form.Item)`
  position: relative;
  margin-bottom: 0px;
  padding: ${(props) => (props.type2 ? '0' : '0 30px')};
  @media only screen and (max-width: 900px) {
    padding: 0 15px !important;
  }
  & input {
    line-height: 20px;
    border-radius: 8px;
    font-size: 16px;
    font-family: 'Urbanist', sans-serif;
    color: #35364f;
    padding: 30px 15px 10px 15px;
    background-color: #f4f5f7 !important;
    border: ${(props) => (props.type2 ? 'none' : '')};
    margin-left: ${({ name }) => (name === 'password' ? '-10px' : '')};
    @media only screen and (max-width: 900px) {
      font-size: 12px;
      height: 50px;
    }
  }

  & #ant-file-upload-button {
    display: none;
    background-color: red;
  }

  & #password,
  #confirm {
    line-height: 20px;
    border-radius: 8px;
    font-size: 16px;
    font-family: 'Urbanist', sans-serif;
    font-weight: 500;
    color: #35364f;
    padding: ${(props) =>
      props.corporatePassword ? ' 20px 15px 20px 15px' : '35px 15px 10px 15px'};
    background-color: #f4f5f7 !important;

    @media only screen and (max-width: 900px) {
      font-size: 12px;
    }
  }
 
  & .ant-form-item-required::before {
    content: '' !important;
  }

  & .ant-form-item-required::after {
    display: inline-block !important;
    margin-left: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: 'Urbanist', sans-serif;
    line-height: 1;
    content: '*' !important;

    @media only screen and (max-width: 900px) {
      font-size: 12px;
    }
  }

  & .ant-form-item-label {
    padding: 0;
    position: absolute;
    top: 5px;
    left: ${(props) => (props.left || props.type2 ? '12px' : '42px')};
    z-index: 1;
    & label {
      font-size: 14px;
      font-family: 'Urbanist', sans-serif;
      color: #65667d;
      &::after {
        content: '';
      }

      @media only screen and (max-width: 900px) {
        font-size: 12px;
      }
    }

    @media only screen and (max-width: 900px) {
      left: 25px;
    }
  }

  & .ant-input-affix-wrapper {
    background-color: #f4f5f7 !important;
    border-radius: 10px;
    padding: 0 10px;
    border: ${(props) => (props.type2 ? 'none' : null)};
  }

  & .react-tel-input {
    height: 67px;

    & .form-control {
      height: 100%;
      width: 100%;
      line-height: 20px !important;
      border-radius: 8px;
      font-size: 16px;
      font-family: 'Urbanist', sans-serif;
      color: #35364f;
      padding: 35px 15px 10px 15px !important;
      background-color: #f4f5f7 !important;
      border: ${(props) => (props.type2 ? 'none !important' : '')};

      @media only screen and (max-width: 900px) {
        font-size: 14px;
      }
    }

    & .flag-dropdown {
      position: absolute;
      top: 0;
      right: 0;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
      border: ${(props) => (props.type2 ? 'none !important' : '')};

      & .country-list {
        position: absolute;
        left: -260px;
      }
    }
  }

  & .ant-input-prefix {
    color: #6b6b6b;
  }

  & .ant-picker {
    height: 67px;
    padding-left: 0px;
    border-radius: 10px !important;
    background-color: #f4f5f7 !important;
    border: ${(props) => (props.type2 ? 'none' : '')};
    & .anticon-calendar {
      font-size: 23px;
    }
  }
  & .ant-select-selector {
    height: 67px !important;
    border-radius: 10px !important;
    background-color: #f4f5f7 !important;
    padding: 35px 15px 10px 15px !important;
    border: ${(props) => (props.type2 ? 'none !important' : '')};
    @media only screen and (max-width: 900px) {
      height: 50px !important;
      padding: 0 15px !important;
      display: flex;
      align-items: center;
    }
  }
  & .ant-select-selection-placeholder {
    line-height: 20px !important;
    /* font-size: 16px;
    font-family: 'Urbanist', sans-serif;
    color: #35364f !important; */
  }
  & .ant-select-selection-item {
    line-height: 20px !important;
    font-size: 16px;
    font-family: 'Urbanist', sans-serif;
    color: #35364f !important;
    @media only screen and (max-width: 900px) {
      font-size: 14px;
    }
  }
  & .ant-select-selection-search-input {
    padding: 25px 4px 0px !important;
    line-height: 20px !important;
    height: 100% !important;
  }

  & textarea {
    min-height: 120px;
    line-height: 20px;
    border-radius: 8px;
    font-size: 16px;
    font-family: 'Urbanist', sans-serif;
    color: #35364f;
    padding: 35px 15px 10px 15px;
    background-color: #f4f5f7 !important;
    border: ${(props) => (props.type2 ? 'none' : '')};

    @media only screen and (max-width: 900px) {
      font-size: 12px;
    }
  }
`;

export const StyledForm = styled(Form)`
  & > :not(:first-of-type) {
    margin-top: 13px;
  }
`;

export const FormWrapper = styled.div`
 display: flex;
 flex-direction: row;
 justify-content: ${(props) => (props.Viewanswer ? 'space-between' : props.IndividualAnswers ? 'flex-start' : 'center')};
 align-items: ${(props) => (props.Viewanswer ? 'space-between' : props.IndividualAnswers ? 'flex-start' : 'center')};
 width: 100%;
 .ant-divider-horizontal {
  margin: 0px;
 }
  @media only screen and (max-width: 700px) {
    width: auto;
    justify-content: start;
    flex-direction: column;
  }
`;
export const SubTextHeading = styled.h2`
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #000;
`;

export const SubText = styled.h5`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #13140F;
    margin-left: ${(props) => (props.formName ? '10px' : '0px')};
`;

export const Text = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: #1C9C31;
    text-transform: capitalize;
`;

export const ProfileContainer = styled.div`
 
  height: 70px;
  width:70px;
  border-radius: 50%;
  background-color: lightgray;
  & img {
    
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }

  @media only screen and (max-width: 900px) {
    width: 100px;
    height: 100px;
  }

  @media only screen and (max-width: 600px) {
    width: 50px;
    height: 50px;
  }
`;
export const ViewMoreButton = styled.p`
  color: #32AFBF;
  font-family: 'Urbanist', sans-serif;
  font-size: 16px;
  font-weight: 500;

  &:hover{
    cursor:pointer;
    opacity: 0.7;
  }

  @media only screen and (max-width:600px){
    font-size:14px;
    margin-top:5px;
  }
`;

export const Container = styled.div`
    width: 50%;
    margin-left: 20px;

.ant-progress-inner {
    position: relative;
    display: inline-block;
    width: 100%;
    height:60px;
    margin-top: 10px;
    overflow: hidden;
    vertical-align: middle;
    background-color: #f5f5f5;
    border-radius: 10px;
}

.ant-progress-bg {
    min-height: 60px;
}
.ant-progress-success-bg, .ant-progress-bg {
    position: relative;
    background-color: #AFEDF5;
    border-radius: 10px;
    height: 60px;
    -webkit-transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
    transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}
`;

export const QuesText = styled.h3`
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 150%;
color: #13140F;
`;
export const ButtonContainer = styled.div`
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: flex-start;
 padding-left: 30px;
 padding-right: 30px;
`;

export const Label = styled.p`
  margin: 0px;
  padding: 0px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: ${({ fontWeight }) => fontWeight ?? 400};
  color: ${({ color }) => color ?? "#000000"};
  margin-bottom: 10px;
  margin-top: ${({ marginTop }) => marginTop ?? "10px"};
  margin-right: ${({ marginRight }) => marginRight ?? "0px"};

  &:hover {
    cursor: ${({ hover }) => hover ? "pointer" : ""};
    opacity: ${({ hover }) => hover ? 0.7 : 1}
  }
`;

export const AlignedRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

export const SmallText = styled.p`
  position: absolute;
  z-index: 20;
  top: 30px;
  left:10px;
`;
export const ProgressText = styled.p`
  position: absolute;
  z-index: 20;
  right:10px;
  top: 30px;
`;
export const ProgressDiv = styled.div`
position: relative;
`;