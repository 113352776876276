import React from "react";
import moment from "moment";
import { Row, Col, Divider } from "antd";
import { UserOutlined } from "@ant-design/icons";
import ProfileAvatar from "../../../../Components/ProfileAvatar";
import CustomText from "../../../../Components/CustomText";
import styled from "styled-components";

const PatientInfoCard = ({ patient }) => {
  const CardWrapper = styled.div`
    min-height: 250px;
    width: 70vw;
    max-width: 550px;
    padding: 16px;
    margin: 15px 0;
    margin-right: 10px;
    .header-wrapper {
      border-bottom: 1px solid #d9d9d9;
      padding-bottom: 16px;
    }
    .content-wrapper {
      & > div {
        margin: 8px;
      }
    }
  `;
  
  return (
    <CardWrapper>
      <div
        style={{ display: "flex", alignItems: "center" }}
        className="header-wrapper"
      >
        <div>
          <ProfileAvatar
            url={patient?.profilePic}
            icon={<UserOutlined />}
            size={60}
          />
        </div>
        <div
          style={{
            display: "flex",
            marginLeft: "16px",
            flexDirection: "column",
          }}
        >
          <CustomText size="16px" color="#2959a8" fontWeight="bold">
            {patient?.name ?? patient?.fullName}
          </CustomText>
          <CustomText size="16px" color="grey">
            {patient?.phoneNumber}
          </CustomText>
        </div>
      </div>
      <div className="content-wrapper">
        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">Patient Id:</CustomText>
          </Col>
          <Col span={12}>
            <CustomText fontWeight="bold" color="grey">
              {patient?._id}
            </CustomText>
          </Col>
        </Row>

        {patient?.gender ? (
          <Row>
            <Col span={12}>
              <CustomText fontWeight="bold">Gender:</CustomText>
            </Col>
            <Col span={12}>
              <CustomText fontWeight="bold" color="grey">
                {patient?.gender}
              </CustomText>
            </Col>
          </Row>
        ) : null}

        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">Address:</CustomText>
          </Col>
          <Col span={12}>
            <CustomText fontWeight="bold" color="grey">
              {patient?.city} ,{patient?.state},{patient?.country}
            </CustomText>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">Email:</CustomText>
          </Col>
          <Col span={12}>
            <CustomText fontWeight="bold" color="grey">
              {patient?.email ?? '____'}
            </CustomText>
          </Col>
        </Row>
        
        {/* Dob */}
        {patient?.dob ? (
          <Row>
            <Col span={12}>
              <CustomText fontWeight="bold">Date Of Birth:</CustomText>
            </Col>
            <Col span={12}>
              <CustomText fontWeight="bold" color="grey">
                {moment(patient?.dob).format("DD MMMM YYYY")}
              </CustomText>
            </Col>
          </Row>
        ) : null}

        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">Age:</CustomText>
          </Col>
          <Col span={12}>
            <CustomText fontWeight="bold" color="grey">
              {patient?.age ?? '____'}
            </CustomText>
          </Col>
        </Row>

        {/* Status */}
        {patient?.status ? (
          <Row>
            <Col span={12}>
              <CustomText fontWeight="bold">Status:</CustomText>
            </Col>
            <Col span={12}>
              <CustomText fontWeight="bold" color="grey">
                {patient.status}
              </CustomText>
            </Col>
          </Row>
        ) : null}
        
        {/* Company */}
        {patient?.companyDetails?.companyName ? (
          <Row>
            <Col span={12}>
              <CustomText fontWeight="bold">Company name:</CustomText>
            </Col>
            <Col span={12}>
              <CustomText fontWeight="bold" color="grey">
                {patient?.companyDetails?.companyName}
              </CustomText>
            </Col>
          </Row>
        ) : null}
      </div>
    </CardWrapper>
  );
};

export default PatientInfoCard;
