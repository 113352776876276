import React, { useState, useEffect, Fragment } from "react";
import { FormListContainer,
   HeadingText, 
   ProfileContainer, 
   SubTextHeading, 
   SubText, 
   ViewMoreButton, 
   Wrapper 
  } from "./styledComponent";
import { Divider, Empty, message } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useHistory, useParams } from "react-router-dom";
import ApiCalls from "../../apis/ApiCalls";
import moment from "moment";

const ViewProfile = () => {
  const [singleFormDetails, setSingleFormDetails] = useState({});
  const [allSubmissionSingleFormDetails, setAllSubmissionSingleFormDetails] = useState({});
  const [answerPatientList, setAnswerPatientList] = useState([]);
  const [loading, setLoading] = useState(false);

  const param = useParams();
  const history = useHistory();

  //All Submission Single Form API
  const allSubmissionSignleFormApi = () => {
    ApiCalls.getAllSubmissionSingleForm(param.id)
      .then((res) => {
        setLoading(false);
        if (res?.data?.data?.data) {
          setAllSubmissionSingleFormDetails(res.data.data.data);
          message.success("View Answers Details is here");
        }
      })
      .catch((error) => {
        setAllSubmissionSingleFormDetails({});
        setLoading(false);
        if (error.response) {
          message.info(error.response.data.message);
        } else if (error.request) {
          message.error("Server not responding");
        } else {
          message.error("Something went wrong");
        }
      });
  }

  // Get Answers Patient List Api
  const AnswersPatientList = () => {
    ApiCalls.getAnswerPatientList(param.id)
      .then((res) => {
        setLoading(false);
        if (res?.data?.data?.data) {
          setAnswerPatientList(res.data.data.data);
          message.success("All the patient list are here");
        }
      })
      .catch((error) => {
        setAnswerPatientList({});
        setLoading(false);
        if (error.response) {
          message.info(error.response.data.message);
        } else if (error.request) {
          message.error("Server not responding");
        } else {
          message.error("Something went wrong");
        }
      });
  }

  useEffect(() => {
    allSubmissionSignleFormApi();
    AnswersPatientList();
  }, [])

  const ViewMore = (item) => {
    history.push(`/base/home/survay-form/form-details/individual-answers/${param.id}/${item.patientId}`)
  }

  return (
    <FormListContainer>
      <HeadingText>{singleFormDetails.formName}</HeadingText>
      <Divider />
      {answerPatientList.map((item) => (
        <Fragment key={item._id}>
          <Wrapper>
            <SubTextHeading>Name</SubTextHeading>
            <SubTextHeading>Email</SubTextHeading>
            <SubTextHeading>Submission Date</SubTextHeading>
            <SubTextHeading>View in detail</SubTextHeading>
           </Wrapper>
           <Wrapper profile={true}>
            <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
            <ProfileContainer>
              <img
                src={Empty}
                alt=''
                onError={(e) => {
                  if (e.target.src !== Empty) {
                    e.target.src = Empty;
                  }
                }}
              />
            
            </ProfileContainer>
            <SubText formName={true}>{item.patientDetails.patientName}</SubText>
            </div>
            <SubText>{item.patientDetails.patientEmail}</SubText>
            <SubText>{moment(item?.createdAt).format("HH:MM a, DD-MM-YYYY")}</SubText>
            

            <ViewMoreButton onClick={()=>ViewMore(item)}>
              View more
            <RightOutlined style={{ textAlign: "right", marginLeft: '5px' }} />
            </ViewMoreButton>
          </Wrapper>
        </Fragment>
      ))}

      <Divider />
    </FormListContainer>
  )
}
export default ViewProfile;
