import React, { useState } from 'react'
import styled from 'styled-components'
import { Typography, Row, Col, Button, Upload, Spin } from 'antd'
import ProfileAvatar from '../../../Components/ProfileAvatar'
import { UserOutlined, DeleteOutlined } from '@ant-design/icons'
import { useEffect } from 'react'
import storage from '../../../storage'

const PrimaryButton = styled.div`
  .ant-btn-primary {
    border-radius: 25px;
    height: 40px;
    font-size: 12px;
    width: 150px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* svg{
    margin-right:10px;
    align-self: center;
} */
  }
`

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const RemoveButton = styled(Button)`
  background-color: #dcdcdc;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  color: #a9a9a9;
  border: 0;
`

const CardTitle = styled(Typography)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 29px;
  color: #2959a8;
  text-transform: capitalize;
`
const CardSubTitle = styled(Typography)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #aeaeae;
`

const UploadButtonContainer = styled.div`
  .ant-btn {
    border: none;
  }
  .ant-btn-primary {
    background-color: #e4e4e4;
    border: none;
    width: 150px;
    color: #9d9d9d;
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }
`


const InstitutionTitle = ({ institution = {}, updateProfilePicture, picLoading }) => {
  function beforeUpload(file) {
    const formData = new FormData()
    formData.append('proPic', file)
    formData.append('id', institution._id)
    formData.append('type',institution.userType)
    updateProfilePicture(formData)
    return false
  }

  return (
    <Row
      gutter={16}
      align='middle'
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
      }}
    >
      <div style={{ display: 'flex' }}>
        <Col>
          <ProfileAvatar
            url={institution.profilePic}
            icon={<UserOutlined />}
            size={90}
          />
        </Col>
        <StyledCol>
          <div>
            <Row>
              <CardTitle>{institution.name}</CardTitle>
            </Row>
            <Row>
              <CardSubTitle>
                {institution.phoneNumber}
              </CardSubTitle>
            </Row>
          </div>
        </StyledCol>
        <Col style={{ padding: '20px' }}>
          <UploadButtonContainer>
            <Upload
              name='proPic'
              showUploadList={false}
              beforeUpload={beforeUpload}
            >
              <Button
                type='primary'
                size='large'
                shape='round'
                loading={picLoading}
              >
                Upload Photo
              </Button>
            </Upload>
          </UploadButtonContainer>
        </Col>
      </div>
    </Row>
  )
}

export default InstitutionTitle
