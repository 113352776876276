import React from "react";
import ProfileAvatar from "../../Components/ProfileAvatar";
import CustomText from "../../Components/CustomText";
import moment from "moment";
import { Row, Col } from "antd";
import { UserOutlined } from "@ant-design/icons";
import styled from "styled-components";

const ConsultationInfoCard = ({
  medicalReport = {},
  consultationInfo = {},

}) => {
  const {
    providerData = {},
    startday: startDay,
    endDate,
    specialization = [],
  } = consultationInfo;
  const getLength = (startTime, endTime) => {
    const formatDigit = (digit) => {
      return digit.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    };
    let duration = moment
      .duration(endTime.diff(startTime), "millisecond")
      .asSeconds();
    const hours = Math.floor(duration / 3600) % 24;
    duration = duration - 3600 * hours;
    const minutes = Math.floor(duration / 60) % 60;
    duration = duration - 60 * minutes;
    const seconds = Math.floor(duration % 60);
    return `${formatDigit(hours)}:${formatDigit(minutes)}:${formatDigit(
      seconds
    )}`;
  };

  const CardWrapper = styled.div`
    border: 1px solid #d9d9d9;
    padding: 16px;
    margin: 8px auto;
    marginbottom: 16px;
    min-height: 250px;

    .header-wrapper {
      border-bottom: 1px solid #d9d9d9;
      padding-bottom: 16px;
    }
    .content-wrapper {
      & > div {
        margin: 8px;
      }
    }
  `;
  return (
    <CardWrapper>
      {Object.keys(providerData).length > 0 && (
        <div
          style={{ display: "flex", alignItems: "center" }}
          className="header-wrapper"
        >
          <div>
            <ProfileAvatar
              url={providerData.profilePic}
              icon={<UserOutlined />}
              size={60}
            />
          </div>
          <div
            style={{
              display: "flex",
              marginLeft: "16px",
              flexDirection: "column",
            }}
          >
            <CustomText size="16px" color="#2959a8" fontWeight="bold">
              {providerData.firstName}&nbsp;{providerData.lastName}
            </CustomText>
            <CustomText size="16px" color="grey">
              {providerData.mobile}
            </CustomText>
            <div style={{ display: "flex" }}>
              <CustomText size="14px" color="black">
                Department:&nbsp;
              </CustomText>
              <CustomText size="14px" color="grey">
                {specialization.map((item) => item.specialization).join(",")}
              </CustomText>
            </div>
          </div>
        </div>
      )}
      <div className="content-wrapper">
        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">Status:</CustomText>
          </Col>
          <Col span={12}>
            {startDay ? (
              <CustomText fontWeight="bold" color="green">
                Success
              </CustomText>
            ) : (
              <CustomText fontWeight="bold" color="red">
                Failed
              </CustomText>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">Encounter:</CustomText>
          </Col>
          <Col span={12}>
            <CustomText fontWeight="bold" color="grey">
              {moment(startDay).format("MMM DD,YYYY HH:MM A")}
            </CustomText>
          </Col>
        </Row>
        {startDay && (
          <Row>
            <Col span={12}>
              <CustomText fontWeight="bold">Length:</CustomText>
            </Col>
            <Col span={12}>
              <CustomText fontWeight="bold" color="grey">
                {getLength(moment(startDay), moment(endDate))}
              </CustomText>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={12}>
            <CustomText fontWeight="bold">Via:</CustomText>
          </Col>
          <Col span={12}>
            <CustomText fontWeight="bold" color="grey">
              Video
            </CustomText>
          </Col>
        </Row>
      </div>
    </CardWrapper>
  );
};

export default ConsultationInfoCard;
