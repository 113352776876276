import React, { useState, useEffect, useContext } from "react";
import { Table, Col, Modal } from "antd";
import ProfileAvatar from "../../Components/ProfileAvatar";
import CustomText from "../../Components/CustomText";
import moment from "moment";
import InsuranceDetails from "./InsuranceDetails";
import SearchContext from "./SearchContext";
import ApiCalls from "../../apis/ApiCalls";
import CustomButton from "../../Components/CustomButton";
const PatientTable = ({ activeKey }) => {
  const [loading, setLoading] = useState(false);
  const [waiveOffList, setWaiveOffList] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [selectedConsultation, setSelectedConsultation] = useState({});
  const pageLimit = 10;
  const searchKey = useContext(SearchContext);
  const { Column } = Table;
  const handlePageChange = (pageNumber, size) => {
    setPageNumber(pageNumber);
  };

  const handleCancelModal = () => {
    setShowModal(false);
  };
  const handleRefetch = () => {
    setRefetch(!refetch);
  };
  const handleDetailView = (consultation) => {
    setSelectedConsultation(consultation);

    setShowModal(true);
  };
  useEffect(() => {
    setPageNumber(1);
  }, [activeKey, searchKey]);

  useEffect(() => {
    setLoading(true);
    ApiCalls.getPatientInsuranceList({
      updateStatus: activeKey,
      name: searchKey,
      page: pageNumber - 1,
      limit: pageLimit,
    })
      .then((res) => {
        if (res.data.status === 1) {
          setWaiveOffList(res.data.data.data);
          setTotalCount(res.data.data.count);
          setLoading(false);
        } else {
          setWaiveOffList([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        setWaiveOffList([]);
        setLoading(false);
      });
  }, [activeKey, refetch, pageNumber, searchKey]);

  return (
    <>
      <Table
        dataSource={waiveOffList}
        tableLayout="fixed"
        scroll={{ y: 300 }}
        loading={loading}
        pagination={{
          limit: pageLimit,
          total: totalCount,
          pageSize: pageLimit,
          hideOnSinglePage: true,
          position: ["bottomCenter"],
          onChange: handlePageChange,
          defaultCurrent: 1,
          current: pageNumber,
        }}
        style={{
          minHeight: "60vh",
          cursor: "pointer",
        }}
      >
        <Column
          dataIndex="profilePic"
          width={60}
          render={(field, record) => (
            <Col>
              <ProfileAvatar url={field} size={48} />
            </Col>
          )}
        />
        <Column
          align="center"
          dataIndex="name"
          key="name"
          title={<CustomText fontWeight="600">Name</CustomText>}
          render={(field, record) => (
            <CustomText fontWeight="600" size="14px" color="blue">
              {`${record.firstName} ${record.lastName}`}
            </CustomText>
          )}
        />
        <Column
          align="center"
          dataIndex="date"
          title={<CustomText fontWeight="600">Date</CustomText>}
          key="date"
          render={(date, record) => (
            <CustomText>
              {moment(record.waiveOffDate).format("DD/MM/YYYY")}
            </CustomText>
          )}
        />
        <Column
          align="center"
          dataIndex="email"
          title={<CustomText fontWeight="600">Email</CustomText>}
          key="email"
        />
        <Column
          align="center"
          dataIndex="mobile"
          title={<CustomText fontWeight="600">Email</CustomText>}
          key="mobile"
        />
        <Column
          align="center"
          render={(item, record) => (
            <CustomButton
              shape="round"
              // type="primary"
              style={{
                backgroundColor: "#f5f5f5",
                color: "#000",
              }}
              onClick={() => handleDetailView(record)}
            >
              DETAILS
            </CustomButton>
          )}
        />
      </Table>
      <Modal
        visible={showModal}
        onCancel={handleCancelModal}
        footer={false}
        width={"72%"}
        centered={true}
      >
        <InsuranceDetails
          patient={selectedConsultation}
          handleCancelModal={handleCancelModal}
          handleRefetch={handleRefetch}
        />
      </Modal>
    </>
  );
};

export default PatientTable;
