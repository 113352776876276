import React from "react";
import styled from "styled-components";
import { Card, Col, Row, Button, message, Pagination, Spin } from "antd";
import { WarningOutlined } from "@ant-design/icons";

import { useEffect } from "react";
import CustomText from "../../../Components/CustomText";
import ApiCalls from "../../../apis/ApiCalls";

const DeleteButton = styled.div`
  width: 100%;

  .ant-btn {
    /* background: #e79c23; */
    border: 0;
    border-radius: 5px;
    height: 39px;
    font-size: 16px;
    width: 106px;
    align-items: center;
  }
`;

const CancelButton = styled.div`
  width: 100%;
  .ant-btn {
    border-radius: 5px;
    height: 39px;
    font-size: 16px;
    width: 106px;
    align-items: center;
  }
`;

const Warning = styled(WarningOutlined)`
  height: 30px;
  width: 55px;
  svg {
    height: 100%;
    width: 100%;
  }
`;

const DeleteModal = ({
  selectedRefund,
  action,
  handleModalConfirm,
  handleModalCancel,
}) => {
  return (
    <>
      <CustomText size="18px" style={{ display: "flex" }}>
        <Warning />
        {`Are you sure you want to ${
          action === "accepted" ? "accept" : "reject"
        }
        ${selectedRefund.name} ?`}
      </CustomText>
      <Row
        justify="end"
        gutter={16}
        style={{ height: "100px", alignContent: "flex-end" }}
      >
        <Col>
          <CancelButton>
            <Button
              style={
                {
                  // backgroundColor: 'white',
                  // border: '1px solid #E79C23',
                  // color: '#E79C23',
                }
              }
              onClick={() => handleModalCancel(false)}
            >
              Cancel
            </Button>
          </CancelButton>
        </Col>
        <Col>
          <DeleteButton>
            <Button
              type="primary"
              htmlType="submit"
              onClick={(selectedRefund) => handleModalConfirm(action)}
            >
              {action === "accepted" ? "Accept" : "Reject"}
            </Button>
          </DeleteButton>
        </Col>
      </Row>
    </>
  );
};

export default DeleteModal;
