import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import {
  Row,
  Col,
  Button,
  Form,
  Select,
  Input,
  Radio,
  DatePicker,
  Skeleton,
  Modal,
  Divider,
  message
} from 'antd'

// import PhoneInput from 'react-phone-number-input'
// import flags from 'react-phone-number-input/flags'
// import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import CustomText from '../../../Components/CustomText'

const StyledCol = styled(Col)`
  font-family: Montserrat;
  font-style: normal;

  .ant-radio-button-wrapper {
    width: 200px;
    display: flex;
    justify-content: center;
    /* height: 50px; */
    align-items: center;
    /* margin: 12px */
    border-radius: 5px;
  }
  .ant-radio-group {
    display: flex;
    width: 100%;
  }
  .ant-radio-button-wrapper-checked {
    color: '#000000';
  }
  .ant-picker {
    width: 100%;
    border-radius: 5px;
  }
  .ant-btn {
    border-radius: 25px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    width: 170px;
    height: 40px;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    color: #ffffff;
  }

  .ant-form-item-label > label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #6b6b6b;
    padding-left: 15px;
  }

  .ant-select-multiple .ant-select-selector,
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
  }

  .ant-form-item-label > label.ant-form-item-required::before {
    display: none;
  }

  .ant-input-lg {
    padding: 6.5px 15px;
    font-size: 14px;
    color: #2f2f2f;
    height: 41px;
  }

  .react-tel-input .form-control {
    width: 100%;
    font-size: 14px;
    color: #2f2f2f;
    height: 41px;
    font-family: Montserrat;
  }

  .react-tel-input .selected-flag {
    font-family: Montserrat;
  }
`

const StyledInput = styled(Input)`
  border-radius: 5px;
`

const StyledButton = styled(Button)`
  background-color: black;
  border: 0;
`

const ModalStyle = styled(Modal)`
  .ant-modal-title {
    font-family: Montserrat;
    font-style: normal;
    font-size: 16px;
    font-weight: 600;
  }

  .ant-modal-header {
    padding: 32px 40px 0;
    border-bottom: 0;
  }

  .ant-modal-body {
    padding: 0 40px 40px;
  }
`

const ProfileInfo = ({
  institution = {},
  cities,
  specialization,
  saveProfileInfo,
  loading
}) => {
  const [initialValues, setInitialValues] = useState({})
  const [phoneNumber, setPhoneNumber] = useState(institution.phoneNumber)

  const [form] = Form.useForm()

  useEffect(() => {
    console.log(institution, 'Information')
    if (institution && institution._id) {
      setInitialValues(institution)
    } else {
      form.resetFields()
    }
  }, [institution])

  const onFinish = values => {
    let profileValues = {
      ...values,
      id: institution._id,
      userType: institution.userType,
      name: undefined,
      phoneNumber: phoneNumber
    }
    switch (institution.userType) {
      case 'hospitalAdmin':
        saveProfileInfo({ ...profileValues, hospital: values.name })
        break
      case 'laboratory':
        saveProfileInfo({ ...profileValues, laboratory: values.name })
        break
      case 'pharmacy':
        saveProfileInfo({ ...profileValues, pharmacy: values.name })
        break
    }
  }

  const handlePhoneNumber = number => {
    setPhoneNumber(number)
  }

  return (
    <>
      <Form
        layout='vertical'
        form={form}
        initialValues={institution}
        key={Object.keys(institution).length !== 0}
        onFinish={onFinish}
      >
        {console.log(institution)}
        <Row>
          <StyledCol
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
          >
            <Form.Item
              name='name'
              rules={[
                {
                  required: true,
                  message: 'Hospital name is required'
                }
              ]}
              label='Name*'
            >
              <StyledInput placeholder='Name' size='large' />
            </Form.Item>
          </StyledCol>
        </Row>

        <Row gutter={24}>
          <StyledCol
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <Form.Item
              name='email'
              rules={[
                {
                  required: true,
                  message: 'Email is required'
                }
              ]}
              label='Email*'
            >
              <StyledInput placeholder='Email' size='large' />
            </Form.Item>
          </StyledCol>
          <StyledCol
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <Form.Item
              name='state'
              rules={[
                {
                  required: true,
                  message: 'State is required'
                }
              ]}
              label='State*'
            >
              <Select
                placeholder='Select State'
                // mode="multiple"
                size='large'
              >
                {cities &&
                  cities.map(({ _id, name }) => (
                    <Select.Option value={name}>{name}</Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </StyledCol>
        </Row>

        <Row>
          <StyledCol
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
          >
            <Form.Item
              name='address'
              rules={[
                {
                  required: true,
                  message: 'Address is required'
                }
              ]}
              label='Address*'
            >
              <StyledInput placeholder='Address' size='large' />
            </Form.Item>
          </StyledCol>
        </Row>
        <Row gutter={24}>
          <StyledCol
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <Form.Item name='officePhone' label='Office Phone'>
              <StyledInput placeholder='Office Phone' size='large' />
            </Form.Item>
          </StyledCol>
          <StyledCol
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <Form.Item label='Mobile'>
              <PhoneInput
                placeholder='Mobile'
                value={phoneNumber}
                onChange={setPhoneNumber}
                inputProps={{
                  required: true,
                  autoFocus: true
                }}
                country='ae'
              />
            </Form.Item>
          </StyledCol>
        </Row>
        {institution.userType === 'hospitalAdmin' && (
          <>
            <Row>
              <StyledCol
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <Form.Item
                  name='platformFee'
                  label='Platform Fees %'
                  rules={[
                    {
                      required: true,
                      message: 'Fees is required'
                    }
                  ]}
                >
                  <StyledInput
                    type='number'
                    placeholder='Platform Fees'
                    size='large'
                    min='0'
                  />
                </Form.Item>
              </StyledCol>
            </Row>

            <Row gutter={24}>
              <StyledCol span={24}>
                <Form.Item
                  name='specialization'
                  rules={[
                    {
                      required: true,
                      message: 'specialization is required'
                    }
                  ]}
                  label='Specialization*'
                >
                  <Select
                    placeholder='Select Specialization'
                    mode='multiple'
                    size='large'
                  >
                    {specialization &&
                      specialization.map(({ _id, specialization }) => (
                        <Select.Option value={_id}>
                          {specialization}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </StyledCol>
            </Row>
          </>
        )}

        <Divider />
        <Row justify='end'>
          <StyledCol>
            <Form.Item>
              <Button
                type='primary'
                block={true}
                htmlType='submit'
                loading={loading}
              >
                Save Changes
              </Button>
            </Form.Item>
          </StyledCol>
        </Row>
      </Form>
    </>
  )
}
export default ProfileInfo
