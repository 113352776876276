import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Typography, Card, Tabs, Col, Button, Input, Row, Spin, Modal } from 'antd';
import CardItem from './CardItem';
import AddBanners from './AddBanners';
import ApiCalls from '../../apis/ApiCalls';
import DeleteBanner from './deleteBanner';

const { TabPane } = Tabs;

const buttonTexts = {
    '1': 'Banner Image',
    '2': 'Banner Content',
    '3': 'Footer Content'
};

const StyledCard = styled(Card)`
  width: 100%;
`;

const StyledDiv = styled.div`
  // styles here
`;

const CustomTabs = styled(Tabs)`
  width: 100%;
  // styles here
`;

const MainTitle = styled(Typography)`
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 0.5em;
  color: #5472a3;
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
`;

const Main = styled.div`
  padding: 3% 5%;
  font-family: 'Montserrat', sans-serif;

  .ant-input {
    background-color: transparent;
  }
  .ant-input-search {
    background-color: transparent;
  }
`;

const Index = () => {
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('1');
    const [showAddModal, setShowAddModal] = useState(false);
    const [bannerData, setBannerData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false)
    const [specializationList, setSpecializationList] = useState([])

    useEffect(() => {
        fetchBannerData();
        fetchSpecialization();
    }, []);

    const fetchSpecialization = () => {
        setLoading(true);
        ApiCalls.getSpecialisation()
            .then(response => {
                if (response.data.status === 1) {
                    setLoading(false);
                    setSpecializationList(response.data.data);
                }
            })
            .catch(error => {
                setLoading(false);
            });
    }

    const fetchBannerData = () => {
        setLoading(true);
        ApiCalls.fetchBannerData()
            .then(response => {
                if (response.data.status === 1) {
                    setLoading(false);
                    setBannerData(response.data.data.data);
                }
            })
            .catch(error => {
                setLoading(false);
            });
    };

    const handleAddContent = () => {
        setShowAddModal(true);
    };

    const handleCancelModal = () => {
        setSelectedItem(null)
        setShowAddModal(false);
        setDeleteModal(false)
    };

    const handleEditItem = (item) => {
        setSelectedItem(item);
        setShowAddModal(true);
    };

    const handleRefectch = () => {
        fetchBannerData();
    };

    const handleRemoveItem = (item) => {
        setSelectedItem(item)
        setDeleteModal(true)
    }

    const renderContentByType = (type) => {
        const filteredItems = bannerData.filter(item => item.type === type);
        return (
            <div style={{ minHeight: '50vh' }}>
                <Spin spinning={loading}>
                    {filteredItems.length > 0 ?
                        filteredItems.map(item => (
                            <CardItem key={item.id} item={item} handleRemoveItem={handleRemoveItem} handleEditItem={handleEditItem} />
                        ))
                        :
                        <Row justify="center" align="middle" style={{ height: '200px' }}>
                            <Col>
                                <div style={{ color: '#A4A4A4', fontSize: "18px" }}>
                                    No Data Found
                                </div>
                            </Col>
                        </Row>
                    }
                </Spin>
            </div>
        );
    };

    return (
        <Main>
            <Row justify="space-between" align="middle">
                <Col>
                    <MainTitle>Manage Banner</MainTitle>
                </Col>
                <Col>
                    <Row gutter="16" justify="end" align="middle">
                        <Col>
                            <Button type="primary" shape="round" onClick={() => handleAddContent(activeTab)}>
                                + ADD {buttonTexts[activeTab]}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <StyledCard>
                <Col span={24}>
                    <StyledDiv>
                        <CustomTabs
                            defaultActiveKey="1"
                            size="large"
                            activeKey={activeTab}
                            onChange={(key) => setActiveTab(key)}
                        >
                            <TabPane tab={<div className="tabs-wrapper">Banner Image</div>} key="1" />
                            {/* <TabPane tab={<div className="tabs-wrapper">Banner Content</div>} key="2" /> */}
                            <TabPane tab={<div className="tabs-wrapper">Footer Content</div>} key="3" />
                        </CustomTabs>
                    </StyledDiv>
                </Col>
            </StyledCard>

            {activeTab === '2' && renderContentByType('banner')}
            {activeTab === '1' && renderContentByType('image')}
            {activeTab === '3' && renderContentByType('footer')}

            <Modal
                visible={showAddModal}
                onCancel={handleCancelModal}
                footer={false}
                width={'30%'}
                centered={true}
                title={false}
                destroyOnClose={true}
            >
                <AddBanners onCancel={handleCancelModal} institutionType={buttonTexts[activeTab]} selectedItem={selectedItem} handleRefetch={handleRefectch} specializationList={specializationList} />
            </Modal>

            <Modal
                visible={deleteModal}
                onCancel={handleCancelModal}
                footer={false}
                width={'30%'}
                centered={true}
                title={false}
                destroyOnClose={true}
            >
                <DeleteBanner onCancel={handleCancelModal} institutionType={buttonTexts[activeTab]} selectedItem={selectedItem} handleRefetch={handleRefectch} />
            </Modal>
        </Main>
    );
};

export default Index;
