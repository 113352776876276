import React, { useState, useEffect } from "react";
import { Select, DatePicker } from "antd";
import moment from "moment";
import CustomLayout from "../../Components/CustomLayout";
import { WEEK_DATA } from "./dashboard.data";
import ApiCalls from "../../apis/ApiCalls";
import { 
  Main, 
  StyledColumn, 
  StyledHead, 
  StyledSelect, 
  FilterWrapper, 
  StyledSpin,
  BarWrapper,
  NoData
} from "./dashboard.styles";
import styled from "styled-components";

const DashboardCountContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
`;

const CountCard = styled.div`
  background-color: #C7D7FE;
  color: #5472a3
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h3`
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #5472a3;
`;

const Value = styled.p`
  color: #5472a3;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  font-size: 32px;
`;

const Dashboard = () => {
  const [selectedDoctorFilter, setSelectedDoctorFilter] = useState('by_week');
  const [selectedPatientFilter, setSelectedPatientFilter] = useState('by_week');
  const [doctorsDate, setDoctorsDate] = useState(moment(new Date()));
  const [patientsDate, setPatientsDate] = useState(moment(new Date()));
  const [doctorsData, setDoctorsData] = useState([...WEEK_DATA]);
  const [patientsData, setPatientsData] = useState([...WEEK_DATA]);
  const [patientLoader, setPatientLoader] = useState(false);
  const [doctorLoader, setDoctorLoader] = useState(true);
  let weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  const [metrics, setMetrics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {

    fetchMetrics();
  }, []);

  const doctorConfig = {
    data: doctorsData,
    xField: 'type',
    yField: 'value',
    seriesField: '',
    color: '#5B8FF9',
    legend: false,
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false
      }
    },
    scrollbar: {
      type: 'horizontal'
    }
  };

  const patientConfig = {
    data: patientsData,
    xField: 'type',
    yField: 'value',
    seriesField: '',
    color: '#5B8FF9',
    legend: false,
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false
      }
    },
    scrollbar: {
      type: 'horizontal'
    }
  };

  const fetchMetrics = async () => {
    try {
      let { data } = await ApiCalls.getOverallStats();
      if(data.status == 1){
        setMetrics(data.data);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  
  const getOnboardingCounts = async(userType, startDate, endDate, queryType) => {
    let { data } = await ApiCalls.onboardingCounts(userType, startDate, endDate, queryType);
    let temp = [];
    if(data?.data && Object.keys(data?.data)?.length > 0) {
      Object.keys(data?.data).forEach(dat => {
        temp.push({
          type: dat,
          value: data?.data[dat]
        });
      });
    }
    if(userType === 0) {
      setDoctorLoader(false);
      if(selectedDoctorFilter === "by_week" && temp?.length === 7) {
        for(let i = 0; i < temp.length; i++) {
          temp[i].type = weekDays[i];
        }
      } else if(selectedDoctorFilter === "by_month" && temp?.length > 0) {
        for(let i = 0; i < temp.length; i++) {
          temp[i].type = temp[i]?.type?.split("/")[0];
        }
      }
      setDoctorsData(temp?.length > 0 ? [...temp] : [{ type: "", value: "" }]);
    } else if(userType === 1) {
      setPatientLoader(false);
      if(selectedPatientFilter === "by_week" && temp?.length === 7) {
        for(let i = 0; i < temp.length; i++) {
          temp[i].type = weekDays[i];
        }
      } else if(selectedDoctorFilter === "by_month" && temp?.length > 0) {
        for(let i = 0; i < temp.length; i++) {
          temp[i].type = temp[i]?.type?.split("/")[0];
        }
      }
      setPatientsData(temp?.length > 0 ? [...temp] : [{ type: "", value: "" }]);
    }
  }

  useEffect(() => {
    let startDate = moment(new Date()).startOf('week').format("MM-DD-YYYY");
    let endDate = moment(new Date()).endOf('week').format("MM-DD-YYYY");
    setDoctorLoader(true);
    setPatientLoader(true);
    getOnboardingCounts(0, startDate, endDate, 'daily');
    getOnboardingCounts(1, startDate, endDate, 'daily');
  }, []);

  const onDoctorDateChange = (date, dateString) => {
    let startDate, endDate;
    if(selectedDoctorFilter === "by_week") {
      startDate = moment(date).startOf('week').format("MM-DD-YYYY");
      endDate = moment(date).endOf('week').format("MM-DD-YYYY");
    } else if(selectedDoctorFilter === "by_month") {
      startDate = moment(date).startOf('month').format("MM-DD-YYYY");
      endDate = moment(date).endOf('month').format("MM-DD-YYYY");
    } else if(selectedDoctorFilter === "by_year") {
      startDate = moment(date).startOf('year').format("MM-DD-YYYY");
      endDate = moment(date).endOf('year').format("MM-DD-YYYY");
    } else if(selectedDoctorFilter === "custom") {
      startDate = dateString[0];
      endDate = dateString[1];
    }
    getOnboardingCounts(0, startDate, endDate, selectedDoctorFilter === "by_year" ? "yearly": "daily");
    setDoctorsDate(date);
    // console.log(date, dateString)
  }

  const onPatientDateChange = (date, dateString) => {
    let startDate, endDate;
    if(selectedPatientFilter === "by_week") {
      startDate = moment(date).startOf('week').format("MM-DD-YYYY");
      endDate = moment(date).endOf('week').format("MM-DD-YYYY");
    } else if(selectedPatientFilter === "by_month") {
      startDate = moment(date).startOf('month').format("MM-DD-YYYY");
      endDate = moment(date).endOf('month').format("MM-DD-YYYY");
    } else if(selectedPatientFilter === "by_year") {
      startDate = moment(date).startOf('year').format("MM-DD-YYYY");
      endDate = moment(date).endOf('year').format("MM-DD-YYYY");
    } else if(selectedPatientFilter === "custom") {
      startDate = dateString[0];
      endDate = dateString[1];
    }
    getOnboardingCounts(1, startDate, endDate, selectedPatientFilter === "by_year" ? "yearly" : "daily");
    setPatientsDate(date);
    // console.log(date, dateString)
  }

  const doctorFilterChange = (value) => {
    setDoctorsDate("");
    setSelectedDoctorFilter(value);
    setDoctorsData([{ type: "", value: "" }]);
  }

  const patientFilterChange = (value) => {
    setPatientsDate("");
    setSelectedPatientFilter(value);
    setPatientsData([{ type: "", value: "" }]);
  }

  return (
    <Main>

      {/* Header */}
      <CustomLayout
        mainTitle="Dashboard"
        makePaddingZero={true}
      />

<DashboardCountContainer>
      <CountCard>
        <Title>Total Appointments</Title>
        <Value>{metrics?.scheduleCount}</Value>
      </CountCard>
      <CountCard>
        <Title>Cancellations</Title>
        <Value>{metrics?.cancelledCount}</Value>
      </CountCard>
      <CountCard>
        <Title>Refund Request</Title>
        <Value>{metrics?.refundCount}</Value>
      </CountCard>
      <CountCard>
        <Title>Active Users</Title>
        <Value>{metrics?.activeUsersCount}</Value>
      </CountCard>
      <CountCard>
        <Title>Therapist Signup</Title>
        <Value>{metrics?.therapistCount}</Value>
      </CountCard>
      <CountCard>
        <Title>Users Signup</Title>
        <Value>{metrics?.patientCount}</Value>
      </CountCard>
    </DashboardCountContainer>

      {/* Doctors Onboarding */}
      <StyledHead type="Doctor">Therapists Onboarding</StyledHead>
      
      {/* Custom Date Filter */}
      <FilterWrapper>
        <StyledSelect value={selectedDoctorFilter} onChange={doctorFilterChange}>
          <Select.Option value="by_week">By week</Select.Option>
          <Select.Option value="by_month">By month</Select.Option>
          <Select.Option value="by_year">By year</Select.Option>
          <Select.Option value="custom">Custom</Select.Option>
        </StyledSelect>

        {selectedDoctorFilter === "by_week" ? (
          <DatePicker onChange={onDoctorDateChange} picker="week" value={doctorsDate} />
        ) : selectedDoctorFilter === "by_month" ? (
          <DatePicker onChange={onDoctorDateChange} picker="month" format="MMM YYYY" value={doctorsDate} />
        ) : selectedDoctorFilter === "by_year"  ? (
          <DatePicker onChange={onDoctorDateChange} picker="year" value={doctorsDate} />
        ) : (
          <DatePicker.RangePicker picker="date" onChange={onDoctorDateChange} />
        )}
      </FilterWrapper> 

      <BarWrapper>
        {doctorLoader ? (
          <StyledSpin spinning={true} size="large" />
        ) : doctorsData[0]?.type === "" && (
          <NoData>
            No onboarding details found
          </NoData>
        )}
        {/* Bar Chart */}
        <StyledColumn {...doctorConfig} />
      </BarWrapper>

      {/* Patients Onboarding */}
      <StyledHead type="Patient">Patients Onboarding</StyledHead>
      
      {/* Custom Date Filter */}
      <FilterWrapper>
        <StyledSelect value={selectedPatientFilter} onChange={patientFilterChange}>
          <Select.Option value="by_week">By week</Select.Option>
          <Select.Option value="by_month">By month</Select.Option>
          <Select.Option value="by_year">By year</Select.Option>
          <Select.Option value="custom">Custom</Select.Option>
        </StyledSelect>

        {selectedPatientFilter === "by_week" ? (
          <DatePicker onChange={onPatientDateChange} picker="week" value={patientsDate} />
        ) : selectedPatientFilter === "by_month" ? (
          <DatePicker onChange={onPatientDateChange} picker="month" format="MMM YYYY" value={patientsDate} />
        ) : selectedPatientFilter === "by_year"  ? (
          <DatePicker onChange={onPatientDateChange} picker="year" value={patientsDate} />
        ) : (
          <DatePicker.RangePicker picker="date" onChange={onPatientDateChange} />
        )}
      </FilterWrapper>

      {/* Bar Chart */}
      <BarWrapper>
        {patientLoader ? (
          <StyledSpin spinning={true} size="large" />
        ) : patientsData[0]?.type === "" && (
          <NoData>
            No onboarding details found
          </NoData>
        )}
        <StyledColumn {...patientConfig} />
      </BarWrapper>

    </Main>
  );
}

export default Dashboard;